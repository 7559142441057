import { toast } from "sonner";

import { supabase } from "utils/SupabaseClient";

export async function fetchTemplates() {
  const { data, error } = await supabase.from("dim_bi_plantillas").select(
    `id, 
      title, 
      description, 
      url, 
      updated_at,
      templates:dim_bi_plantillas_relacion!report_id(
       data_source:dim_bi_origenes!origin_id(
        id, proveedor, tipo
       )
      )`
  );

  if (error) {
    toast.error("Error al cargar plantillas.");
    return [];
  }
  return data;
}

export async function fetchReports() {
  return true;
}
