import { getUserData, supabase } from "utils/SupabaseClient";
import { DownloadFile } from "hooks/commonData";
import { decode } from "base64-arraybuffer";
import { toast } from "sonner";
import fileDownload from "js-file-download";

export async function createCustumerTicket({
  ticketTypeId,
  serviceId,
  projectId,
  summary,
  description,
  subcompanyId,
}) {
  const user = await getUserData();

  const { data, error } = await supabase
    .from("th_tickets")
    .insert([
      {
        project_id: projectId,
        type_id: ticketTypeId,
        service_id: serviceId,
        title: summary,
        description: description,
        created_by: user.id,
        modified_by: user.id,
        subcompany_id: subcompanyId,
      },
    ])
    .select();
  if (error) {
    toast.error(`Error al guardar la incidencia`);
  }
  return [data[0].id, data[0].created_by];
}

export async function getComments(limit, offset, issue) {
  const {
    data: th_ticket_comments,
    count,
    error,
  } = await supabase
    .from("th_ticket_comments")
    .select("*", { count: "exact" })
    .eq("ticket_id", issue)
    .order("updated_at", { ascending: false })
    .range(offset, offset + limit - 1);

  for (const comment of th_ticket_comments) {
    comment["urlFiles"] = await getUrlFileComment(comment.id);
  }
  return { th_ticket_comments, count, error };
}

export async function saveComment(body, files) {
  try {
    // Verificamos si alguno de los archivos ya existe
    if (files) {
      await checkfileAlready(files);
    }

    const { data, error } = await supabase
      .from("th_ticket_comments")
      .insert([body])
      .select();

    if (error) {
      throw new Error("Error al insertar el comentario.");
    }

    if (files) {
      await saveFile(files, data[0].id);
    }

    return { data, error: null };
  } catch (error) {
    return { data: null, error: error.message };
  }
}

export async function updateComment(body, idComment, files) {
  try {
    if (files) {
      await checkfileAlready(files);
    }

    const { data, error } = await supabase
      .from("th_ticket_comments")
      .update(body)
      .eq("id", idComment)
      .select();

    if (error) {
      throw new Error("Error al insertar el comentario.");
    }

    if (files) {
      await saveFile(files);
    }

    return { data, error: null };
  } catch (error) {
    return { data: null, error: error.message };
  }
}

export async function getUrlFileComment(id_comment) {
  // let urlFile = [];

  const { data: commentFiles, error: fetchError } = await supabase
    .from("th_ticket_comment_files")
    .select("file_name")
    .eq("comment_id", id_comment);

  // commentFiles.forEach(file => {
  //   const { data, error } = supabase.storage
  //   .from("ticket")
  //   .getPublicUrl(`${file.file_name}`);

  //   urlFile.push(data.publicUrl)
  // });

  return commentFiles;
}

export async function handleDownload(fileName) {
  try {
    const blob = await DownloadFile("ticket", fileName);
    fileDownload(blob, fileName);
  } catch (error) {
    console.error("Error al descargar el archivo:", error);
  }
}

export async function getListFileCommnet(ticketId) {
  const { data, error } = await supabase.rpc("get_comments_with_files", {
    ticket_id: ticketId,
  });
  return data;
}

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      // El resultado es la cadena Base64 (con el prefijo data:...)
      resolve(reader.result.split(",")[1]); // Elimina el prefijo `data:*/*;base64,`
    };

    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file); // Convierte el archivo a Base64
  });
}

async function saveFile(files, comment_id) {
  for (const file of files) {
    try {
      // Convertir el archivo a base64
      const base64File = await convertToBase64(file);
      const base64FileString = base64File.replace(/^data:.+;base64,/, "");
      const decodedBuffer = decode(base64FileString);
      const fileBlob = new Blob([decodedBuffer]);

      const fileName = file.name;

      const { error: uploadFiles } = await supabase.storage
        .from("ticket")
        .upload(`request/comments/${fileName}`, fileBlob, {
          contentType: "application/octet-stream",
        });

      if (uploadFiles && uploadFiles.statusCode === "409") {
        throw new Error(`El archivo o imagen '${fileName}' ya existe`);
      }

      const { error: errorFile } = await supabase
        .from("th_ticket_comment_files")
        .insert([
          {
            comment_id: comment_id,
            created_at: new Date(),
            file_name: `request/comments/${file.name}`,
          },
        ]);

      if (errorFile) {
        throw new Error("Error al insertar el archivo en la base de datos.");
      }
    } catch (error) {
      throw error.message;
    }
  }
}

async function checkfileAlready(files) {
  for (const file of files) {
    const fileName = file.name;

    const { data: existingFileData, error: fileCheckError } =
      await supabase.storage
        .from("ticket")
        .list("request/comments", { search: fileName });

    if (fileCheckError) {
      throw new Error(`Error al verificar el archivo: ${fileName}`);
    }

    if (existingFileData && existingFileData.length > 0) {
      throw new Error(
        `El archivo o imagen '${fileName}' ya existe. No se insertará el comentario ni los archivos.`
      );
    }
  }
}
