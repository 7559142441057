import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserData } from "utils/SupabaseClient";
import { getUserDetails, getProjectDetails } from "hooks/commonData";
import { saveCustumerRequest } from "api/requests/route";
import { ChargingComponent } from "components/common/ChargingComponent";
import { Text } from "components/tailwind/Text";
import { Form } from "components/tailwind/Forms";
import { Files } from "components/tailwind/Files";
import { Select } from "components/tailwind/Select";
import { TextArea } from "components/tailwind/TextArea";
import { toast } from "sonner";

export function Request({ ticketTypeId }) {
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [enumServices, setEnumServices] = useState([]);
  const [enumProjects, setEnumProjects] = useState([]);
  const [loading, setLoading] = useState(false);

  const consultarProyectos = user !== "";

  useEffect(() => {
    getUserData().then((data) => {
      setUser(data.id);
    });
  }, []);

  useEffect(() => {
    if (consultarProyectos) {
      getUserDetails(user, "projects").then((data) => {
        setEnumProjects(data.company.projects);
      });
    }
  }, [consultarProyectos]);

  const [selectedService, setSelectedService] = useState(-1);
  const [selectedProject, setSelectedProject] = useState(-1);
  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (selectedProject != -1) {
      getProjectDetails(selectedProject, "services").then(
        (data) => {
          setEnumServices(data.th_user_projects_services);
        }
      );
    } else {
      setEnumServices([]);
    }
  }, [selectedProject]);

  const handleSave = async () => {
    if (selectedService === -1) {
      toast.error("Por favor selecciona un servicio.");
      return;
    }

    if (selectedProject === -1) {
      toast.error("Por favor selecciona un proyecto.");
      return;
    }

    if (summary === "") {
      toast.error("Por favor escribe un resumen.");
      return;
    }

    if (description === "") {
      toast.error("Por favor escribe una descripción.");
      return;
    }
    setLoading(true);
    try {
      try {
        const result = await saveCustumerRequest({
          ticketTypeId,
          projectId: selectedProject,
          summary,
          description,
          serviceId: selectedService,
          attachment: "Adjunto",
          files,
        });

        if (result?.error) {
          return toast.error(result.error);
        }

        toast.success("Solicitud creada exitosamente.");
        //TODO: Navegar a interfaz adecuada
        navigate("/support");
      } catch (error) {
        toast.error("Error inesperado:");
        return;
      }
      //TODO: Navegar a interfaz adecuada
      navigate("/support");
      ChargingComponent();
    } catch (error) {
      toast.error("Error desconocido");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mx-auto max-w-7xl p-6 lg:p-8">
      <Form
        title={"Solicitud"}
        description={"Describe tu solicitud."}
        onClick={handleSave}
      >
        <Select
          title={"Proyecto"}
          field={"project"}
          onChange={setSelectedProject}
          required={true}
          options={enumProjects.map((proyecto) => ({
            value: proyecto.id,
            name: proyecto.project_name,
          }))}
        />
        <Select
          title={"Servicio"}
          field={"service"}
          onChange={setSelectedService}
          required={true}
          options={enumServices.map((item) => ({
            value: item.services.id,
            name: item.services.item_value,
          }))}
        />

        <Text
          title={"Resumen"}
          field={"summary"}
          required={true}
          onChange={setSummary}
        />

        <TextArea
          title={"Descripción"}
          description={"Por favor describemos tú solicitud."}
          field={"description"}
          onChange={setDescription}
          required={true}
        />
        <Files
          title={"Adjuntos"}
          message={"PNG, JPG, SVG, XLSX, CSV, PDF, DOC up to 50MB"}
          files={files}
          setFiles={setFiles}
        />
      </Form>
    </div>
  );
}
