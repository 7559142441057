import React, { useEffect, useState } from "react";

const MultipleSelect = ({ setValue, list, title }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const toggleOption = (option) => {
    setSelectedOptions((prevSelected) => {
      if (prevSelected.includes(option)) {
        return prevSelected.filter((item) => item !== option);
      } else {
        return [...prevSelected, option];
      }
    });
  };

  useEffect(() => {
    setValue(selectedOptions);
  }, [selectedOptions]);

  return (
    <div>
      <label className="ml-2 text-md font-sans font-semibold text-slate-950">
        {`${title}:`}
      </label>

      <div className="flex w-full p-2 overflow-x-auto">
        <div className="flex flex-wrap gap-2 w-max">
          {list.map((item) => (
            <button
              className={`rounded-lg px-4 py-2 text-sm text-gray-700 hover:bg-yellow-100 slide-down-animation transition-all ${
                selectedOptions.includes(item.label)
                  ? "bg-blue-300 scale-110"
                  : "border border-slate-300 bg-white"
              }`}
              key={item.label}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                toggleOption(item.label);
              }}
            >
              {item.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultipleSelect;
