import { Develope } from "api/budget/Develope";
import { Question } from "api/budget/Quiestion";
import { AutoHeigth } from "components/common/AutoHeigth";
import { BackGroundSections } from "components/common/BackGroundSections";
import { Button } from "components/common/Button";
import { ButtonSave } from "components/common/ButtonSave";
import { ChargingComponent } from "components/common/ChargingComponent";
import { InputText } from "components/common/InputText";
import MultipleSelect from "components/common/MultipleSelect";
import { Subtitle } from "components/common/Subtitle";
import { BackIcon } from "components/svg/BackIcon";
import { TextArea } from "components/tailwind/TextArea";
import { useEffect, useState } from "react";

export function Form({ setSubmodule, submodule }) {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [settings, setSetting] = useState("");
  const [tecnology, setTecnology] = useState("");
  const [nameConsult, setNameConsult] = useState("");
  const [description, setDescription] = useState("");

  const settingsList = [
    { label: "Power BI" },
    { label: "Power APPS" },
    { label: "Power Automate" },
    { label: "Power Platform" },
    { label: "Ms. Fabrics" },
    { label: "Looker Studio" },
    { label: "AppSheets" },
    { label: "Pentaho" },
    { label: "Python" },
    { label: "Supabase" },
    { label: "React" },
    { label: "Tableau" },
  ];

  const tecnologyList = [
    { label: "BI" },
    { label: "BA" },
    { label: "IA" },
    { label: "ML" },
    { label: "DL" },
    { label: "RPA" },
    { label: "API" },
    { label: "ETL" },
    { label: "DWH" },
    { label: "Fabrica de Software" },
  ];

  const descriptionComplete = `${description}
Herramientas:
${settings.length !== 0 ? settings : "ninguna"}

Tecnologias:
${tecnology.length !== 0 ? tecnology : "ninguna"}
    `;

  const Insert = async () => {
    if (submodule === "IhaveAQuestionVisual") {
      await Question({
        nameConsult,
        descriptionComplete,
        setIsLoading,
        setError,
        setSuccess,
      });
    } else {
      // await Develope({});
    }
  };

  useEffect(() => {
    if (success || error) {
      setIsLoading(false);
      setSuccess(false);
      setError(false);
      setNameConsult("");
      setDescription("");
    }
  }, [success, error]);

  return (
    <div className="col-span-2">
      <BackGroundSections>
        <div className={`${isLoading ? "appear-animation" : "hidden"}`}>
          <ChargingComponent />
        </div>
        <div className={`${!isLoading ? "appear-animation" : "hidden"}`}>
          <AutoHeigth maxPx="130px">
            <div className="flex justify-between my-1">
              <div className="w-full text-center">
                <Subtitle
                  text={
                    submodule === "IhaveAQuestionVisual"
                      ? "TENGO UNA PREGUNTA"
                      : "QUIERO UN DESARROLLO"
                  }
                />
              </div>
              <button onClick={() => setSubmodule("MenuVisualBudget")}>
                <BackIcon w={30} h={30} />
              </button>
            </div>
            <MultipleSelect
              title={"Herramientas"}
              list={settingsList}
              setValue={setSetting}
            />
            <MultipleSelect
              title={"Tecnología"}
              list={tecnologyList}
              setValue={setTecnology}
            />
            <InputText
              placeholder="Nombre de la consulta:"
              setValue={setNameConsult}
              value={nameConsult}
            />
            <TextArea
              title="Descripción General"
              onChange={setDescription}
              value={description}
            />
            <div
              className={`${
                settings !== "" && description !== ""
                  ? "appear-animation"
                  : "hidden"
              } flex justify-center`}
            >
              <ButtonSave HadleSubmit={Insert} />
            </div>
          </AutoHeigth>
        </div>
      </BackGroundSections>
    </div>
  );
}
