import { GradiendBanner } from "components/common/GradiendBanner";
import { Subtitle } from "components/common/Subtitle";
import { Form } from "./Form";
import { Information } from "./Information";

export function Visual({ submodule, setSubmodule }) {
  return (
    <div
      className={`${
        submodule === "IhaveAQuestionVisual" || submodule === "WantDevelope"
          ? "slide-down-animation"
          : "hidden"
      }`}
    >
      <GradiendBanner colors="bg-blue-200 border-2 border-sky-700">
        <div className="p-3 text-sky-700 text-center">
          <Subtitle text="¡Nos encanta lo que hacemos y queremos compartir toda nuestra experiencia para que trabajemos juntos!" />
        </div>
      </GradiendBanner>

      <div className="grid grid-cols-3 gap-2 mt-2">
        <Information submodule={submodule} />
        <Form submodule={submodule} setSubmodule={setSubmodule} />
      </div>
    </div>
  );
}
