export function PowerAutomateIcon({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 466 375"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M141.586 187.25L1.96068 30.125C1.96068 30.125 -7.41433 9.49999 14.8357 0L299.398 0.437492C299.398 0.437492 303.945 0.132838 305.07 1.63278"
        fill="url(#paint0_linear_777_4366)"
      />
      <path
        d="M5.03016 341.973L305.069 1.63281L389.53 94.7069L141.325 374.676L18.3326 374.632C18.3326 374.632 -9.81907 366.368 5.03016 341.973Z"
        fill="url(#paint1_linear_777_4366)"
      />
      <path
        d="M141.326 374.676H300.072C300.072 374.676 311.657 372.082 317.157 362.582L461.292 198.651C461.292 198.651 472.959 186.011 456.033 169.88L389.532 94.7068L141.326 374.676Z"
        fill="url(#paint2_linear_777_4366)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_777_4366"
          x1="141.586"
          y1="187.25"
          x2="143.22"
          y2="0.197332"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#214A97" />
          <stop offset="0.872152" stop-color="#1453D7" />
          <stop offset="1" stop-color="#1152D4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_777_4366"
          x1="176.068"
          y1="374.676"
          x2="208.511"
          y2="1.63275"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#256BDA" />
          <stop offset="1" stop-color="#3F8FF3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_777_4366"
          x1="300.072"
          y1="374.676"
          x2="296.92"
          y2="94.7068"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5BA7EE" />
          <stop offset="1" stop-color="#83C2F6" />
        </linearGradient>
      </defs>
    </svg>
  );
}
