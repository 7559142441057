import { useState } from "react";
import { ModalBG } from "components/common/ModalBG";
import { ModalSuccess } from "components/modal/ModalSuccess";
import { ChargingComponent } from "components/common/ChargingComponent";
import { FormSubmit } from "./FormSubmit";

export function NewFile({ newRow, onClose, files }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <ModalBG open={newRow} onClose={onClose} title="Cargue archivo Siigo">
      {isLoading && <ChargingComponent />}
      {!isLoading && (
        <>
          {!isSuccess && (
            <FormSubmit
              files={files}
              isSuccess={isSuccess}
              setIsSuccess={setIsSuccess}
              setIsLoading={setIsLoading}
            />
          )}
          {isSuccess && (
            <ModalSuccess
              onClose={onClose}
              title="Creado"
              message="Se ha creado el registro correctamente, recarga la pagina para
              poder visualizar tu nueva conexión."
            />
          )}
        </>
      )}
    </ModalBG>
  );
}
