import { createCustumerTicket } from "api/tickets/route";
import { getUserDetails } from "hooks/commonData";
import { toast } from "sonner";
import { getUserData, supabase } from "utils/SupabaseClient";

export async function Question({
  nameConsult,
  description,
  setIsLoading,
  setError,
  setSuccess,
}) {
  setIsLoading(true);

  const dataTicket = await createCustumerTicket({
    ticketTypeId: 1,
    serviceId: 47,
    projectId: "04a4d30d-d133-4281-98db-33fb5a401adc",
    description,
    summary: nameConsult,
  });

  if (!dataTicket) {
    toast.error("¡No fue posible enviar tu solicitud!");

    setError(true);
  } else {
    toast.success("¡Solicitud enviada exitosamente!");
    setSuccess(true);
  }
}
