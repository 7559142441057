import { Link } from "react-router-dom";
import { AutoHeigth } from "./AutoHeigth";
import { BackIcon } from "components/svg/BackIcon";

export function Module({ title, children, linkToBack, back }) {
  return (
    <div className="grow slide-down-animation truncate">
      {title && (
        <>
          <div className="flex justify-between h-11 p-2">
            <h1 className="text-2xl font-semibold text-sky-700 flex justify-center w-full md:justify-start">
              {title}
            </h1>
            {back && (
              <Link to={linkToBack}>
                <BackIcon h={"30"} w={"30"} />
              </Link>
            )}
          </div>
        </>
      )}
      <AutoHeigth>{children}</AutoHeigth>
    </div>
  );
}
