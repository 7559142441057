import { createClient } from "@supabase/supabase-js";

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const SUPABASE_ANON_KEY = process.env.REACT_APP_SUPABASE_KEY;
const SUPABASE_ANON_KEY_SECRET = process.env.REACT_APP_SUPABASE_KEY_SECRET;

export const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);

export const supabaseAddUser = createClient(
  SUPABASE_URL,
  SUPABASE_ANON_KEY_SECRET,
  {
    auth: {
      autoRefreshToken: false,
      persistSession: false,
    },
  }
);
export const getUserData = async () => {
  const userId = localStorage.getItem("userId");

  if (!userId) {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    localStorage.removeItem("userId");
    localStorage.setItem("userId", JSON.stringify(user));
    return user;
  } else {
    return JSON.parse(userId);
  }
};
