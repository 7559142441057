import { addUser } from "api/user-module/AddUser";

import { ModalBG } from "components/common/ModalBG";

import { getRoles, getSubCompañias, getTypeDocument } from "hooks/commonData";
import { useEffect, useState } from "react";
import { StructureModal } from "./StructureModal";

export function ModalAddUser({ isOpen, setIsOpen, companyId, setRecharge }) {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [typeDocumentData, setTypeDocumentData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [subCompanyData, setSubCompanyData] = useState([]);

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [roleId, setRoleId] = useState("");
  const [subCompany, setSubCompany] = useState("");
  const [documento, setDocumento] = useState("");
  const [typeDocument, setTypeDocument] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    getTypeDocument().then((r) =>
      setTypeDocumentData(
        r.map((item) => ({ value: item.tipo, label: item.tipo }))
      )
    );
    getRoles().then((r) =>
      setRolesData(r.map((item) => ({ value: item.id, label: item.rol })))
    );
    getSubCompañias(companyId).then((r) =>
      setSubCompanyData(
        r.map((item) => ({ value: item.id, label: item.subcompañia }))
      )
    );
  }, []);

  const Insert = async () => {
    await addUser({
      companyId,
      subCompany,
      name,
      lastName,
      phone,
      email,
      roleId,
      documento,
      typeDocument,
      password,
      setSuccess,
      setError,
      setIsLoading,
    });
  };

  useEffect(() => {
    if (success || error) {
      setIsLoading(false);
      setSuccess(false);
      setError(false);
      setRecharge(true);
      setIsOpen(false);
      setName("");
      setLastName("");
      setPhone("");
      setEmail("");
      setRoleId(null);
      setSubCompany("");
      setDocumento("");
      setTypeDocument("");
      setPassword("");
    }
  }, [success, error]);

  return (
    <ModalBG
      open={isOpen}
      onClose={() => setIsOpen(false)}
      title={"Añadir Nuevo usuario"}
    >
      <StructureModal
        isLoading={isLoading}
        setName={setName}
        name={name}
        setLastName={setLastName}
        lastName={lastName}
        setPhone={setPhone}
        phone={phone}
        setEmail={setEmail}
        email={email}
        rolesData={rolesData}
        setRoleId={setRoleId}
        roleId={roleId}
        subCompanyData={subCompanyData}
        setSubCompany={setSubCompany}
        subCompany={subCompany}
        typeDocumentData={typeDocumentData}
        setTypeDocument={setTypeDocument}
        typeDocument={typeDocument}
        setDocumento={setDocumento}
        documento={documento}
        setPassword={setPassword}
        password={password}
        form={Insert}
      />
    </ModalBG>
  );
}
