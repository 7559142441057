import { useState } from "react";

import { formatDate } from "utils/Operations";

import { TbApi } from "react-icons/tb";
import { RiFileExcel2Fill } from "react-icons/ri";
import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";

import { TD } from "components/table/TD";
import { Row } from "components/table/Row";
import { Table } from "components/table/Table";
import { ViewDocFiles } from "../documents";
import { Button } from "components/common/Button";
import { FormSubmit } from "../connections/FormSubmit";
// title,
// description,
// imageURL,
// updatedDate,
// origins,
// urlReport,
//TODO: Delete Datos quemados
const imgURL =
  "https://learn.microsoft.com/es-es/power-bi/consumer/media/end-user-report-open/power-bi-dashboards.png";
const connectionsDetail = [
  {
    connection: "API",
    title: "Conexión API",
    decription:
      "Permite realizar la conexión de forma directa mediante una API, para ello se requiere de una clave de acceso y un token de seguridad.",
  },
  {
    connection: "EXCEL",
    title: "Cargar Archivos Excel",
    decription:
      "Permite la carga de archivos de Excel, para ello se requiere de un archivo con extensión .xls o .xlsx.",
  },
];

export function ReportDetail({ item }) {
  return (
    <>
      <div className="grid grid-cols-3 gap-4 items-center">
        <div className="col-span-1 flex justify-center">
          <img src={imgURL} alt={item.title} className="self-start" />
        </div>
        <div className="col-span-2 text-gray-700">
          <section className="mb-4">
            <p className="text-base">
              <strong className="text-sky-700 uppercase">Nombre: </strong>
              {item.title}
            </p>
            <p className="text-base">
              <strong className="text-sky-700 uppercase">
                Última actualización:{" "}
              </strong>
              {formatDate(item.updated_at)}
            </p>
            <p className="text-base">
              <strong className="text-sky-700 uppercase">Descripción: </strong>
              {item.description}
            </p>
          </section>
          <section>
            <h3 className="text-sky-700 font-bold uppercase">
              Orígenes de datos:
            </h3>
            {/* TODO: Taer datos en la tabla <Table items={items} setRecharge={() => {}} active /> */}
            <h3 className="text-sky-700 font-bold uppercase pt-4">
              Documentos:
            </h3>
            <ViewDocFiles submodule="Documentos" setSubmodule={() => {}} />
          </section>
        </div>
      </div>
    </>
  );
}

export function ReportTemplate({ item }) {
  const [step, setStep] = useState(1);
  const [connection, setConnection] = useState(null);
  return (
    <>
      <Steps actualStep={step} />
      {step === 1 && (
        <FirstStep
          item={item}
          setConnection={setConnection}
          onClick={() => {
            setStep(2);
          }}
        />
      )}
      {step === 2 && (
        <SecondStep
          item={item}
          connection={connection}
          onClick={() => {
            setStep(3);
          }}
        />
      )}
      {step === 3 && <ThirdStep item={item} onClick={() => {}} />}
    </>
  );
}

function ActionButtons({ setConnection }) {
  const [isDetails, setIsDetails] = useState(null);
  const [details, setDetails] = useState(null);
  const [optionSelected, setOptionSelected] = useState(null);

  return (
    <>
      {isDetails && (
        <div className="border border-zinc-300 rounded-md p-4">
          <div className="flex justify-between items-center w-full">
            <h3 className="text-sm font-semibold leading-6 text-sky-700">
              {details.title}
            </h3>
            <Button
              type="button"
              onClick={() => {
                setIsDetails(false);
                setDetails(null);
              }}
              className="bg-red-500 p-1 text-white rounded-lg transition-colors"
              icon={<XMarkIcon className="h-4" />}
            />
          </div>
          <div className="text-gray-700 mt-2">
            <p className="text-xs">{details.decription}</p>
          </div>
        </div>
      )}
      {!isDetails && (
        <>
          <div
            onClick={() => {
              setConnection("EXCEL");
              setOptionSelected("EXCEL");
            }}
            className={`flex w-full border border-zinc-300 rounded-md text-emerald-600 hover:bg-yellow-50 hover:text-yellow-500 cursor-pointer ${
              optionSelected === "EXCEL" && "bg-yellow-50 text-yellow-500"
            }`}
          >
            <div className="flex space-x-4 items-center grow text-2xl p-1">
              <div className="h-12 w-12 flex items-center justify-center rounded-full bg-gray-50">
                <RiFileExcel2Fill />
              </div>
              <h3 className="text-xs font-semibold leading-6 text-sky-700">
                Cargar Archivos Excel
              </h3>
            </div>
            <div className="px-1 flex items-center justify-between">
              <Button
                type="button"
                onClick={() => {
                  setDetails(connectionsDetail[1]);
                  setIsDetails(true);
                }}
                className="bg-sky-800 p-2 text-white rounded-lg transition-colors"
                icon={<InformationCircleIcon className="h-4" />}
              />
            </div>
          </div>
          <div
            onClick={() => {
              setConnection("API");
              setOptionSelected("API");
            }}
            className={`flex w-full border border-zinc-300 rounded-md text-emerald-600 hover:bg-yellow-50 hover:text-yellow-500 cursor-pointer ${
              optionSelected === "API" && "bg-yellow-50 text-yellow-500"
            }`}
          >
            <div className="flex space-x-4 items-center grow text-2xl p-1">
              <div className="h-12 w-12 flex items-center justify-center rounded-full bg-gray-50">
                <TbApi />
              </div>
              <h3 className="text-xs font-semibold leading-6 text-sky-700">
                Conexión API
              </h3>
            </div>
            <div className="px-1 flex items-center justify-between">
              <Button
                type="button"
                onClick={() => {
                  setIsDetails(true);
                  setDetails(connectionsDetail[0]);
                }}
                className="bg-sky-800 p-2 text-white rounded-lg transition-colors"
                icon={<InformationCircleIcon className="h-4" />}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

function FirstStep({ item, setConnection, onClick }) {
  return (
    <>
      <div className="grid grid-cols-3 gap-4 text-sm">
        <div className="col-span-2 mb-4 text-gray-600 pt-4">
          <p className="font-bold uppercase text-gray-500">
            <strong className="text-sky-700">Nombre: </strong>
            {item.title}
          </p>
          <p>
            <strong className="text-sky-700 uppercase">
              Última actualización:{" "}
            </strong>
            {formatDate(item.updated_at)}
          </p>
          <p>
            <strong className="text-sky-700 uppercase">Descripción: </strong>
            {item.description}
          </p>
          <p>
            <strong className="text-sky-700 uppercase">
              Orígenes de datos:{" "}
            </strong>
            Este informe se basa en la información obtenida de la plataforma, y
            tendrás la posibilidad de elegir la forma en que deseas conectarte:
          </p>
          <div className="text-sm text-gray-500 py-4">
            <p className="font-bold">Opción 1: Cargar archivos de Excel</p>
            <p className="font-bold">Opción 2: Conexión directa mediante API</p>
          </div>
          <Table nameRows={[{ label: "Nombre" }, { label: "Tipo" }]}>
            {item.templates.map((template) => (
              <Row key={template.data_source.id}>
                <TD item={template.data_source.tipo} />
                <TD item="Archivo Excel" />
              </Row>
            ))}
          </Table>
        </div>
        <div className="col-span-1 space-y-2 flex flex-col justify-center items-center">
          <ActionButtons setConnection={setConnection} />
        </div>
      </div>
      <div className="flex justify-end col-span-3">
        <Button
          className="bg-sky-800 p-2 text-white rounded-lg transition-colors"
          text="Siguiente"
          onClick={onClick}
        />
      </div>
    </>
  );
}

function SecondStep({ item, connection, onClick }) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  console.log("item", item);
  return (
    <>
      {connection === "EXCEL" && (
        <FormSubmit
          files={[]}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          setIsLoading={setIsLoading}
        />
      )}
      <Button
        className="bg-sky-800 p-2 text-white rounded-lg transition-colors"
        text="Siguiente"
        onClick={onClick}
      />
    </>
  );
}

function ThirdStep({ onClick }) {
  return (
    <>
      <div className="flex space-x-4 py-4">
        <div className="flex-1">
          <img src="" alt="img" />
        </div>
        <div className="flex-1 space-y-4 text-center">
          <h3 className="font-bold uppercase">¡Gracias por tu solicitud!</h3>
          <p className="text-sm">
            Estamos trabajando en la integración de tus tados. Haz clic aquí
            para seguir el progreso.
          </p>
        </div>
      </div>
      <div className="flex justify-end col-span-3">
        <Button
          className="bg-sky-800 p-2 text-white rounded-lg transition-colors"
          text="Siguiente"
          onClick={onClick}
        />
      </div>
    </>
  );
}

function Steps({ actualStep }) {
  return (
    <div className="grid grid-cols-3 gap-4 border border-zinc-300 rounded-md p-1">
      <Step
        step="1"
        text="¡Conéctate de Forma Rápida!, Elige tu Opción"
        actualStep={actualStep}
      />
      <Step
        step="2"
        text="¡Carga tu Archivo o Activa tu API en un instante!"
        actualStep={actualStep}
      />
      <Step
        step="3"
        text="¡Listo para Recibir tu Solicitud! Nosotros lo Conectamos"
        actualStep={actualStep}
      />
    </div>
  );
}

function Step({ step, text, actualStep }) {
  const active = actualStep == step;
  return (
    <div className={active ? "text-sky-700" : "text-gray-500"}>
      <hr
        className={
          active ? "border-2 border-sky-700" : "border-2 border-zinc-300"
        }
      />
      <div className="flex text-xs">
        <div className="flex-none font-bold">PASO {step}</div>
        <div className="flex-1 text-center font-medium">{text}</div>
      </div>
    </div>
  );
}
