import { ModalBG } from "components/common/ModalBG";
import { FileUploadComponent } from "components/tailwind/FileUploadComponent";
import { useState } from "react";

export function ModalChangeProfilePicture({ open, setOpen, user }) {
  const [infoFiles, setInfoFiles] = useState([]);
  const [selectedFilesProps, setSelectedFilesProps] = useState([]);
  const [base64Props, setBase64Props] = useState([]);

  const changeImage = async () => {};
  return (
    <ModalBG onClose={setOpen} open={open} title="Cambio de foto de perfil">
      <FileUploadComponent
        text="Sube tu nueva foto de perfil"
        setInfoFiles={() => {}}
        selectedFilesProps={() => {}}
        base64Props={() => {}}
        accept=".png, .jpg"
      />
    </ModalBG>
  );
}
