import { useState } from "react";
import { LogoSiigo } from "components/svg/LogoSiigo";
import { ButtonIntegration } from "components/tailwind/ButtonIntegration";
import { NewFile } from "modules/bi/submodules/connections/NewModal";
import { OtherModal } from "modules/bi/submodules/integrations/OtherModal";
import { NewRowModal } from "modules/bi/submodules/integrations/NewRowModal";

export function IntegrationsBanner({
  setActive,
  isConnections,
  items,
  setRecharge,
}) {
  const [newRow, setNewRow] = useState(false);
  const [otherRow, setOtherRow] = useState(false);

  const handleNewRow = () => {
    setNewRow(!newRow);
    if (setActive) {
      setActive(newRow);
    }
  };

  const handleSuccess = () => {
    setNewRow(!newRow);
    if (setActive) {
      setActive(newRow);
    }
    setRecharge();
  };

  const handleOtherRow = () => {
    setOtherRow(!otherRow);
  };

  return (
    <>
      <div className="bg-gradient-to-r from-sky-700 via-sky-500 to-yellow-500 w-full mt-4 rounded-lg">
        <div className="p-2 2xl:p-4 flex">
          <div className="grow">
            <h2 className="text-white text-lg 2xl:text-xl font-semibold">
              {isConnections ? "Conexión archivos" : "Integración por API"}
            </h2>
            {isConnections && (
              <span className="text-white text-sm">Cargar archivos</span>
            )}
            <div className="p-2 2xl:p-4 flex gap-4">
              <ButtonIntegration
                children={<LogoSiigo h={40} w={40} />}
                onClick={handleNewRow}
              />
              {!isConnections && (
                <ButtonIntegration
                  children={
                    <label className="text-xl font-bold text-[#2E5DA1]">
                      Otro
                    </label>
                  }
                  onClick={handleOtherRow}
                />
              )}
            </div>
          </div>
          <img src="assets/gift/watch.gif" className="self-end w-36" />
        </div>
      </div>
      {!isConnections && (
        <>
          <NewRowModal newRow={newRow} onClose={handleNewRow} />
          <OtherModal otherRow={otherRow} onClose={handleOtherRow} />
        </>
      )}
      {isConnections && (
        <NewFile
          newRow={newRow}
          onClose={handleSuccess}
          files={items ? items : []}
        />
      )}
    </>
  );
}
