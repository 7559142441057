import { EyeIcon } from "components/svg/EyeIcon";
import { useEffect, useState } from "react";
import { FiUploadCloud } from "react-icons/fi";

export function FileUploadComponent({
  text,
  accept,
  selectedFilesProps,
  base64Props,
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false); // Controla si la imagen está en pantalla completa
  const [fullScreenImage, setFullScreenImage] = useState(null); // Guarda la imagen seleccionada para pantalla completa

  const handleFileChange = (event) => {
    const files = event.target.files;

    if (files) {
      const newFiles = Array.from(files);
      const fileName = newFiles.map((file, index) => ({
        name: file.name,
        id: index,
      }));
      setSelectedFiles(fileName);
      // Crear una lista de previsualizaciones de imágenes
      const previews = newFiles.map((file) =>
        file.type.startsWith("image/") ? URL.createObjectURL(file) : null
      );
      setImagePreviews(previews);

      // Leer los archivos como base64 y pasarlos al parent component
      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          base64Props(reader.result);
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleFullScreen = (preview) => {
    setIsFullScreen(true);
    setFullScreenImage(preview);
  };

  const closeFullScreen = () => {
    setIsFullScreen(false);
    setFullScreenImage(null);
  };

  useEffect(() => {
    setSelectedFiles(selectedFilesProps);
  }, [selectedFilesProps]);

  return (
    <div className="flex flex-col mt-2">
      <>
        <label
          htmlFor="file"
          className="flex items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-slate-300 transition delay-150 duration-300 ease-in-out text-gray-300 hover:text-slate-400"
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <FiUploadCloud className="w-20 h-20" />

            <p className="mb-2 text-sm ">
              <span className="text-lg text-center">{text}</span>
            </p>
            <p className="text-red-500">
              Adjuntar solo archivos de word y excel
            </p>
          </div>
          <input
            id="file"
            type="file"
            accept={accept}
            className="hidden"
            multiple
            onChange={handleFileChange}
          />
        </label>
        <div className="flex flex-col grow h-full mt-1">
          <p className="font-semibold">Archivos seleccionados:</p>
          {imagePreviews.length > 0 && (
            <div className="grid grid-cols-2 gap-1">
              {imagePreviews.map((preview, index) => {
                return (
                  preview && (
                    <div key={index} className="flex justify-start mt-2 ">
                      <button
                        className="relative w-20 h-20 rounded-lg object-cover border-2 border-gray-300 bg-black"
                        onClick={() => handleFullScreen(preview)}
                      >
                        <img
                          src={preview}
                          alt={`Preview ${index}`}
                          className="absolute h-full top-0 hover:opacity-40 transition-all"
                          // Abre la imagen a pantalla completa
                        />
                        <div className="w-full h-full items-center flex justify-center">
                          <EyeIcon
                            className="h-10 w-10 opacity-100 hover:opacity-0"
                            fillClass="fill-white"
                            strokeClass="stroke-white"
                            hidden={true}
                          />
                        </div>
                      </button>
                      <label className="text-sky-800 ml-2 my-5">
                        {selectedFiles[index]?.name}
                      </label>
                    </div>
                  )
                );
              })}
            </div>
          )}
        </div>
      </>

      {isFullScreen && (
        <div
          className=""
          onClick={closeFullScreen} // Cerrar al hacer clic en cualquier parte de la pantalla
        >
          <img
            src={fullScreenImage}
            alt="Full Screen"
            className="max-w-full max-h-full object-contain"
          />
        </div>
      )}
    </div>
  );
}
