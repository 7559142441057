import { Subtitle } from "components/common/Subtitle";
import { AutoHeigth } from "components/common/AutoHeigth";
import { BackGroundSections } from "components/common/BackGroundSections";
import { ChangePasswordSection } from "./ChangePasswordSection";
import { DataUser } from "./DataUser";
import { ChargingComponent } from "components/common/ChargingComponent";
import { useState } from "react";
import { UserImage } from "./UserImage";

export function UserVisual({ submodule }) {
  const [isLoading, setisLoading] = useState(false);

  return (
    <div className={`${submodule === "Perfil" ? "" : "hidden"}`}>
      <BackGroundSections>
        <div className={isLoading ? "appear-animation" : "hidden"}>
          <ChargingComponent />
        </div>

        <div className={!isLoading ? "appear-animation" : "hidden"}>
          <AutoHeigth maxPx={"94px"}>
            <div className="text-[#1a3365] mt-2">
              <Subtitle text={`Perfil de Usuario`} />
            </div>
            <UserImage />
            <DataUser setisLoading={setisLoading} />
            <div className="text-[#1a3365] mt-6">
              <Subtitle text={`Cambiar contraseña`} />
            </div>
            <ChangePasswordSection setisLoading={setisLoading} />
          </AutoHeigth>
        </div>
      </BackGroundSections>
    </div>
  );
}
