import React, { useEffect, useState } from "react";
import { getCourses, saveCurseFinalized } from "hooks/commonData";
import img from "../../utils/Certificado.png";
import jsPDF from "jspdf";

const generateCertification = async (
  name,
  course,
  userId,
  courseId,
  duration
) => {
  const doc = new jsPDF("l", "mm", [1920, 1080]);

  doc.addImage(
    img,
    "PNG",
    0,
    0,
    doc.internal.pageSize.getWidth(),
    doc.internal.pageSize.getHeight()
  );

  doc.setFont("montserrat", "normal", 30);
  doc.setFontSize(150);
  const nameToDisplay = name || "Nombre no disponible";
  const nameWidth = doc.getTextWidth(nameToDisplay);
  const nameX = (doc.internal.pageSize.getWidth() - nameWidth) / 2;
  doc.text(nameToDisplay, nameX, 400);

  doc.setFont("montserrat", "bold", 30);
  doc.setFontSize(70);
  const courseWidth = doc.getTextWidth(course);
  const courseX = (doc.internal.pageSize.getWidth() - courseWidth) / 2;
  doc.text(course, courseX, 560);

  doc.setFontSize(50);
  const durationText = `Con duración de: ${duration}`;
  const durationWidth = doc.getTextWidth(durationText);
  const durationX = (doc.internal.pageSize.getWidth() - durationWidth) / 2;
  doc.text(durationText, durationX, 630);

  doc.setFontSize(50);
  doc.text("Emitido el:", 350, 800);

  const date = new Date().toLocaleDateString();
  doc.text(date, 450, 800);

  const certificateId = `${courseId}-${userId}`;
  const certificateIdWidth = doc.getTextWidth(certificateId);
  const certificateIdX =
    doc.internal.pageSize.getWidth() - certificateIdWidth - 250;
  doc.setFont("montserrat", "normal", 20);
  doc.text(`Certificado ID: ${certificateId}`, certificateIdX, 828);

  doc.save(`${course}.pdf`);

  const dateFinalized = new Date().toISOString();
  await saveCurseFinalized(userId, courseId, 1, dateFinalized);
};

function CertificateGenerator(props) {
  const { name, course, userId, courseId } = props;
  const [duration, setDuration] = useState(null);

  useEffect(() => {
    const fetchCourseDuration = async () => {
      const courseData = await getCourses(courseId);
      if (courseData && courseData.length > 0) {
        setDuration(courseData[0].tiempo_curso);
      }
    };

    fetchCourseDuration();
  }, [courseId]);

  return (
    <div>
      <button
        className="p-2 border rounded-md"
        onClick={() =>
          generateCertification(name, course, userId, courseId, duration)
        }
      >
        Generar Certificado
      </button>
    </div>
  );
}

export default CertificateGenerator;
