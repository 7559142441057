export function PythonIcon({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 385 388"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.0369 283.756C25.3938 278.535 16.9291 270.026 7.7168 251.268C0.354697 236.278 0 233.605 0 193.129C0 152.289 0.298959 150.094 7.99756 134.416C16.9267 116.232 28.388 106.106 45.2549 101.499C51.9156 99.6792 83.6135 98.4017 122.329 98.3924C185.924 98.3772 188.09 98.1926 188.09 92.7899C188.09 87.4875 185.75 87.1635 142.128 86.4259L96.1665 85.6487L96.1895 60.0334C96.2175 28.634 99.9632 21.6617 122.295 11.438C144.407 1.31448 178.684 -2.42204 212.807 1.5711C246.242 5.4836 267.19 14.8827 277.627 30.6541L284.851 41.5703L283.847 97.6771C282.732 159.975 282.274 161.677 262.622 176.677L252.185 184.644L186.904 186.231C113.422 188.018 108.748 189.166 95.8958 208.587C89.3509 218.477 88.4126 223.246 87.0066 253.763L85.4346 287.881L68.1731 287.757C58.6793 287.689 46.0181 285.888 40.0369 283.756ZM146.876 57.1624C153.255 50.7832 152.366 40.0327 145.035 34.8974C137.096 29.3371 133.074 29.3466 127.481 34.9391C118.434 43.986 124.842 61.6071 137.179 61.6071C140.068 61.6071 144.432 59.607 146.876 57.1624Z"
        fill="#3570A0"
      />
      <path
        d="M168.291 386.1C151.381 384.569 138.739 381.339 127.642 375.714C115.186 369.4 107.61 362.724 102.736 353.77L98.7891 346.518L99.2454 291.744L99.7017 236.97L103.858 228.761C109.973 216.683 119.946 208.567 134.35 203.944C137.422 202.958 162.365 201.902 200.111 201.161L260.922 199.966L268.258 196.215C277.96 191.255 284.748 184.037 290.279 172.801L294.814 163.589L295.546 132.397L296.277 101.205L316.783 101.648C341.587 102.183 348.174 104.062 358.603 113.575C376.996 130.355 385.919 162.004 383.993 203.626C381.951 247.749 371.021 270.268 345.067 283.82L337.29 287.881L264.811 288.295L192.333 288.709V294.286V299.863L237.941 300.236L283.549 300.609L283.936 326.753C284.19 343.888 283.77 354.231 282.719 356.769C278.868 366.065 261.271 377.675 243.951 382.348C227.503 386.786 194.138 388.441 168.291 386.1ZM256.544 350.618C260.367 348.876 264.458 341.669 264.458 336.676C264.458 332.286 260.35 325.873 256.379 324.064C246.239 319.444 235.896 324.226 233.984 334.416C231.561 347.332 244.058 356.307 256.544 350.618Z"
        fill="#FDD141"
      />
    </svg>
  );
}
