import { toast } from "sonner";
import { supabase } from "utils/SupabaseClient";

export async function assignPermissionToRole(roleId, permissionId) {
  const { data, error } = await supabase
    .from("auth_role_permissions")
    .insert([{ role_id: roleId, permission_id: permissionId }]);

  if (error) {
    console.error("Error assigning permission to role:", error);
    return null;
  }
  return data;
}

export async function assignRoleToUser(userId, roleId) {
  const { data: existingRole, error: existingRoleError } = await supabase
    .from("auth_user_roles")
    .select("*")
    .eq("user_id", userId)
    .eq("role_id", roleId);

  if (existingRole && existingRole.length > 0) {
    console.log("The user already has this role.");
    return null;
  }

  const { data, error } = await supabase
    .from("auth_user_roles")
    .insert({ user_id: userId, role_id: roleId });

  if (error) {
    console.error("Error assigning role to user:", error);
    return null;
  }
  return data;
}

export async function deleteRoleFromUser(userId, roleId) {
  const { data, error } = await supabase
    .from("auth_user_roles")
    .delete()
    .eq("user_id", userId)
    .eq("role_id", roleId);

  if (error) {
    toast.error("Error deleting role from user:", error);
    return null;
  }
  return data;
}

export async function createPermission(name, description, createdBy) {
  const { data, error } = await supabase.from("auth_permissions").insert([
    {
      name,
      description,
      created_by: createdBy,
      modified_by: createdBy,
    },
  ]);

  if (error) {
    console.error("Error creating permission:", error);
    return null;
  }
  return data;
}

export async function deletePermission(permissionId) {
  const { error: deleteRelationError } = await supabase
    .from("auth_role_permissions")
    .delete()
    .eq("permission_id", permissionId);

  if (deleteRelationError) {
    console.error(
      "Error deleting role-permission relation:",
      deleteRelationError
    );
    return null;
  }

  const { data, error } = await supabase
    .from("auth_permissions")
    .delete()
    .eq("id", permissionId);

  if (error) {
    console.error("Error deleting permission:", error);
    return null;
  }
  return data;
}

export async function createRole(name, description) {
  const { data, error } = await supabase
    .from("auth_roles")
    .insert([{ role_name: name, description }]);

  if (error) {
    console.error("Error creating role:", error);
    return null;
  }
  return data;
}

export async function hasPermission(userId, permissionName) {
  const { data, error } = await supabase
    .from("auth_user_roles")
    .select("role_id")
    .eq("user_id", userId);

  if (error) {
    console.error("Error fetching user roles:", error);
    return false;
  }

  const roleIds = data.map((role) => role.role_id);

  const { data: permissionsData, error: permissionsError } = await supabase
    .from("auth_role_permissions")
    .select("permission_id")
    .in("role_id", roleIds);

  if (permissionsError) {
    console.error("Error fetching permissions:", permissionsError);
    return false;
  }

  const permissionIds = permissionsData.map(
    (permission) => permission.permission_id
  );

  const { data: permissionData, error: permissionFetchError } = await supabase
    .from("auth_permissions")
    .select("id")
    .eq("name", permissionName);

  if (permissionFetchError) {
    console.error("Error fetching permission:", permissionFetchError);
    return false;
  }

  const permissionId = permissionData[0]?.id;

  if (permissionId && permissionIds.includes(permissionId)) {
    return true;
  }

  return false;
}

export async function getUserPermissions(userId) {
  try {
    const { data, error } = await supabase
      .from("auth_user_permissions")
      .select("permission_id")
      .eq("user_id", userId);

    if (error) {
      console.error("Error fetching user permissions:", error);
      return [];
    }

    return data.map((item) => item.permission_id);
  } catch (error) {
    console.error("Error fetching user permissions:", error);
    return [];
  }
}

export async function removePermissionFromUser(userId, permissionId) {
  try {
    const { data, error } = await supabase
      .from("auth_user_permissions")
      .delete()
      .eq("user_id", userId)
      .eq("permission_id", permissionId);

    if (error) {
      console.error("Error removing permission from user:", error);
      return null;
    }

    return data;
  } catch (error) {
    console.error("Error removing permission from user:", error);
    return null;
  }
}
