export function PowerAppsIcon({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 437 421"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M213.169 393.759L196.108 412.84C196.108 412.84 181.137 428.56 168.072 413.589L4.8247 226.253C4.8247 226.253 -6.47124 212.507 5.50521 193.25L168.564 7.71485C168.564 7.71485 181.477 -7.01563 195.223 7.47863L213.069 27.3146L156.198 89.5621C156.198 89.5621 148.018 103.227 154.947 110.926L234.623 197.877C234.623 197.877 246.191 212.643 234.078 224.62L156.871 307.533C156.871 307.533 147.104 322.305 155.717 331.303L213.169 393.759Z"
        fill="url(#paint0_linear_777_4341)"
      />
      <path
        d="M212.643 26.8503L231.137 7.53935C231.137 7.53935 244.747 -7.09089 256.757 4.4092L430.552 192.902C430.552 192.902 444.638 212.568 429.599 226.858L259.479 412.833C259.479 412.833 247.933 427.795 233.353 415.477L157.138 332.846C157.138 332.846 146.277 325.016 156.446 307.069L230.75 227.273C230.75 227.273 248.984 213.549 232.406 195.458L154.521 110.462C154.521 110.462 147.831 102.154 155.772 89.0978L212.643 26.8503Z"
        fill="url(#paint1_linear_777_4341)"
      />
      <path
        d="M261.771 225.996C261.771 225.996 247.178 213.152 261.24 192.902L332.502 115.324C332.502 115.324 345.49 100.589 359.084 115.246L430.553 192.902C430.553 192.902 444.74 210.402 430.115 226.277L358.928 303.839C358.928 303.839 345.23 315.302 332.678 303.237"
        fill="url(#paint2_linear_777_4341)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_777_4341"
          x1="-1.2048e-05"
          y1="210.576"
          x2="239.607"
          y2="210.576"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#952E8A" />
          <stop offset="1" stop-color="#73236C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_777_4341"
          x1="151.887"
          y1="210.171"
          x2="436.539"
          y2="210.171"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E084C5" />
          <stop offset="1" stop-color="#BD75A6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_777_4341"
          x1="255.125"
          y1="208.756"
          x2="436.731"
          y2="208.756"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E799C9" />
          <stop offset="1" stop-color="#E791CB" />
        </linearGradient>
      </defs>
    </svg>
  );
}
