import { decode } from "base64-arraybuffer";
import { supabase } from "utils/SupabaseClient";
import { createCustumerTicket } from "api/tickets/route";

export async function saveCustumerChange({
  ticketTypeId,
  projectId,
  summary,
  description,
  serviceId,
  urgencyValue,
  reasonValue,
  customerImpactValue,
  additionalInfo,
  files,
}) {
  let ticketId;
  let createdBy;
  let fileNames = [];

  try {
    // Verificamos si alguno de los archivos ya existe
    for (const file of files) {
      const fileName = file.name;

      const { data: existingFileData, error: fileCheckError } =
        await supabase.storage
          .from("ticket")
          .list("request", { search: fileName });

      if (fileCheckError) {
        throw new Error(`Error al verificar el archivo: ${fileName}`);
      }

      if (existingFileData && existingFileData.length > 0) {
        throw new Error(
          `El archivo o imagen '${fileName}' ya existe. No se insertará el ticket ni los archivos.`
        );
      }
    }

    const dataTicket = await createCustumerTicket({
      ticketTypeId,
      serviceId,
      projectId,
      summary,
      description,
    });

    ticketId = dataTicket[0];
    createdBy = dataTicket[1];

    const { data, error } = await supabase.from("th_ticket_fields").insert([
      {
        ticket_id: ticketId,
        field_definition_id: 10,
        field_value: urgencyValue,
        created_by: createdBy,
        modified_by: createdBy,
      },
      {
        ticket_id: ticketId,
        field_definition_id: 8,
        field_value: reasonValue,
        created_by: createdBy,
        modified_by: createdBy,
      },
      {
        ticket_id: ticketId,
        field_definition_id: 6,
        field_value: customerImpactValue,
        created_by: createdBy,
        modified_by: createdBy,
      },
      {
        ticket_id: ticketId,
        field_definition_id: 16,
        field_value: additionalInfo,
        created_by: createdBy,
        modified_by: createdBy,
      },
    ]);

    for (const file of files) {
      try {
        // Convertir el archivo a base64
        const base64File = await convertToBase64(file);
        const base64FileString = base64File.replace(/^data:.+;base64,/, "");
        const decodedBuffer = decode(base64FileString);
        const fileBlob = new Blob([decodedBuffer]);

        const fileName = file.name;

        const { error: errorFile } = await supabase
          .from("th_ticket_files")
          .insert([
            {
              ticket_id: ticketId,
              created_at: new Date(),
              file_name: `request/${file.name}`,
            },
          ]);

        if (errorFile) {
          throw new Error(`Error al guardar el archivo ${file.name}`);
        }

        fileNames.push(fileName);

        const { error: uploadFiles } = await supabase.storage
          .from("ticket")
          .upload(`request/${fileName}`, fileBlob, {
            contentType: file.type,
          });

        if (uploadFiles && uploadFiles.statusCode === "409") {
          throw new Error(`El archivo o imagen '${fileName}' ya existe`);
        }
      } catch (error) {
        throw error;
      }
    }
  } catch (error) {
    return { data: null, error: error.message };
  }
}

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      // El resultado es la cadena Base64 (con el prefijo data:...)
      resolve(reader.result.split(",")[1]); // Elimina el prefijo `data:*/*;base64,`
    };

    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file); // Convierte el archivo a Base64
  });
}
