import { useState, useEffect } from "react";

export function ConfirmDelete({ message, onConfirm }) {
  const [text, setText] = useState("");
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    if (text.toLowerCase() == "eliminar") {
      setIsDelete(true);
      onConfirm(true);
    } else {
      onConfirm(false);
      setIsDelete(false);
    }
  }, [text]);

  return (
    <>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        Por favor, escribe la palabra <strong>"Eliminar"</strong> {message}.
      </label>
      <div className="mt-2">
        <input
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-700 sm:text-sm sm:leading-6"
        />
      </div>
      {!isDelete && (
        <p className="text-red-500">Por favor escribe la correctamente.</p>
      )}
    </>
  );
}
