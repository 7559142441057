import React, { useState, useEffect } from "react";
import { supabase } from "utils/SupabaseClient";
import { Country, State, City } from "country-state-city";
import { ButtonSave } from "components/common/ButtonSave";
import Selector from "components/common/Selector";
import { toast } from "sonner";
import {
  createUser,
  createSubcompany,
  createUserRole,
  setChannelsInfo,
  createChannelsInfo,
} from "../../api/login/route";
import { ChargingComponent } from "components/common/ChargingComponent";

export function SignupForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState(null);
  const [jobTitle, setJobTitle] = useState("");
  const [contactInformation, setContactInformation] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [contactChannels, setContactChannels] = useState([]);

  useEffect(() => {
    const countriesData = Country.getAllCountries();
    setCountries(countriesData);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const statesData = State.getStatesOfCountry(selectedCountry.isoCode);
      setStates(statesData);
      setSelectedState(null);
      setCities([]);
      setSelectedCity(null);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      const citiesData = City.getCitiesOfState(
        selectedCountry.isoCode,
        selectedState.isoCode
      );
      setCities(citiesData);
      setSelectedCity(null);
    }
  }, [selectedState, selectedCountry]);

  useEffect(() => {
    const fetchContactChannels = async () => {
      const { data, error } = await supabase
        .from("dim_canales_contacto")
        .select("id, name:fuente");

      if (error) {
        console.error("Error fetching contact channels:", error.message);
        return;
      }
      setContactChannels(data);
    };

    fetchContactChannels();
  }, []);

  const getUserData = async () => {
    const { data: user, error } = await supabase.auth.getUser();
    if (error) {
      console.error("Error fetching user data:", error.message);
      return null;
    }
    return user;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const user = await getUserData();
      if (!user) {
        throw new Error("No user found");
      }

      const countryIsoCode = selectedCountry?.name || null;
      const stateIsoCode = selectedState?.name || null;
      const cityName = selectedCity?.name || null;
      const phoneCode = selectedCountry?.phonecode || phoneCode;

      const userResponse = await createUser({
        id: user.user.id,
        firstName,
        lastName,
        email: user.user.email,
        phoneNumber,
        jobTitle,
        countryIsoCode,
        stateIsoCode,
        cityName,
        phoneCode,
      });

      const roleResponse = await createUserRole({
        id: user.user.id,
      });

      console.log("roleResponse", roleResponse);

      const subCompanyResponse = await createSubcompany({
        user: userResponse.data[0],
        name: companyName,
      });

      if (contactInformation) {
        const channelResponse = await setChannelsInfo({
          contactInformation: contactInformation.name,
        });

        console.log("channelResponse", channelResponse);

        const createChannelResponse = await createChannelsInfo({
          id: userResponse.data[0].id,
          type: channelResponse.data[0].id,
        });
      }

      setIsLoading(false);
      setIsSuccess(true);
      toast.success("Tu cuenta ha sido registrada correctamente");
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      toast.error("Error. Por favor intente de nuevo");
      console.error("Error during registration:", error);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="col-span-2 text-center py-4">
          <ChargingComponent />
        </div>
      ) : (
        <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-xl p-8">
          <div className="flex justify-start mb-6">
            <img
              src="assets/img/horizontal/principal.png"
              alt="login-image"
              className="w-36"
            />
          </div>
          <h2 className="text-2xl font-semibold text-gray-900 mb-6 text-center">
            ¡Bienvenido!
          </h2>
          <p className="text-gray-600 text-sm mb-8 text-center">
            Complete el registro de su cuenta para continuar en la plataforma
          </p>

          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-1 gap-6 sm:grid-cols-2"
          >
            <div className="sm:col-span-1">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-900"
              >
                Nombre
              </label>
              <input
                type="text"
                id="first-name"
                className="mt-2 block w-full px-4 py-2 text-sm border border-gray-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600 transition duration-200"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>

            <div className="sm:col-span-1">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-900"
              >
                Apellido
              </label>
              <input
                type="text"
                id="last-name"
                className="mt-2 block w-full px-4 py-2 text-sm border border-gray-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600 transition duration-200"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="company-name"
                className="block text-sm font-medium text-gray-900"
              >
                Nombre de la Empresa
              </label>
              <input
                type="text"
                id="company-name"
                className="mt-2 block w-full px-4 py-2 text-sm border border-gray-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600 transition duration-200"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder="Nombre de tu empresa (Opcional)"
              />
            </div>

            <div className="sm:col-span-1">
              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-900"
              >
                País
              </label>
              <Selector
                data={countries}
                selected={selectedCountry}
                setSelected={setSelectedCountry}
              />
            </div>

            <div className="sm:col-span-1">
              <label
                htmlFor="state"
                className="block text-sm font-medium text-gray-900"
              >
                Departamento/Estado/Provincia
              </label>
              <Selector
                data={states}
                selected={selectedState}
                setSelected={setSelectedState}
              />
            </div>

            <div className="sm:col-span-1">
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-900"
              >
                Ciudad
              </label>
              <Selector
                data={cities}
                selected={selectedCity}
                setSelected={setSelectedCity}
              />
            </div>

            <div className="sm:col-span-1">
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium text-gray-900"
              >
                Número de Teléfono
              </label>
              <input
                type="tel"
                id="phoneNumber"
                className="mt-2 block w-full px-4 py-2 text-sm border border-gray-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600 transition duration-200"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="contactInformation"
                className="block text-sm font-medium text-gray-900"
              >
                ¿Cómo nos encontraste?
              </label>
              <Selector
                data={contactChannels}
                selected={contactInformation}
                setSelected={setContactInformation}
              />
            </div>

            <div className="col-span-2 flex justify-end mt-6">
              <ButtonSave HadleSubmit={handleSubmit} />
            </div>
          </form>
        </div>
      )}
    </>
  );
}
