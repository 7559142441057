import { BackIcon } from "components/svg/BackIcon";

export function ModalBG({ title, open, onClose, children }) {
  return (
    <div
      className={`${
        open ? "appear-animation" : "hidden"
      } bg-[#0000004c] fixed z-40 inset-0 overflow-none transition-all flex justify-center`}
    >
      <div className=" bg-white pt-2 pb-1 px-2 2xl:p-4 rounded-md text-sky-700 w-3/5 self-center">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold">{title}</h2>
          <button onClick={onClose}>
            <BackIcon w={30} h={30} />
          </button>
        </div>
        <div className="pt-2 pb-1 px-4 2xl:p-4">{children}</div>
      </div>
    </div>
  );
}
