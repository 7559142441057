import { Th } from "./Th";

export function THead({ nameRows }) {
  return (
    <thead className={`text-xs text-sky-800 uppercase bg-gray-50 top-0 sticky`}>
      {nameRows.map((item) => (
        <Th title={item.label} responsive={item.responsive} />
      ))}
    </thead>
  );
}
