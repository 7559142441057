import { useEffect, useState } from "react";
import { getIntegrations } from "api/bi-module/integration";

import { Table } from "./Table";
import { AutoHeigth } from "components/common/AutoHeigth";
import { BackGroundSections } from "components/common/BackGroundSections";
import { IntegrationsBanner } from "components/common/IntegrationsBanner";

export function Integrations({ submodule, setSubmodule }) {
  const [data, setData] = useState([]);
  const [isRecharge, setIsRecharge] = useState(false);

  useEffect(() => {
    setSubmodule(submodule);
  }, [submodule]);

  useEffect(() => {
    getIntegrations().then((r) => {
      console.log("r", r);
      setData(r);
      setIsRecharge(false);
    });
  }, [isRecharge]);

  return (
    <>
      <div className={`${submodule === "Integraciones" ? "" : "hidden"} `}>
        <IntegrationsBanner setRecharge={setIsRecharge} />
        <AutoHeigth maxPx="275px">
          <BackGroundSections>
            <label className="text-[#2E5DA1] text-xl font-semibold">
              Mis Conexiones
            </label>
            <Table items={data} setRecharge={setIsRecharge} />
          </BackGroundSections>
        </AutoHeigth>
      </div>
    </>
  );
}
