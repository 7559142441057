import { useNavigate } from "react-router-dom";

import { LogoSiigo } from "components/svg/LogoSiigo";

export function TemplateBanner({ children }) {
  return <div className="flex justify-center h-32 my-4">{children}</div>;
}

export function ItemTemplate({ title, custom, setActive, setReport, report }) {
  //TODO: Cambiar la imagen de Logo que sea dinamica
  //TODO: Cambiar la ruta de la imagen para que sea desde el Storage
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/support");
  };
  const handleClick = () => {
    setActive(true);
    setReport(report);
  };

  return (
    <div className="bg-white border border-gray-300 rounded-lg flex flex-col justify-center text-center w-28 px-2 pt-1 pb-2 mx-2">
      <LogoSiigo className="h-6 w-18 py-0.5" />
      <hr className="border-sky-500" />
      <img
        className="object-contain h-16 w-full py-1"
        src="https://learn.microsoft.com/es-es/power-bi/consumer/media/end-user-report-open/power-bi-dashboards.png"
        alt={title}
      />
      <h2 className="text-[0.5rem] truncate">{title}</h2>
      <div
        onClick={custom ? handleClick : handleNavigate}
        className="text-[0.5rem] text-yellow-500 underline cursor-pointer"
      >
        {custom ? "Ver plantilla" : "Cotiza un desarrollo a la medida"}
      </div>
    </div>
  );
}
