export function Button({ icon, text, onClick, type, className }) {
  return (
    <button
      className={
        className
          ? className
          : "appear-animation bg-sky-800 hover:bg-yellow-500  p-2 text-white rounded-lg transition-colors"
      }
      onClick={onClick}
      type={type}
    >
      <div className={`${icon && "flex justify-center"}`}>
        {icon}
        <label className="cursor-pointer">{text}</label>
      </div>
    </button>
  );
}
