export function LockerStudioIcon({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 264 424"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95.5227 166.724C162.097 147.279 234.21 184.268 256.71 250.274C279.21 316.28 246.272 388.443 181.865 414.247C117.458 440.051 44.2724 410.405 15.568 346.885C-13.1363 283.364 12.7528 208.346 74.3945 176.423L91.3117 212.764C91.3117 212.764 79.7907 220.005 71.2863 227.767C62.7819 235.528 57.2942 243.811 53.0973 251.905C45.5894 266.384 41.9714 283.854 44.1779 300.014C47.1078 321.471 56.5308 343.988 72.7131 358.38C89.8609 373.63 114.744 382.221 137.669 381.191C158.385 380.26 179.726 370.166 194.282 355.396C210.291 339.152 221.988 315.532 221.74 292.726C221.465 267.389 209.31 239.859 189.727 223.779C179.199 215.134 160.72 207.299 143.498 204.625C126.276 201.951 110.312 204.439 110.312 204.439L95.5227 166.724Z"
        fill="#4186F4"
      />
      <path
        d="M68.5183 166.541C41.8711 171.618 15.3256 157.226 4.6645 131.923C-5.99664 106.619 2.08787 77.1937 24.1114 61.1424C46.1349 45.0912 76.1868 46.7215 96.3995 65.064L74.6861 84.3247C74.6861 84.3247 69.1837 81.1149 63.6848 79.8232C58.1859 78.5314 52.6906 79.1577 48.5794 80.7051C39.3482 84.1794 31.5803 93.5081 29.2227 103.085C27.2339 111.165 29.6462 120.953 34.9215 127.387C37.6736 130.744 43.2859 135.538 48.5226 137.399C53.7593 139.26 58.6206 138.188 58.6206 138.188L68.5183 166.541Z"
        fill="#6299F4"
      />
      <path
        d="M105.058 74.8552C114.084 87.7383 117.607 103.768 114.828 119.319C112.05 134.87 103.207 148.628 90.298 157.481L79.0254 129.793C79.0254 129.793 82.602 125.916 83.9196 123.655C85.5254 120.899 86.7948 117.89 87.4878 114.777C88.2447 111.377 88.5866 107.788 88.0717 104.343C87.5308 100.725 84.2806 94.0425 84.2806 94.0425L105.058 74.8552Z"
        fill="#6299F4"
      />
      <path
        d="M100.013 57.1997C90.3878 40.9944 92.8393 19.6316 107.465 7.95349C122.091 -3.72465 142.963 -2.40839 156.068 11.0185C169.172 24.4454 170.374 45.7464 158.867 60.6189C147.359 75.4915 127.522 76.618 111.68 66.7295L127.528 53.5016C127.528 53.5016 130.423 54.34 133.803 53.6025C137.182 52.8651 141.141 51.0207 143.258 49.3143C145.99 47.1119 147.935 43.1191 148.242 39.6232C148.645 35.0235 146.576 30.1811 143.773 26.5118C141.782 23.9046 138.812 21.7581 135.62 20.9981C132.088 20.157 127.969 20.5786 124.815 22.3786C121.203 24.4402 118.047 27.0164 116.36 30.1441C114.673 33.2718 114.454 36.951 115.403 41.0937L100.013 57.1997Z"
        fill="#ADCBFB"
      />
    </svg>
  );
}
