import { useState } from "react";

function Option({ value, name }) {
  return <option value={value}>{name}</option>;
}

export function Select({ title, value, options, field, onChange, required }) {
  const [values, setValue] = useState(-1);

  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div>
      <label className={`block text-sm font-medium leading-6 text-gray-900`}>
        {title} {required && <span className="text-red-500">*</span>}
      </label>
      <div className="mt-2">
        <select
          id={field}
          name={field}
          value={value}
          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-700 sm:max-w-xs sm:text-sm sm:leading-6`}
          onChange={handleChange}
        >
          <Option value={-1} name={"Selecciona una opción"} />
          {options.map((option) => (
            <Option
              key={option.value}
              value={option.value}
              name={option.name}
            />
          ))}
        </select>
      </div>
    </div>
  );
}
