import { useState } from "react";
import { VscGraph } from "react-icons/vsc";
import { IoReader } from "react-icons/io5";
import { TbPlugConnected } from "react-icons/tb";
import { MdOutlineGppBad } from "react-icons/md";
import { SiAmazonapigateway } from "react-icons/si";

export function Tabs({ items, setActive, active }) {
  const [selectedSection, setSelectedSection] = useState(active);

  const selectSection = (name) => {
    setSelectedSection(name);
    setActive(name);
  };

  return (
    <div className="stiky top-0 z-[10]">
      <div className="relative flex w-full flex-col pt-[2px] md:pt-0">
        <div className="mx-auto flex w-full flex-col">
          <div className="flex w-full flex-col">
            <div className="flex items-center justify-center rounded-lg bg-zinc-100 mx-auto">
              {items.map((item) => (
                <Section
                  key={`${item.id}-${item.nombre}`}
                  name={item.nombre}
                  isSelected={selectedSection === item.nombre}
                  selectSection={selectSection}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Section({ isSelected, name, selectSection }) {
  return (
    <div
      onClick={() => selectSection(name)}
      className={`w-24 sm:w-32 md:w-44 h-6 md:h-8 cursor-pointer items-center justify-center md:justify-between md:px-4 transition-colors rounded-sm text-base
        font-semibold text-zinc-950 ${
          isSelected
            ? "boton bg-white "
            : "hover:bg-[#F6D033] hover:text-white transition-all"
        }`}
    >
      <p className="hidden lg:block text-center mt-[4px]">{name}</p>
      <RetrieveIcon name={name} className={`lg:hidden w-full h-full p-[2px]`} />
    </div>
  );
}

function RetrieveIcon({ name, className }) {
  switch (name) {
    case "Reportes":
      return <VscGraph className={className} />;
    case "Conexiones":
      return <TbPlugConnected className={className} />;
    case "Integraciones":
      return <SiAmazonapigateway className={className} />;
    case "Documentos":
      return <IoReader className={className} />;
    default:
      return <MdOutlineGppBad className={className} />;
  }
}
