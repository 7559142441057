import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUserData } from "utils/SupabaseClient";
import { editCourse, getCourses, saveCourse } from "hooks/commonData";
import { Text } from "components/tailwind/Text";
import { Files } from "components/tailwind/Files";
import { Select } from "components/tailwind/Select";
import { Button } from "components/common/Button";
import { Module } from "components/common/Module";
import { InputNumber } from "components/common/InputNumber";
import { toast } from "sonner";

export function NewCourse() {
  const [userId, setUserId] = useState();
  const [nameNewCourse, setNameNewCourse] = useState("");
  const [descriptionNewCourse, setDescriptionNewCourse] = useState();
  const [isPaid, setIsPaid] = useState();
  const [files, setFiles] = useState([]);
  const [price, setPrice] = useState();
  const [course, setCourse] = useState({});

  const navigate = useNavigate();
  const param = useParams();

  let optionsIsPad = [
    { value: "TRUE", name: "SI" },
    { value: "FALSE", name: "NO" },
  ];

  const handleCancel = () => {
    navigate("/trainingpmo");
  };

  const handleSaveCourse = async () => {
    if (!nameNewCourse) {
      return toast.error("El nombre es obligatorio");
    }

    if (!descriptionNewCourse) {
      return toast.error("La descripción es obligatoria");
    }

    if (isPaid === "-1" || isPaid === undefined) {
      return toast.error("Escoja si el curso será o no pago");
    }

    if (isPaid === "true") {
      if (!price) {
        return toast.error("El precio es obligatorio");
      }
    }
    let newCourse = {
      nombre: nameNewCourse,
      description: descriptionNewCourse,
      isPaid: isPaid,
      id_user: userId,
      image: files[0]?.name,
      price: price,
      state: course ? course.estado_curso : 5,
    };

    try {
      if (param.id_course) {
        await editCourse(
          newCourse,
          "cursos",
          "miniaturas",
          files,
          param.id_course
        );
        toast.success("Curso editado con exito");
      } else {
        await saveCourse(newCourse, "cursos", "miniaturas", files);
        toast.success("Curso creado con exito");
      }

      navigate("/trainingpmo");
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getUserData().then((data) => {
      setUserId(data.id);
    });
  }, []);

  useEffect(() => {
    if (param.id_course) {
      getCourses(param.id_course).then((dato) => {
        setCourse(dato[0]);
        if (dato[0].es_pago === false) {
          setIsPaid("FALSE");
        } else if (dato[0].es_pago === true) {
          setIsPaid("TRUE");
        } else {
          setIsPaid("-1");
        }

        setNameNewCourse(dato[0].nombre_curso);
        setDescriptionNewCourse(dato[0].descripcion);
      });
    }
  }, [param.id_course]);

  return (
    <Module
      title={"Creación de un Nuevo Curso"}
      back={true}
      linkToBack={"/trainingpmo"}
    >
      <div className="ml-1 grow mt-2">
        <div class="">
          <div class=" bg-white p-2 rounded-lg mx-auto col-span-2 2xl:w-8/12 xl:w-7/12">
            <div>
              <Text
                title={"Nombre"}
                onChange={setNameNewCourse}
                value={nameNewCourse}
                required={true}
              />
              <Text
                title={"Descripción"}
                onChange={setDescriptionNewCourse}
                value={descriptionNewCourse}
                required={true}
              />
              <div className="flex gap-5 w-full">
                <Select
                  title={"Es de pago"}
                  value={isPaid}
                  options={optionsIsPad}
                  field={"TRUE"}
                  onChange={setIsPaid}
                  required={true}
                />
                <InputNumber
                  title={"Precio"}
                  field={"precio"}
                  onChange={setPrice}
                  value={course && course.valor_curso}
                  required={isPaid === "TRUE" && true}
                  disabled={isPaid === "FALSE" && true}
                />
              </div>
              <Files
                title={"Imagen"}
                message={"PNG, JPG up to 50MB"}
                files={files}
                setFiles={setFiles}
                disabled={files.length > 0 && true}
              />
            </div>

            <Button text={"Guardar"} onClick={handleSaveCourse} />
            {param.id_course && (
              <span className="ml-2">
                <Button text={"Cancelar"} onClick={handleCancel} />
              </span>
            )}
          </div>
        </div>
      </div>
    </Module>
  );
}
