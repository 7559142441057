import { formatDate } from "utils/Operations";

export function ReportBanner({ report, setActive, setReport }) {
  const handleClick = () => {
    setActive(true);
    setReport(report);
  };
  const imgURL =
    "https://learn.microsoft.com/es-es/power-bi/consumer/media/end-user-report-open/power-bi-dashboards.png";

  return (
    <div
      onClick={handleClick}
      className={`bg-sky-700 text-white rounded-md p-4 flex flex-col items-center cursor-pointer`}
    >
      {/*TODO: Poner el Background por bases de datos */}
      <h2 className="text-center uppercase font-bold">{report.title}</h2>
      {/*TODO: Poner la imagen por bases de datos */}
      <img className="h-40" src={imgURL} alt={`report-${report.title}`} />
      <section className="w-full py-2">
        <p className="text-sm">
          <strong>Descripción: </strong> {report.description}
        </p>
        <p className="pt-2 text-sm">
          <strong>Actualizado: </strong> {formatDate(report.updated_at)}
        </p>
        <p className="text-sm">
          {/*TODO: Poner los formatos por los formatos de bases de datos.*/}
          {report.templates.length} Orígenes de datos en formato csv.
        </p>
      </section>
    </div>
  );
}
