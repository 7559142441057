import React, { useState, useEffect } from "react";
import { supabase, getUserData } from "utils/SupabaseClient";
import { getModule } from "hooks/commonData";
import { AddIcon } from "components/svg/AddIcon";

const Section = ({
  sectionId,
  title,
  videoUrl,
  subsections,
  onEditModule,
  setUrl,
  setVideo,
  onVideoUpload,
  uploadProgress,
}) => {
  return (
    <div className="mb-4 p-4 border rounded-lg shadow-md">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold">{title}</h3>
        <div>
          <button
            onClick={() => onEditModule()}
            className="text-blue-500 hover:underline"
          >
            Más opciones
          </button>
        </div>
      </div>
      <div className="mt-2">
        {videoUrl ? (
          <video controls className="w-full h-48 object-cover rounded-md mb-4">
            <source src={videoUrl} type="video/mp4" />
            Tu navegador no soporta el formato de video.
          </video>
        ) : (
          <p>No hay video cargado.</p>
        )}

        {uploadProgress > 0 && (
          <div className="relative pt-1 mb-4">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block py-1 uppercase">
                  Subiendo video... {uploadProgress}%
                </span>
              </div>
            </div>
            <div className="flex mb-2 items-center justify-between">
              <div className="w-full bg-gray-200 rounded-full">
                <div
                  className="bg-blue-600 text-xs leading-none py-1 text-center text-white"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
            </div>
          </div>
        )}

        <input
          type="file"
          accept="video/*"
          onChange={onVideoUpload}
          className="mt-4"
        />
      </div>
    </div>
  );
};

const Modal = ({
  isOpen,
  closeModal,
  courseName,
  onEdit,
  onDelete,
  moduleImage,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg w-1/3">
        {/* <img
          src={moduleImage || "https://via.placeholder.com/150"}
          alt="Module"
          className="w-full h-48 object-cover rounded-md mb-4"
        /> */}
        <h2 className="text-xl mb-4">Editar o eliminar módulo</h2>
        <div className="mb-4">
          <label className="block text-sm">Nombre del módulo</label>
          <input
            type="text"
            defaultValue={courseName}
            className="w-full p-2 border rounded-lg"
            id="courseNameInput"
          />
        </div>
        <div className="flex justify-between">
          <button
            onClick={() =>
              onEdit(document.getElementById("courseNameInput").value)
            }
            className="bg-blue-500 text-white py-2 px-4 rounded-lg"
          >
            Cambiar Nombre
          </button>
          <button
            onClick={onDelete}
            className="bg-red-500 text-white py-2 px-4 rounded-lg"
          >
            Remover módulo
          </button>
        </div>
        <button
          onClick={closeModal}
          className="mt-4 w-full bg-gray-500 text-white py-2 px-4 rounded-lg"
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default function ModuleManager({ id_course, setUrl, setVideo }) {
  const [sections, setSections] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModule, setCurrentModule] = useState(null);
  const [courseState, setCourseState] = useState("Published");
  const [uploadProgress, setUploadProgress] = useState(0);

  const id_curso = String(id_course).split(",")[0];

  useEffect(() => {
    getModule(id_curso).then((r) => {
      setSections(
        r.map((item) => ({
          id: item.id,
          title: item.nombre_modulo,
          state: item.estado,
          videoUrl: item.videoUrl || "",
          subsections: [],
        }))
      );
    });
  }, [id_curso]);

  const handleAddSection = async () => {
    const newSection = {
      id: Date.now(),
      title: "Nuevo módulo",
      videoUrl: "",
      subsections: [],
    };
    try {
      const user = await getUserData();
      if (!user) {
        alert("Usuario no autenticado.");
        return;
      }

      const id_user = user.id;
      setSections((prevSections) => [...prevSections, newSection]);

      const { data: userData, error: userError } = await supabase
        .from("auth_users")
        .select("id")
        .eq("id", id_user)
        .single();

      if (userError || !userData) {
        throw new Error("Usuario no encontrado en la base de datos.");
      }

      const { data, error } = await supabase
        .from("dim_capacitacion_modulos")
        .insert([
          {
            nombre_modulo: newSection.title,
            curso_id: id_curso,
            uuid_creado: id_user,
            uuid_actualizado: id_user,
          },
        ]);

      if (error) {
        throw error;
      }
      
      if(data) {
       const insertedModule = data[0];
        setSections((prevSections) =>
          prevSections.map((section) =>
            section.id === newSection.id
              ? { ...section, id: insertedModule.id }
              : section
          )
        );
      }

    } catch (error) {
      alert("Error al añadir el módulo: " + error.message);
      setSections((prevSections) =>
        prevSections.filter((section) => section.id !== newSection.id)
      );
    }
  };

  const handleEditModuleName = async (newName) => {
    setSections((prevSections) =>
      prevSections.map((section) => {
        if (section.id === currentModule.id) {
          return { ...section, title: newName };
        }
        return section;
      })
    );

    try {
      const { error } = await supabase
        .from("dim_capacitacion_modulos")
        .update({ nombre_modulo: newName })
        .eq("id", currentModule.id);

      if (error) {
        throw error;
      }

      alert("Módulo actualizado correctamente.");
      setIsModalOpen(false);
    } catch (error) {
      alert("Error al actualizar el módulo: " + error.message);
    }
  };

  const handleRemoveModule = async () => {
    try {
      const { error: deleteModuleError } = await supabase
        .from("dim_capacitacion_modulos")
        .delete()
        .eq("id", currentModule.id);

      if (deleteModuleError) {
        throw deleteModuleError;
      }

      alert("Módulo eliminado correctamente.");
      setIsModalOpen(false);

      setSections((prevSections) =>
        prevSections.filter((section) => section.id !== currentModule.id)
      );
    } catch (error) {
      alert("Error al eliminar el módulo: " + error.message);
    }
  };

  const handleModalOpen = (module) => {
    setCurrentModule(module);
    setIsModalOpen(true);
  };

  const MAX_FILE_SIZE_MB = 50;

  const handleVideoUpload = async (event, moduleId) => {
    const file = event.target.files[0];
    if (file) {
      const fileSizeInMB = file.size / 1024 / 1024;
      if (fileSizeInMB > MAX_FILE_SIZE_MB) {
        alert(`El archivo excede el tamaño máximo de ${MAX_FILE_SIZE_MB} MB.`);
        return;
      }

      try {
        const user = await getUserData();
        if (!user) {
          alert("Usuario no autenticado.");
          return;
        }

        const id_user = user.id;
        const courseFolder = `${id_curso}`;

        const sanitizedFileName = file.name
          .toLowerCase()
          .replace(/\s+/g, "-")
          .replace(/[^\w\-\.]/g, "");

        const timestamp = Date.now();
        const filePath = `${courseFolder}/${moduleId}/${timestamp}-${sanitizedFileName}`;

        const { data: uploadData, error: uploadError } = await supabase.storage
          .from("videos")
          .upload(filePath, file, {
            onUploadProgress: (progressEvent) => {
              const percent = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              setUploadProgress(percent);
            },
          });

        if (uploadError) {
          throw uploadError;
        }

        const { data: videoData, error: videoError } = await supabase
          .from("dim_capacitacion_videos")
          .insert([
            {
              id_modulo: moduleId,
              uuid_creado: id_user,
              uuid_actualizado: id_user,
              url: filePath,
              titulo_video: sanitizedFileName,
            },
          ]);

        if (videoError) {
          throw videoError;
        }

        setSections((prevSections) =>
          prevSections.map((section) => {
            if (section.id === moduleId) {
              return { ...section, videoUrl: filePath };
            }
            return section;
          })
        );
      } catch (error) {
        alert("Error al subir el video: " + error.message);
      } finally {
        setUploadProgress(100);
      }
    }
  };

  return (
    <div
      className="p-[20px] overflow-x-auto"
      style={{ height: `calc(135vh - 500px)` }}
    >
      <button
        onClick={handleAddSection}
        className="flex justify-between stroke-black w-full hover:bg-slate-100 hover:scale-105 rounded-lg p-2 mb-2 transition-all"
      >
        <label>Añadir Módulo</label>
        <AddIcon h={"20"} w={"20"} />
      </button>

      <div className="max-h-[calc(100vh-300px)] overflow-y-auto">
        {sections.map((section) => (
          <div key={section.id}>
            <Section
              sectionId={section.id}
              title={section.title}
              videoUrl={section.videoUrl}
              subsections={section.subsections || []}
              setUrl={setUrl}
              setVideo={setVideo}
              onEditModule={() => handleModalOpen(section)}
              onVideoUpload={(event) => handleVideoUpload(event, section.id)}
              uploadProgress={uploadProgress}
            />
            <Modal
              isOpen={isModalOpen && currentModule?.id === section.id}
              closeModal={() => setIsModalOpen(false)}
              courseName={currentModule?.title}
              onEdit={handleEditModuleName}
              onDelete={handleRemoveModule}
              moduleImage={currentModule?.image}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
