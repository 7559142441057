export function Th({ title, responsive }) {
  return (
    <th
      scope="col"
      className={`px-3 py-3 ${responsive && `hidden ${responsive}:table-cell`}`}
    >
      {title}
    </th>
  );
}
