import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { downloadImage } from "hooks/commonData";
import { StatusDetail } from "components/training/StatusDetail";

export function TarjetCourses({ to, title, image, value, status, colorId }) {
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    downloadImage(image).then((r) => setAvatarUrl(r));
  }, [image]);

  // const value_course = value == null ? "0,0$" : value + "$";
  return (
    <Link
      to={to}
      className=" lg:bg-white lg:hover:scale-105 rounded-md px-[8px] py-[8px] transition-transform border-b border-red lg:border-none"
    >
      <img
        src={avatarUrl}
        alt=""
        className="w-full rounded-lg slide-down-animation"
      />
      <div className="text-start ml-1">
        <p className="text-lg text-black slide-down-animation">{title}</p>
        {/* <p className="text-lg text-black slide-down-animation">
            {value_course}
          </p> */}
      </div>
      <StatusDetail colorId={colorId} statusName={status} />
    </Link>
  );
}
