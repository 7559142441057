import { getUserData, supabase } from "utils/SupabaseClient";
import { toast } from "sonner";

//Crud Compañias
async function getCompanie(userId) {
  const { data, error } = await supabase
    .from("auth_users")
    .select("company_id")
    .eq("id", userId)
    .single();

  return data ? data : error;
}

//Curd AccesKey
export async function saveAccesKey({ accesKey, create }) {
  let data, error;
  const user = await getUserData();
  const company = await getCompanie(user.id);

  if (create) {
    ({ data, error } = await supabase.from("th_bi_siigo_api").insert({
      id_empresa: company.company_id,
      access_key: accesKey,
      created_by: user.id,
      updated_by: user.id,
    }));
  } else {
    ({ data, error } = await supabase
      .from("th_bi_siigo_api")
      .update({
        updated_at: new Date(),
        access_key: accesKey,
        updated_by: user.id,
      })
      .eq("id_empresa", company.company_id));
  }

  if (error) {
    toast.error("Error al guardar la clave de acceso.");
    return [];
  }
  toast.success("Se ha generado correctamente la conexión");
  return data;
}

export async function searchAccessKey() {
  const user = await getUserData();

  const { data, error } = await supabase
    .from("auth_users")
    .select(
      `company:auth_user_companies!company_id(
        info:th_bi_siigo_api!id_empresa(
          id, access_key, created_at, updated_at, 
          user:auth_users!created_by(
            first_name, last_name
          )
        )
      )`
    )
    .eq("id", user.id);

  if (error) {
    toast.error("No se pudieron obtener las conexiones del usuario.");
    return [];
  }
  return data[0].company.info;
}

export async function deleteAccessKey({ id }) {
  const { data, error } = await supabase
    .from("th_bi_siigo_api")
    .delete()
    .eq("id", id)
    .select();

  if (error) {
    toast.error("Ha ocurrido un error, por favor intenta de nuevo.");
    return [];
  }
  toast.success("Se ha eliminado la información correctamente.");
  return data;
}

//Crud Archivos
export async function fetchConnectFiles({ proveedor }) {
  const { data, error } = await supabase
    .from("dim_bi_origenes")
    .select("id, tipo, formato_fecha, columnas, columna_encabezados")
    .eq("proveedor", proveedor);

  if (error) {
    toast.error("Ha ocurrido un error, por favor intenta de nuevo.");
    return [];
  }
  return data;
}

//Crud Conexiones
export async function saveConnection({
  subCompanyId,
  proveedor,
  tipoArchivo,
  archivo,
}) {
  const user = await getUserData();

  const { data, error } = await supabase.from("th_bi_origenes").insert({
    subcompany_id: subCompanyId,
    proveedor: proveedor,
    type: tipoArchivo,
    directory: archivo,
    source: `${subCompanyId}/${proveedor}/${tipoArchivo}/${archivo}`,
    storage: true,
    created_by: user.id,
    updated_by: user.id,
  });
  if (error) {
    toast.error("No se pudo crear la nueva conexión, intenta nuevamente.");
  }
  return data;
}

export async function fetchConnections() {
  const { data, error } = await supabase
    .from("th_bi_origenes")
    .select(
      `
      id,
      proveedor,
      type,
      directory,
      created_at,
      auth_user_subcompanies(
        id,
        razon_social
      )
    `
    )
    .neq("type", "API");

  if (error) {
    toast.error("Ha ocurrido un error, por favor intenta de nuevo.");
    return [];
  }
  return data.map((item) => ({
    id: item.id,
    proveedor: item.proveedor,
    tipo_archivo: item.type,
    archivo: item.directory,
    created_at: item.created_at,
    auth_user_subcompanies: item.auth_user_subcompanies,
  }));
}

export async function deleteConnection({ id }) {
  const { data, error } = await supabase
    .from("th_bi_origenes")
    .delete()
    .eq("id", id)
    .select();

  if (error) {
    toast.error("Ha ocurrido un error, por favor intenta de nuevo.");
    return [];
  }
  return data;
}
