import { useEffect, useState } from "react";

import {
  getSiigoIntegrations,
  NewIntegration,
} from "api/bi-module/integration";
import { getUserDetails } from "hooks/commonData";

import { getUserData } from "utils/SupabaseClient";

import { Text } from "components/tailwind/Text";
import { Select } from "components/tailwind/Select";
import { ModalBG } from "components/common/ModalBG";
import { ButtonSave } from "components/common/ButtonSave";
import { ModalSuccess } from "components/modal/ModalSuccess";
import { ChargingComponent } from "components/common/ChargingComponent";

export function NewRowModal({ newRow, onClose }) {
  const [usuario, setUsuario] = useState("");
  const [contraseña, setContraseña] = useState("");
  const [idSubCompanie, setIdSubCompanies] = useState();
  const [isNewProyect, setIsNewProyect] = useState();
  const [projects, setProjects] = useState([]);
  const [subcompanies, setSubCompanies] = useState([]);
  const [subcompanyName, setCompanyName] = useState();
  const [proyectName, setProyectName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSucces, setIsSucces] = useState(false);
  const [isValidUser, setIsValidUser] = useState(false);

  const HadleSubmit = async () => {
    setIsLoading(true);
    const subirData = NewIntegration({
      subcompany_id: idSubCompanie,
      user_api: usuario,
      access_key: contraseña,
      proyect_id: isNewProyect,
      subcompany_name: subcompanyName,
      proyect_name: proyectName,
    });
    if (subirData) {
      setIsLoading(false);
      setIsSucces(true);
      newRow = false;
    } else {
      setIsLoading(false);
      setIsSucces(false);
    }
  };

  useEffect(() => {
    getSiigoIntegrations().then((project) => {
      setProjects(project);
    });
    getUserData().then((user) => {
      getUserDetails(user.id, "subcompanies").then((project) => {
        setSubCompanies(project.company.projects);
      });
    });
  }, []);

  useEffect(() => {
    const dataproyect = projects.filter((item) => isNewProyect === item.id);
    const datasubcompanies = subcompanies.filter(
      (item) => idSubCompanie === item.id
    );
    if (dataproyect !== null && dataproyect.length > 0) {
      setProyectName(dataproyect[0].project_name);
    }
    if (datasubcompanies !== null && datasubcompanies.length > 0) {
      setCompanyName(datasubcompanies[0].razon_social);
    }
  }, [isNewProyect, idSubCompanie]);

  useEffect(() => {
    const expresionRegular = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setIsValidUser(expresionRegular.test(usuario) ? true : false);
  }, [usuario]);

  return (
    <ModalBG
      open={newRow}
      onClose={onClose}
      title={isSucces ? "Creado Correctamente" : "Creación Conexión Api Siigo"}
    >
      {isLoading && <ChargingComponent />}
      {!isLoading && (
        <>
          {isSucces ? (
            <ModalSuccess
              title="Creado"
              message="Se ha creado el registro correctamente, recarga la pagina para poder visualizar tu nueva conexión"
            />
          ) : (
            <>
              <section>
                <p className="font-semibold">Información Empresa</p>
                <Select
                  title={"Seleccionar Sub Empresa"}
                  value={idSubCompanie}
                  options={subcompanies.map((item) => ({
                    value: item.id,
                    name: item.razon_social,
                  }))}
                  field={true}
                  onChange={setIdSubCompanies}
                  required={false}
                />
              </section>
              <section className="pt-4">
                <p className="font-semibold">Información del Api Siigo</p>
                <div className="grid grid-cols-2 gap-4">
                  <Text
                    title="Correo eléctronico"
                    field="user-email"
                    onChange={setUsuario}
                    value={usuario}
                  />
                  <Text
                    title="Contraseña"
                    field="integrations-email"
                    onChange={setContraseña}
                    value={contraseña}
                  />
                </div>
              </section>
              {isValidUser && (
                <>
                  <section className="pt-4">
                    <p className="font-semibold">Proyectos</p>
                    <Select
                      title={"Seleccionar Proyecto"}
                      value={isNewProyect}
                      options={projects.map((item) => ({
                        value: item.id,
                        name: item.project_name,
                      }))}
                      field={true}
                      onChange={setIsNewProyect}
                      required={false}
                    />
                  </section>
                  <ButtonSave HadleSubmit={HadleSubmit} />
                </>
              )}
            </>
          )}
        </>
      )}
    </ModalBG>
  );
}
