import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserData, supabase } from "utils/SupabaseClient";
import { getTicketDetails } from "hooks/commonData";
import {
  getComments,
  saveComment,
  updateComment,
  getListFileCommnet,
} from "api/tickets/route";
import { getFiles } from "api/requests/route";
import { toast } from "sonner";
import { ViewRequestUI } from "./ViewRequestUI";
import { ChargingComponent } from "components/common/ChargingComponent";

export function ViewRequest() {
  const { issue } = useParams();
  const [message, setMessage] = useState("");
  const [user, setUser] = useState("");
  const [comments, setComments] = useState([]);
  const [ticketBasic, setTicketBasic] = useState();
  const [ticketFields, setTicketFields] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [idCommentForUpdate, setIdCommentForUpdate] = useState(null);
  const [countR, setCountR] = useState();
  const [userWhoCreatedTicker, setUserWhoCreatedTicker] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [files, setFiles] = useState([]);
  const [tabComment, setTabComment] = useState(1);
  const [filesLoaded, setFilesLoaded] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [isLoadingComments, setIsLoadingComments] = useState(false);

  const handleGetData = async (limit, offset, issue) => {
    setIsLoadingComments(true);
    const data = await getComments(limit, offset, issue);
    if (data.error) {
      setIsLoadingComments(false);
      toast.error("Error al obtener los comentarios:");
      return;
    }
    setComments(data.th_ticket_comments);
    setCountR(data.count);
    setIsLoadingComments(false);
  };

  const handleUpdateComment = (comment, id) => {
    setMessage(comment);
    setIdCommentForUpdate(id);
    setIsUpdate(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("es-ES", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const handleSaveComment = async () => {
    const body = {
      ticket_id: ticketBasic.id,
      user_id: user,
      comment: message,
      created_by: user,
      modified_by: user,
    };
    try {
      setIsloading(true);
      try {
        const result = await saveComment(body, files);

        if (result?.error) {
          toast.error(result.error);
          return;
        }
      } catch (error) {
        console.error("Error inesperado:", error.message);
        return;
      }

      setMessage("");
      const commentsData = await getComments(10, 0, issue);
      setComments(commentsData.th_ticket_comments);
      setCountR(commentsData.count);

      setFiles([]);
      toast.success("Su comentario se agregó exitosamente");
    } catch (error) {
      toast.error("Hubo un error al agregar su comentario");
    } finally {
      setIsloading(false);
    }
  };

  const UpdateComment = async () => {
    const body = {
      comment: message,
      updated_at: new Date(),
      modified_by: user,
    };

    try {
      setIsloading(true);
      const dataUpdate = await updateComment(body, idCommentForUpdate, files);

      if (dataUpdate.error) {
        toast.error(dataUpdate.error);
        return;
      }

      const data = await getComments(10, 0, issue);
      if (data?.error) {
        toast.error("Error al obtener los comentarios: " + data.error);
        return;
      }

      setComments(data.th_ticket_comments);
      setCountR(data.count);
      setMessage("");
      setIsUpdate(false);
      toast.success("Su comentario se actualizó exitosamente");
    } catch (error) {
      toast.error("Ocurrió un error inesperado. Inténtalo nuevamente.");
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    getTicketDetails(issue, "basic").then((data) => {
      setTicketBasic(data);

      const emailUser = data.created_by?.email;

      if (emailUser) {
        supabase
          .from("auth_users")
          .select("first_name, last_name")
          .eq("email", emailUser)
          .then(({ data: userData, error }) => {
            if (error) {
              toast.error("No se pudo obtener el usuaior que creó el ticket");
            } else {
              setUserWhoCreatedTicker(userData[0]);
            }
          });
      }
      getFiles(data.id).then((data) => {
        setDocuments(data);
      });

      getListFileCommnet(data.id).then((data) => {
        setFilesLoaded(data);
      });
    });

    getTicketDetails(issue, "fields").then((data) => {
      setTicketFields(data.th_ticket_fields);
    });

    getUserData().then((data) => {
      setUser(data.id);
    });
    handleGetData(10, 0, issue);
  }, []);

  if (!ticketBasic || !ticketFields || isLoading) {
    return <ChargingComponent />;
  } else if (ticketBasic || ticketFields) {
    return (
      <ViewRequestUI
        ticketBasic={ticketBasic}
        documents={documents}
        comments={comments}
        setMessage={setMessage}
        saveComment={handleSaveComment}
        updateCommnet={UpdateComment}
        formatDate={formatDate}
        value={message}
        isUpdate={isUpdate}
        setIsUpdate={setIsUpdate}
        handleUpdateComment={handleUpdateComment}
        countR={countR}
        getComments={handleGetData}
        user={userWhoCreatedTicker}
        files={files}
        setFiles={setFiles}
        tabComment={tabComment}
        setTabComment={setTabComment}
        filesLoaded={filesLoaded}
        isLoadingComments={isLoadingComments}
      />
    );
  }
}
