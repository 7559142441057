import { useEffect, useMemo, useState } from "react";
import { fetchConnections } from "api/bi-module/datasource";
import { Table } from "./Table";
import { Text } from "components/tailwind/Text";
import { IntegrationsBanner } from "components/common/IntegrationsBanner";

export function ConnectSource({ submodule, setSubmodule }) {
  useEffect(() => {
    setSubmodule(submodule);
  }, [submodule]);

  const [active, setActive] = useState(true);
  const [isRecharge, setIsRecharge] = useState(null);
  const [connections, setConnections] = useState([]);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    fetchConnections().then((r) => {
      setConnections(r);
      setIsRecharge(false);
    });
  }, [isRecharge]);

  const filteredConnections = useMemo(() => {
    if (!search) return connections;

    const lowerCaseSearchTerm = search.toLowerCase();
    return connections.filter(
      (item) =>
        item.auth_user_subcompanies.razon_social
          .toLowerCase()
          .includes(lowerCaseSearchTerm) ||
        item.proveedor.toLowerCase().includes(lowerCaseSearchTerm) ||
        item.tipo_archivo.toLowerCase().includes(lowerCaseSearchTerm) ||
        item.archivo.toLowerCase().includes(lowerCaseSearchTerm)
    );
  }, [search, connections]);

  return (
    <div
      className={`overflow-y-auto h-[88vh] 2xl:max-h-[94vh] ${
        submodule === "Conexiones" ? "" : "hidden"
      }`}
    >
      <IntegrationsBanner
        setActive={setActive}
        isConnections
        items={connections.map((item) => ({
          subcompany_id: item.auth_user_subcompanies.id,
          file: item.archivo,
        }))}
        setRecharge={setIsRecharge}
      />
      <div className="bg-white p-4 rounded-md mt-4">
        <div className="pb-4">
          <h2 className="text-sky-800 text-lg 2xl:text-xl font-semibold">
            Mis Archivos
          </h2>
          <Text
            title="Buscador"
            field="input-search"
            value={search}
            onChange={setSearch}
          />
        </div>
        <Table
          active={active}
          items={filteredConnections}
          setRecharge={setIsRecharge}
        />
      </div>
    </div>
  );
}
