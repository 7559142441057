import { AutoHeigth } from "components/common/AutoHeigth";
import { GradiendBanner } from "components/common/GradiendBanner";
import { TarjetBudget } from "components/common/TarjetBudget";
import { AppSheetIcon } from "components/svg/AppSheetIcon";
import { LockerStudioIcon } from "components/svg/LockerStudioIcon";
import { MincrosoftFabricIcon } from "components/svg/MincrosoftFabricIcon";
import { PhentahoIcon } from "components/svg/PhentahoIcon";
import { PowerAppsIcon } from "components/svg/PowerAppsIcon";
import { PowerAutomateIcon } from "components/svg/PowerAutomateIcon";
import { PowerBiIcon } from "components/svg/PowerBiIcon";
import { PowerPlatformIcon } from "components/svg/PowerPlatformIcon";
import { PythonIcon } from "components/svg/PythonIcon";
import { TabeutIcon } from "components/svg/TabeutIcon";

export function MenuVisualBudget({ submodule, setSubmodule }) {
  return (
    <div
      className={`${
        submodule === "MenuVisualBudget" ? "slide-down-animation" : "hidden"
      }`}
    >
      <GradiendBanner>
        <h1 className="font-semibold text-lg text-white p-8 text-center mb-2">
          ¡Nos apasiona lo que hacemos y queremos compartir nuestra experiencia
          contigo!
        </h1>
      </GradiendBanner>
      <AutoHeigth maxPx="158px">
        <div className="mx-10 lg:mx-32 mt-5 mb-2 grid md:grid-cols-2 gap-10 ">
          <TarjetBudget
            onClick={() => setSubmodule("IhaveAQuestionVisual")}
            colorDefault="bg-sky-700"
            colorHovering="bg-blue-400"
            title="tengo una pregunta"
            description="Podrás reunirte de manera sincrónica con un experto y conversar
              del reto que tienes."
            pathImage="/assets/img/atencion_al_cliente.png"
          />
          <TarjetBudget
            onClick={() => setSubmodule("WantDevelope")}
            colorDefault="bg-green-700"
            colorHovering="bg-green-400"
            title="quiero un desarrollo"
            description="Arma tu equipo de desarrollo. 
            Sin importar el tamaño del proyecto o la tecnología que necesites, estamos listos para apoyarte."
            pathImage="/assets/img/desarrollo.png"
          />
        </div>
        <div className="md:flex gap-6 justify-center hidden">
          <PowerBiIcon className="w-14 h-14 hover:scale-110 transition-all" />
          <PowerAppsIcon className="w-14 h-14 hover:scale-110 transition-all" />
          <PowerPlatformIcon className="w-14 h-14 hover:scale-110 transition-all" />
          <MincrosoftFabricIcon className="w-14 h-14 hover:scale-110 transition-all" />
          <AppSheetIcon className="w-14 h-14 hover:scale-110 transition-all" />
          <LockerStudioIcon className="w-14 h-14 hover:scale-110 transition-all" />
          <TabeutIcon className="w-14 h-14 hover:scale-110 transition-all" />
          <PhentahoIcon className="w-14 h-14 hover:scale-110 transition-all" />
          <PowerAutomateIcon className="w-14 h-14 hover:scale-110 transition-all" />
          <PythonIcon className="w-14 h-14 hover:scale-110 transition-all" />
        </div>
      </AutoHeigth>
    </div>
  );
}
