export function EyeIcon({
  onClick,
  className,
  hidden,
  fillClass = "fill-black",
  strokeClass = "stroke-black",
}) {
  return (
    <svg
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={fillClass} // Aquí se aplica la clase personalizada para el fill
        d="M21.0256 12.9675C21.1231 13.29 21.4231 13.5 21.7456 13.5V13.4925C21.8624 13.4936 21.9778 13.4672 22.0825 13.4155C22.1873 13.3637 22.2783 13.2881 22.3484 13.1946C22.4185 13.1012 22.4656 12.9926 22.486 12.8775C22.5063 12.7625 22.4993 12.6443 22.4656 12.5325C22.4423 12.4575 19.9598 4.5 11.9963 4.5C4.03283 4.5 1.55258 12.4575 1.53008 12.54C1.41008 12.9375 1.63508 13.35 2.03258 13.47C2.43008 13.59 2.85008 13.365 2.97008 12.9675C3.05183 12.6825 5.16683 6 11.9971 6C18.8258 6 20.9333 12.6773 21.0256 12.9675ZM9.00008 13.5C9.00008 12.7044 9.31615 11.9413 9.87876 11.3787C10.4414 10.8161 11.2044 10.5 12.0001 10.5C12.7957 10.5 13.5588 10.8161 14.1214 11.3787C14.684 11.9413 15.0001 12.7044 15.0001 13.5C15.0001 14.2956 14.684 15.0587 14.1214 15.6213C13.5588 16.1839 12.7957 16.5 12.0001 16.5C11.2044 16.5 10.4414 16.1839 9.87876 15.6213C9.31615 15.0587 9.00008 14.2956 9.00008 13.5ZM12.0001 9C10.8066 9 9.66201 9.47411 8.8181 10.318C7.97419 11.1619 7.50008 12.3065 7.50008 13.5C7.50008 14.6935 7.97419 15.8381 8.8181 16.682C9.66201 17.5259 10.8066 18 12.0001 18C13.1936 18 14.3381 17.5259 15.1821 16.682C16.026 15.8381 16.5001 14.6935 16.5001 13.5C16.5001 12.3065 16.026 11.1619 15.1821 10.318C14.3381 9.47411 13.1936 9 12.0001 9Z"
      />
      <path
        className={`${strokeClass} stroke ${!hidden ? "" : "hidden"}`} // Clase personalizada para el stroke
        d="M1 4L23 18"
      />
      <path
        className={`stroke-white ${!hidden ? "" : "hidden"}`}
        d="M1 3L23.6155 17.7492"
        strokeLinecap="round"
      />
    </svg>
  );
}
