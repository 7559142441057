import React, { createContext, useContext, useState, useEffect } from "react";
import { supabase, getUserData } from "utils/SupabaseClient";
import { hasPermission } from "api/Roles/route";

const RoleContext = createContext();

export const useRoleContext = () => {
  const context = useContext(RoleContext);
  if (!context) {
    throw new Error("useRoleContext must be used within a RoleProvider");
  }
  return context;
};

export const RoleProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(null);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const fetchRoleAndPermissions = async () => {
      try {
        const userData = await getUserData();
        if (!userData) {
          console.error("No user data found.");
          return;
        }

        const { data: rolesData, error: rolesError } = await supabase
          .from("auth_user_roles")
          .select("role_id")
          .eq("user_id", userData.id);

        if (rolesError) {
          throw new Error("Error fetching roles");
        }

        const permissionIds = [];
        for (let role of rolesData) {
          const { data: rolePermissions, error: permissionsError } =
            await supabase
              .from("auth_role_permissions")
              .select("permission_id")
              .eq("role_id", role.role_id);

          if (permissionsError) {
            throw new Error("Error fetching permissions for role");
          }

          permissionIds.push(
            ...rolePermissions.map((perm) => perm.permission_id)
          );
        }

        const permissionsList = [];
        for (let permissionId of permissionIds) {
          const hasPerm = await hasPermission(userData.id, permissionId);
          if (hasPerm) {
            permissionsList.push(permissionId);
          }
        }

        setPermissions(permissionsList);
        setUserRole(rolesData[0]?.role_id);
      } catch (error) {
        console.error("Error fetching role and permissions:", error);
      }
    };

    fetchRoleAndPermissions();
  }, []);

  return (
    <RoleContext.Provider value={{ userRole, permissions }}>
      {children}
    </RoleContext.Provider>
  );
};
