import { ChargingIcon } from "components/svg/ChargingIcon";

export function ChargingComponent({}) {
  return (
    <div className={`grow my-[100px] animate-pulse`}>
      <div className="flex justify-center">
        <div className="text-center">
          <div className="ml-[15px] mb-2">
            <ChargingIcon h={"100"} w={"100"} />
          </div>
          <div className="text-[#0e6adb]">
            <h1 className="font-semibold">PLISBI </h1>
            <h1>cargando...</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
