import { CheckCircleIcon } from "@heroicons/react/20/solid";

export function ModalSuccess({ title, message, onClose }) {
  return (
    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-sky-100 sm:mx-0 sm:size-10">
          <CheckCircleIcon aria-hidden="true" className="size-6 text-sky-900" />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3 className="text-base font-semibold text-gray-900">{title}</h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">{message}</p>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <button
          className="bg-sky-700 text-white py-2 px-4 rounded-md hover:bg-sky-600"
          onClick={onClose}
        >
          Aceptar
        </button>
      </div>
    </div>
  );
}
