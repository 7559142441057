export function PowerBiIcon({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 384 503"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M208.759 22.3481C208.759 22.3481 206.445 3.11107 220.667 0.957228C234.889 -1.19654 371.155 0.957228 371.155 0.957228C371.155 0.957228 383.063 5.81242 383.132 19.49C383.2 33.1677 382.736 487.169 382.736 487.169C382.736 487.169 380.474 500.7 370.418 500.957C360.361 501.215 210.766 500.957 210.766 500.957L208.759 22.3481Z"
        fill="url(#paint0_linear_777_4372)"
      />
      <path
        d="M101.304 159.676C101.304 159.676 97.0171 124.241 126.278 124.241C155.538 124.241 256.862 124.241 256.862 124.241C256.862 124.241 276.975 121.775 276.782 161.085C276.59 200.395 277.208 501.072 277.208 501.072H116.359C116.359 501.072 101.058 498.269 101.202 473.682C101.347 449.095 101.304 159.676 101.304 159.676Z"
        fill="url(#paint1_linear_777_4372)"
      />
      <path
        d="M0.220163 276.318C0.220163 276.318 -4.06684 252.809 25.1937 252.809C54.4545 252.809 155.778 252.809 155.778 252.809C155.778 252.809 175.891 251.173 175.698 277.253C175.506 303.332 176.124 502.809 176.124 502.809H15.2755C15.2755 502.809 -0.0258423 500.95 0.11846 484.638C0.262838 468.326 0.220163 276.318 0.220163 276.318Z"
        fill="url(#paint2_linear_777_4372)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_777_4372"
          x1="288.644"
          y1="500.536"
          x2="299.303"
          y2="0.535854"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B15500" />
          <stop offset="1" stop-color="#E8B913" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_777_4372"
          x1="196.784"
          y1="501.072"
          x2="177.509"
          y2="124.793"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DE9802" />
          <stop offset="1" stop-color="#F8E27D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_777_4372"
          x1="95.6999"
          y1="502.809"
          x2="87.2037"
          y2="252.809"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F6D033" />
          <stop offset="1" stop-color="#F6E7A4" />
        </linearGradient>
      </defs>
    </svg>
  );
}
