import { getUserData, supabase } from "utils/SupabaseClient";
import { createCustumerTicket } from "api/tickets/route";
import { toast } from "sonner";

export async function getIntegrations() {
  const { data, error } = await supabase
    .from("th_bi_origenes")
    .select(
      `
      id,
      directory,
      source,
      proveedor,
      auth_user_subcompanies(id, numero_documento, razon_social)
    `
    )
    .eq("type", "API");
  if (error) {
    return [];
  }

  return data.map((item) => ({
    id: item.id,
    user_api: item.directory,
    access_key: item.source,
    provider: item.proveedor,
    subcompany_id: item.auth_user_subcompanies,
    report_id: "TODO: Change",
  }));
}

export async function DeleteApiConection({ id }) {
  const { error } = await supabase.from("th_bi_origenes").delete().eq("id", id);

  if (error) {
    toast.error("Error al eliminar el registro");
    return false;
  } else {
    return true;
  }
}

export async function UpdatePassword({ password, id }) {
  const { error } = await supabase
    .from("th_bi_origenes")
    .update({ source: password })
    .eq("id", id);

  if (error) {
    toast.error("Error al cambiar la contraseña");
    return false;
  }
  return true;
}

export async function OtherNewApi({
  subcompany_id,
  title,
  description,
  companyName,
}) {
  let ticketId;
  let createdBy;

  const dataTicket = await createCustumerTicket({
    ticketTypeId: 2,
    serviceId: 42,
    projectId: "b52b5e62-bed1-4b17-964e-8c9187076cf2", //TODO: Revisar para que este UUID no sea quemado,
    summary: title + " - (" + companyName + ")",
    description,
    subcompanyId: subcompany_id,
  });

  ticketId = dataTicket[0];
  createdBy = dataTicket[1];

  const { error } = await supabase.from("th_ticket_fields").insert([
    {
      ticket_id: ticketId,
      field_definition_id: 12,
      field_value: "Reporting",
      created_by: createdBy,
      modified_by: createdBy,
    },
    {
      ticket_id: ticketId,
      field_definition_id: 13,
      field_value: "Usuario PlisBI",
      created_by: createdBy,
      modified_by: createdBy,
    },
    {
      ticket_id: ticketId,
      field_definition_id: 14,
      field_value: "Nueva conexión",
      created_by: createdBy,
      modified_by: createdBy,
    },
    {
      ticket_id: ticketId,
      field_definition_id: 15,
      field_value: "Reportes",
      created_by: createdBy,
      modified_by: createdBy,
    },
    {
      ticket_id: ticketId,
      field_definition_id: 16,
      field_value: "N/A",
      created_by: createdBy,
      modified_by: createdBy,
    },
  ]);

  if (error) {
    toast.error("Error intentelo nuevamente");
    return "";
  }

  return ticketId;
}

export async function NewIntegration({
  subcompany_id,
  user_api,
  access_key,
  proyect_id,
  proyect_name,
  subcompany_name,
}) {
  const user = await getUserData();

  const { data: dat } = await supabase
    .from("th_bi_origenes")
    .select("subcompany_id")
    .eq("subcompany_id", subcompany_id);

  if (dat !== null && dat.length > 0) {
    toast.error("Ya existe la conexion con esta compañia");
  } else {
    const { error } = await supabase.from("th_bi_origenes").insert({
      subcompany_id: subcompany_id,
      proveedor: "siigo",
      type: "API",
      directory: user_api,
      source: access_key,
      created_by: user.id,
      updated_by: user.id,
    });

    if (!error) {
      await crearTicket(
        proyect_id,
        subcompany_name,
        user.id,
        proyect_name,
        subcompany_id
      );
    }
  }
}

async function crearTicket(
  project_id,
  subcompany_name,
  user_id,
  proyect,
  subcompany_id
) {
  const message = `Usuario solicito conexión para el proyecto: ${subcompany_name} la compañia es: ${subcompany_id}, el tipo de conexión es: ${proyect}`;

  const { data, error } = await supabase.from("th_tickets").insert({
    project_id,
    title: "Solicitud conexion API Siigo " + proyect,
    description: message,
    type_id: 2,
    status_id: 1,
    created_by: user_id,
    modified_by: user_id,
    service_id: 42,
    subcompany_id,
  });
  if (error) return [];

  return data;
}

export async function getSiigoIntegrations() {
  const { data, error } = await supabase
    .from("th_user_projects")
    .select("project_name, id")
    .eq("company_id", "e4866171-4292-4b2b-a270-c949e141a082"); //TODO: Viabilidad de conexion quemada

  if (error) {
    toast.error("Error obteniendo los proyectos", error);
    return [];
  }

  return data;
}
