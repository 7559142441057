import { PaperClipIcon } from "@heroicons/react/20/solid";
import { handleDownload } from "api/tickets/route";

function Document({
  index,
  name,
  close,
  removeFile,
  document,
  sectionComments,
  formatDate,
}) {
  const formatFileSize = (bytes) => {
    if (bytes < 1024) {
      return bytes + " bytes";
    } else if (bytes < 1048576) {
      // 1024 * 1024
      return (bytes / 1024).toFixed(2) + " KB"; // Redondeamos a 2 decimales
    } else if (bytes < 1073741824) {
      // 1024 * 1024 * 1024
      return (bytes / 1048576).toFixed(2) + " MB"; // Redondeamos a 2 decimales
    } else {
      return (bytes / 1073741824).toFixed(2) + " GB"; // Redondeamos a 2 decimales
    }
  };

  return (
    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
      <div className="flex w-0 flex-1 items-center">
        <PaperClipIcon
          aria-hidden="true"
          className="h-5 w-5 flex-shrink-0 text-gray-400"
        />
        <div className="ml-4 flex min-w-0 flex-1 gap-2">
          <span className="truncate font-medium">{name}</span>
          {document.size && (
            <span className="flex-shrink-0 text-gray-400">
              {formatFileSize(document.size)}
            </span>
          )}

          {sectionComments && (
            <span className="flex-shrink-0 text-gray-400">
              {formatDate(document.created_at)}
            </span>
          )}
        </div>
      </div>
      <div className="ml-4 flex-shrink-0">
        {close ? (
          <button
            className="font-medium text-indigo-600 hover:text-indigo-500"
            onClick={() => removeFile(index)}
          >
            Eliminar
          </button>
        ) : (
          <button
            className="font-medium text-indigo-600 hover:text-indigo-500"
            onClick={() => handleDownload(document.file_name)}
          >
            Descargar
          </button>
        )}
      </div>
    </li>
  );
}

export function ViewIssueDocuments({
  documents,
  close,
  removeFile,
  sectionComments,
  formatDate,
}) {
  return (
    <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      {sectionComments !== true && (
        <dt className="text-sm font-medium leading-6 text-gray-900">
          {close ? "Archivos seleccionados" : "Adjuntos"}
        </dt>
      )}

      <dd
        className={`mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0 ${
          sectionComments ? "sm:col-span-3" : "sm:col-span-2"
        }`}
      >
        <ul
          role="list"
          className={`divide-y divide-gray-200 rounded-md ${
            !sectionComments && "border border-gray-200"
          }`}
        >
          {documents?.map((document, index) => {
            let name = null;
            if (document?.file_name) {
              let splitName = document?.file_name.split("/");
              name = splitName[splitName.length - 1];
            } else {
              name = document.name;
            }
            return (
              <Document
                key={index}
                index={index}
                name={name}
                close={close}
                removeFile={removeFile}
                document={document}
                sectionComments={sectionComments}
                formatDate={formatDate}
              />
            );
          })}
        </ul>
      </dd>
    </div>
  );
}
