import { useEffect, useRef, useState } from "react";

import { DropDownIcon } from "components/svg/DropDownIcon";

export function SearchSelect({ items, setOption, option, title, className }) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!option) {
      setSearchTerm("");
    }
  }, [option]);

  // Detectar clics fuera del dropdown para cerrarlo
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const [optionSelected, setSelectedOption] = useState("Selecciona una opción");

  const filteredItems = items.filter((item) =>
    item.label?.toLowerCase()?.includes(searchTerm)
  );

  return (
    <div className={`w-full ${className}`}>
      <div className="text-black transition-all mb-2 text-sm">{title}</div>
      <div
        className={`transition-all relative group mt-3 border rounded-md ${
          isOpen ? "border-[#F1C40F]" : "border-slate-300"
        }`}
      >
        <button
          ref={inputRef}
          id="dropdown-button"
          className="flex justify-between items-center w-full p-1.5 focus:border-none focus:transition-all"
          onClick={toggleDropdown}
        >
          <span className="mx-2 truncate text-sm">{optionSelected}</span>
          <DropDownIcon
            className="w-5 h-5 mr-1 p-1 rounded-full transition-colors"
            isopen={isOpen}
          />
        </button>

        <div
          ref={dropdownRef}
          id="dropdown-menu"
          className={`absolute z-10 w-full ${
            isOpen ? "appear-animation" : "hidden"
          } bg-white p-1 mt-1 rounded-md border border-slate-300`}
        >
          <input
            id="search-input"
            className="mb-1 border text-sm border-slate-300 w-full p-1 rounded-md focus:outline-[#F1C40F] focus:border-none focus:transition-all"
            type="text"
            placeholder="Escribe..."
            autoComplete="off"
            value={searchTerm}
            onChange={handleSearch}
          />
          <div className="overflow-y-auto max-h-40">
            {filteredItems.map((item) => (
              <div
                key={item.value}
                className={`${
                  option === item.label ? "bg-blue-100" : "bg-white"
                } block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md transition-all`}
                onClick={() => {
                  setSelectedOption(item.label);
                  setOption(item.value);
                  setIsOpen(false); // Cerrar el menú después de seleccionar una opción
                }}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
