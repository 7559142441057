import { ButtonSave } from "components/common/ButtonSave";
import { ChargingComponent } from "components/common/ChargingComponent";
import { InputText } from "components/common/InputText";
import { SearchSelect } from "components/tailwind/SearchSelect";

export function StructureModal({
  isLoading,
  setName,
  name,
  setLastName,
  lastName,
  setPhone,
  phone,
  setEmail,
  email,
  rolesData,
  setRoleId,
  roleId,
  subCompanyData,
  setSubCompany,
  subCompany,
  typeDocumentData,
  setTypeDocument,
  typeDocument,
  setDocumento,
  documento,
  setPassword,
  password,
  form,
}) {
  return (
    <>
      <div className={isLoading ? "appear-animation" : "hidden"}>
        <ChargingComponent />
      </div>
      <div
        className={`overflow-y-scroll pr-2 h-full ${
          !isLoading ? "appear-animation" : "hidden"
        }`}
        // style={{ height: `calc(100vh - "87px")` }}
      >
        <div className="grid md:grid-cols-2 md:gap-2">
          <InputText
            setValue={setName}
            value={name}
            placeholder={"Nombres"}
            required
          />
          <InputText
            setValue={setLastName}
            placeholder={"Apellidos"}
            value={lastName}
            required
          />
          <InputText
            setValue={setPhone}
            value={phone}
            placeholder={"Teléfono"}
            required
          />
          <InputText
            setValue={setEmail}
            value={email}
            placeholder={"E-mail"}
            required
          />
        </div>
        <div className="mt-2 grid grid-cols-2 gap-4">
          <SearchSelect
            items={rolesData}
            setOption={setRoleId}
            option={roleId}
            title={"Rol"}
          />
          <SearchSelect
            items={subCompanyData}
            setOption={setSubCompany}
            option={subCompany}
            title={"Sub Compañia"}
          />
        </div>

        <div className="my-2 grid md:grid-cols-3 md:gap-2">
          <SearchSelect
            items={typeDocumentData}
            setOption={setTypeDocument}
            option={typeDocument}
            title={"Tipo documento"}
          />
          <div className="md:col-span-2">
            <InputText
              setValue={setDocumento}
              value={documento}
              placeholder="N° Documento"
              required
            />
          </div>
        </div>
        <InputText
          setValue={setPassword}
          value={password}
          placeholder="Contraseña"
          required
        />
        <ButtonSave HadleSubmit={form} />
      </div>
    </>
  );
}
