import { BackGroundSections } from "components/common/BackGroundSections";
import { Subtitle } from "components/common/Subtitle";
import { Table } from "../../../../components/table/Table";
import { useEffect, useState, useCallback } from "react";
import { getUsersCompany } from "hooks/commonData";
import { TD } from "../../../../components/table/TD";
import { Row } from "../../../../components/table/Row";
import { Button } from "components/common/Button";
import { ModalAddUser } from "./ModalAddUser";
import { FiEdit } from "react-icons/fi";
import { toast } from "sonner";
import { ModalEditUser } from "./ModalEditUser";

export function CompanyVisual({ submodule, companyId, company, setCompany }) {
  const [data, setData] = useState([]);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState(false);
  const [recharge, setRecharge] = useState(false);

  const nameRows = [
    { label: "Usuario", responsive: null },
    { label: "Rol", responsive: "md" },
    { label: "Premisos", responsive: "md" },
    { label: "Estado", responsive: "md" },
    { label: "Editar", responsive: null },
  ];

  useEffect(() => {
    getUsersCompany(companyId).then((r) => {
      setRecharge(false);
      setData(r);
    });
  }, [recharge, companyId]);

  useEffect(() => {
    data.length > 0 ? setCompany(data[0].company_id) : setCompany(null);
  }, [data]);

  const handleEdit = useCallback((item) => {
    setIsOpenEdit(true);
    setDataEdit(item);
  }, []);

  return (
    <div className={submodule === "Usuarios" ? "" : "hidden"}>
      <ModalAddUser
        isOpen={isOpenAdd}
        setIsOpen={setIsOpenAdd}
        companyId={companyId}
        setRecharge={setRecharge}
      />
      {/* <ModalEditUser
        dataEdit={dataEdit}
        isOpenEdit={isOpenEdit}
        onClose={() => setIsOpenEdit(false)}
      /> */}
      <BackGroundSections>
        <div className="flex justify-between mb-1">
          <Subtitle text={`Equipo Asociado`} />

          {data.length >= company?.id_plan.user_count ? (
            <button
              className=" rounded-md p-1 border-2 border-red-500 bg-red-200 text-red-500 font-bold hover:bg-red-500  hover:text-white transition-all"
              onClick={() =>
                toast.error("para poder añadir mas usuarios mejora tu plan.")
              }
            >
              ¡No puedes añadir mas usuarios!
            </button>
          ) : (
            <div>
              <Button
                className="appear-animation bg-sky-800 hover:bg-yellow-500 grow p-2 text-white rounded-lg transition-colors"
                text="añadir +"
                onClick={() => setIsOpenAdd(true)}
              />
            </div>
          )}
        </div>

        <Table nameRows={nameRows}>
          {data.map((item) => (
            <Row key={item.id}>
              <TD item={`${item.first_name} ${item.last_name}`} />
              <TD
                item={item.auth_user_roles[0].role_id.role_name}
                responsive={"md"}
              />
              <TD item={"Prueba"} responsive={"md"} />
              <TD
                item={item.activo ? "activo" : "inactivo"}
                responsive={"md"}
              />
              <FiEdit
                onClick={() => handleEdit(item)}
                className="w-7 h-7 ml-5 mt-2 hover:bg-slate-100 hover:scale-105 rounded-lg transition-all p-[2px] cursor-pointer"
              />
            </Row>
          ))}
        </Table>
      </BackGroundSections>
    </div>
  );
}
