import { useEffect, useState } from "react";

import { fetchTemplates } from "api/bi-module/reports";

import { ReportBanner } from "./ReportBanner";
import { ReportInformation } from "./ReportInformation";
import { ItemTemplate, TemplateBanner } from "./TemplateBanner";

export function Reports({ submodule, setSubmodule }) {
  const [report, setReport] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);

  useEffect(() => {
    setSubmodule(submodule);
  }, [submodule]);
  useEffect(() => {
    fetchTemplates().then((r) => {
      setTemplates(r);
    });
  }, []);

  return (
    <div className={`${submodule !== "Reportes" ? "hidden" : ""}`}>
      {(isActive || isTemplate) && (
        <ReportInformation
          report={report}
          isTemplate={isTemplate}
          setActive={isActive ? setIsActive : setIsTemplate}
          setReport={setReport}
        />
      )}
      {!(isActive || isTemplate) && (
        <>
          <TemplateBanner>
            {templates.map((template) => (
              <ItemTemplate
                key={template.id}
                report={template}
                setReport={setReport}
                title={template.title}
                setActive={setIsTemplate}
                custom
              />
            ))}
            <ItemTemplate to="Cotiza un desarrollo a la medida" />
          </TemplateBanner>
          <hr className="border border-zinc-300" />
          <h2 className="text-sky-700 text-center uppercase font-bold">
            Tus reportes
          </h2>
          <div className="grid grid-cols-3 gap-4 my-4">
            {templates.map((report) => (
              <ReportBanner
                key={report.id}
                report={report}
                setActive={setIsActive}
                setReport={setReport}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
