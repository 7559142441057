export function TabeutIcon({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 396 391"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.2519 233.811C32.1519 233.488 31.9663 225.829 31.8397 216.79C31.6554 203.635 31.4732 200.268 30.9267 199.922C30.5512 199.684 27.7377 199.486 24.6745 199.482C21.6112 199.478 15.0862 199.24 10.1745 198.953C-0.0720415 198.355 -0.00982526 198.385 0.000874743 194.004C0.0108747 190.495 0.374255 189.132 1.41836 188.708C1.87246 188.524 8.31904 188.202 15.744 187.993C23.169 187.784 29.6202 187.452 30.0802 187.255C30.5402 187.058 31.0574 186.531 31.2292 186.083C31.7583 184.704 32.4672 170.696 32.4809 161.349L32.494 152.474L34.869 152.374C38.7869 152.209 41.2063 152.48 41.891 153.161C42.7491 154.014 43.474 161.228 43.485 169.021C43.485 172.484 43.641 178.053 43.8207 181.396L44.1476 187.474L52.9457 187.515C64.6217 187.569 73.8464 188.127 74.5402 188.821C75.1612 189.442 75.2233 197.958 74.6112 198.539C74.4092 198.73 67.6844 199.049 59.6671 199.247C51.6497 199.444 44.8193 199.773 44.4884 199.978C44.0565 200.245 43.7454 204.985 43.3856 216.787C43.11 225.828 42.7405 233.365 42.5644 233.537C42.3883 233.709 40.0365 233.973 37.3385 234.124C33.8135 234.321 32.3823 234.233 32.2519 233.811Z"
        fill="#648EA0"
      />
      <path
        d="M90.495 159.291L86.745 158.725L86.4232 137.041C86.0985 115.153 85.7891 108.759 84.9925 107.47C84.6343 106.891 80.8325 106.7 63.7125 106.403C41.8737 106.024 39.4343 105.856 38.4479 104.668C37.6465 103.702 37.5283 93.7523 38.3089 92.9653C39.5126 91.7516 43.3696 91.498 60.7965 91.4864C80.9784 91.4731 84.7719 91.2475 85.2723 90.0311C85.4549 89.5875 85.7958 78.8745 86.0299 66.2245C86.264 53.5745 86.6826 42.4623 86.9601 41.5306C88.0599 37.8383 88.8312 37.6189 98.3375 38.2937L102.834 38.6128L103.215 61.4187C103.425 73.9619 103.743 85.5593 103.921 87.1907C104.223 89.9577 104.346 90.1838 105.745 90.557C106.57 90.7771 116.869 91.1832 128.633 91.4595C140.396 91.7358 150.561 92.0974 151.221 92.2633C152.699 92.634 152.92 93.1838 153.285 97.3915C153.687 102.028 153.064 104.55 151.308 105.396C150.239 105.912 144.686 106.145 127.479 106.396C110.097 106.651 104.898 106.871 104.395 107.374C103.887 107.882 103.688 113.4 103.482 132.665C103.171 161.918 103.564 160.008 97.8585 159.916C95.8711 159.884 92.5575 159.603 90.495 159.291Z"
        fill="#EC9439"
      />
      <path
        d="M194.819 82.4501C193.486 82.2264 192.321 81.9685 192.229 81.877C192.137 81.7855 191.815 74.2323 191.512 65.0922C191.209 55.9521 190.842 48.1618 190.695 47.7803C190.261 46.6474 188.039 46.4745 173.918 46.4745H160.468L159.937 45.0995C159.094 42.9184 159.642 38.6035 160.925 37.3198C161.988 36.257 162.442 36.2171 176.215 35.9745C184.023 35.837 190.592 35.5428 190.814 35.3207C191.036 35.0986 191.398 28.5736 191.62 20.8207C191.841 13.0678 192.184 5.38451 192.383 3.74676C192.795 0.343026 193.145 0.111774 198.023 0.0243136C202.595 -0.0576865 202.388 -0.670402 202.995 14.7245C203.287 22.1495 203.648 29.821 203.796 31.7724L204.064 35.3202L212.359 35.6474C216.92 35.8273 222.586 35.9799 224.948 35.9864C233.459 36.01 235.126 37.0906 234.889 42.4308L234.743 45.7245L224.493 46.0975C211.943 46.5542 205.099 47.1687 204.363 47.9046C203.998 48.2698 203.676 53.5535 203.426 63.2821C203.216 71.4337 202.895 78.8055 202.713 79.6639C202.022 82.9213 200.606 83.4211 194.819 82.4501Z"
        fill="#8DA1B5"
      />
      <path
        d="M295.304 159.914C293.412 159.711 291.639 159.32 291.365 159.045C290.577 158.258 290.056 145.289 290.024 125.68L289.994 107.636L268.119 107.288C256.087 107.096 245.36 106.778 244.279 106.579C241.868 106.137 241.616 105.431 241.163 97.8658C240.844 92.5369 240.85 92.5046 242.043 92.0601C242.704 91.8143 253.573 91.3593 266.197 91.0492C278.821 90.739 289.258 90.3772 289.39 90.2451C289.522 90.113 289.825 80.3793 290.062 68.6147C290.3 56.8501 290.63 45.1168 290.795 42.5408L291.096 37.8572L299.92 38.219C304.773 38.418 308.774 38.6132 308.81 38.6527C308.847 38.6922 309.105 50.2702 309.384 64.3815C309.663 78.4929 309.971 90.1059 310.067 90.1882C310.164 90.2705 319.919 90.5906 331.744 90.8996C343.569 91.2085 353.869 91.6043 354.634 91.7791C356.72 92.256 357.2 93.6564 357.223 99.3353C357.253 106.793 357.759 106.483 344.672 107.005C338.937 107.233 328.695 107.432 321.913 107.447L309.583 107.474L309.411 131.849C309.223 158.704 309.209 158.821 306.244 159.52C303.669 160.127 298.888 160.299 295.304 159.914Z"
        fill="#648EA0"
      />
      <path
        d="M348.092 237.618C347.431 237.492 346.713 236.828 346.407 236.061C346.096 235.281 345.743 228.137 345.559 218.891C345.386 210.183 345.082 202.896 344.884 202.698C344.687 202.5 338.274 202.26 330.634 202.165C322.995 202.069 316.149 201.862 315.421 201.704C312.619 201.098 312.195 199.693 312.394 191.676L312.494 187.628L325.619 187.277C339.668 186.901 344.174 186.497 345.138 185.526C345.593 185.067 345.701 181.223 345.57 170.046C345.423 157.422 345.514 154.894 346.177 153.307C347.31 150.596 349.543 149.785 355.024 150.096C359.97 150.376 361.254 150.904 361.675 152.834C361.842 153.599 362.211 161.41 362.495 170.192C362.779 178.974 363.064 186.202 363.128 186.254C363.192 186.306 369.769 186.59 377.744 186.886C385.719 187.182 392.753 187.574 393.374 187.759C394.757 188.169 395.253 189.771 395.218 193.724C395.144 202.11 395.339 202.016 377.154 202.429C369.68 202.599 363.411 202.891 363.224 203.078C363.036 203.265 362.67 210.912 362.409 220.071C362.149 229.231 361.843 237.006 361.731 237.349C361.58 237.809 359.904 237.956 355.385 237.906C352.007 237.869 348.725 237.739 348.092 237.618Z"
        fill="#526592"
      />
      <path
        d="M292.185 347.983C291.602 347.748 290.981 346.919 290.805 346.14C290.629 345.362 290.275 334.069 290.018 321.045C289.761 308.021 289.407 297.221 289.231 297.045C289.055 296.869 281.448 296.576 272.327 296.394C252.595 296.001 244.487 295.594 242.494 294.9C240.776 294.301 240.281 292.642 240.281 287.475C240.281 282.448 240.816 280.526 242.396 279.87C243.295 279.497 251.42 279.236 266.791 279.088L289.839 278.865L290.166 269.089C290.346 263.713 290.494 251.96 290.494 242.971V226.629L296.869 226.34C304.363 226.001 308.366 226.44 309.288 227.7C310.429 229.261 310.652 233.876 310.339 249.475C310.171 257.862 310.172 267.931 310.339 271.85L310.646 278.975L329.194 279.01C348.737 279.048 356.14 279.421 357.138 280.421C357.977 281.263 357.915 289.119 357.046 292.12C356.631 293.55 355.926 294.743 355.297 295.081C353.937 295.812 340.236 296.402 323.671 296.442L310.599 296.475L310.421 321.207C310.268 342.365 310.134 346.046 309.493 346.68C307.998 348.157 295.032 349.134 292.185 347.983Z"
        fill="#133D79"
      />
      <path
        d="M192.495 262.997C188.763 262.443 188.149 262.126 187.785 260.566C187.613 259.828 187.332 247.152 187.161 232.397L186.849 205.57L185.43 205.287C184.65 205.131 175.739 204.764 165.628 204.472C140.952 203.76 133.949 203.272 133.172 202.21C132.583 201.404 132.315 199.023 131.735 189.424L131.445 184.623L144.345 184.302C151.44 184.126 163.938 183.834 172.12 183.654L186.995 183.326V165.231C186.995 141.074 187.472 126.196 188.284 125.086C188.833 124.335 189.908 124.224 196.69 124.224C207.126 124.224 206.524 123.594 207.043 135.066C207.258 139.828 207.448 152.551 207.465 163.34L207.495 182.955L208.62 183.192C209.238 183.323 221.669 183.683 236.244 183.993C250.819 184.304 262.788 184.601 262.842 184.655C262.895 184.708 263.008 188.377 263.092 192.807C263.264 201.877 263.074 202.449 259.544 203.51C258.453 203.838 248.393 204.205 233.995 204.442C220.932 204.657 209.868 204.995 209.408 205.192C208.948 205.39 208.401 205.928 208.192 206.388C207.983 206.848 207.655 218.699 207.462 232.724C207.27 246.749 206.945 259.084 206.741 260.136C206.479 261.484 206.026 262.177 205.2 262.491C203.461 263.153 195.64 263.464 192.495 262.997Z"
        fill="#E77327"
      />
      <path
        d="M193.864 390.026C189.635 389.407 189.788 390.018 189.416 372.332C189.084 356.59 188.811 353.229 187.813 352.613C187.513 352.427 180.4 352.151 172.006 352L156.744 351.725L156.604 345.676L156.465 339.627L170.604 339.283C186.489 338.896 187.808 338.757 188.542 337.385C188.84 336.83 189.257 329.82 189.515 321.068C189.763 312.629 190.105 304.88 190.274 303.848C190.656 301.526 191.513 301.225 197.744 301.225C203.422 301.225 204.884 301.625 205.555 303.364C205.85 304.13 206.234 311.607 206.434 320.475C206.668 330.898 206.985 336.581 207.371 337.279C208.181 338.744 210.486 338.975 224.286 338.975C235.893 338.975 235.944 338.98 237.342 340.185C238.657 341.32 238.74 341.623 238.672 345.06C238.575 349.928 237.987 351.338 235.892 351.725C234.986 351.892 228.349 352.185 221.144 352.377C211.451 352.634 207.875 352.894 207.394 353.374C206.903 353.865 206.67 357.779 206.441 369.374C206.115 385.87 205.852 387.587 203.411 389.138C201.987 390.044 197.092 390.499 193.864 390.026Z"
        fill="#526592"
      />
      <path
        d="M85.2735 347.926C85.1371 347.54 84.9622 335.929 84.8849 322.124C84.7742 302.357 84.6065 296.886 84.0951 296.374C83.5935 295.872 78.5681 295.649 62.0055 295.394C45.6065 295.141 40.2337 294.904 39.1549 294.389C37.9774 293.826 37.6685 293.266 37.2836 291C36.7914 288.103 37.4933 281.276 38.3741 280.395C39.1877 279.581 47.897 279.058 66.5687 278.701L84.3927 278.36L84.6854 273.042C84.8465 270.118 85.1209 258.476 85.2953 247.173L85.6124 226.622L91.4283 226.34C97.5751 226.043 102.158 226.438 103.063 227.343C104.006 228.286 104.443 238.048 104.469 258.769L104.495 278.813L121.37 279.143C130.651 279.324 141.57 279.473 145.633 279.474L153.021 279.474L152.883 287.099L152.745 294.724L131.245 295.298C119.42 295.613 108.596 295.895 107.191 295.923L104.637 295.974L104.371 320.849C104.224 334.531 103.949 346.101 103.761 346.561C103.226 347.863 102.299 348.026 93.6337 348.336C86.9351 348.576 85.4783 348.505 85.2735 347.926Z"
        fill="#C8182F"
      />
    </svg>
  );
}
