export function AppSheetIcon({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 420 382"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M396.69 11.2983C423.94 18.5649 414.44 41.7817 414.44 41.7817L231.69 363.782C216.44 399.782 188.94 370.282 188.94 370.282L140.19 287.782C140.19 287.782 130.69 276.532 139.94 256.282L278.69 10.0317L396.69 11.2983Z"
        fill="#2685FD"
      />
      <path
        d="M1.22755 30.3713C-5.73158 3.04114 19.1688 0 19.1688 0L389.407 2.32633C428.271 -1.93603 415.947 36.4649 415.947 36.4649L367.738 119.282C367.738 119.282 362.557 133.065 340.368 134.876L57.72 133.977L1.22755 30.3713Z"
        fill="#2685FD"
      />
      <path
        d="M399.94 2.54831C427.19 9.81487 417.69 33.0318 417.69 33.0318L357.752 133.594L209.314 133.157L281.939 1.28174L399.94 2.54831Z"
        fill="#0166DA"
      />
    </svg>
  );
}
