// import { toast } from "sonner";
// import { getUserData, supabase } from "utils/SupabaseClient";

// export async function Develope({
//     setIsLoading,
// setError,
// setSuccess,
// setRecharge,
// }) {
//     setIsLoading(true);

//     const user = await getUserData();

//     const items = {
// project_id: '04a4d30d-d133-4281-98db-33fb5a401adc', type_id: , title: , description: , status_id: , created_by: , modified_by: , service_id: , subcompany_id: ,
//     };

//     const { data: Insercion, error: ErrInsercion } = await supabase
//       .from("th_ti")
//       .insert(items);

//     if (ErrInsercion) {
//       toast.error("¡No fue posible enviar tu solicitud!");

//       setError(true);
//       setRecharge(true);
//     } else {
//       toast.success("¡Solicitud enviada exitosamente!");
//       setSuccess(true);
//       setRecharge(true);
//     }
// }
