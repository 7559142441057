import { useState } from "react";
import { CreateIssues } from "./modules";
import { Issues } from "./issues/Issues";
import { Tabs } from "components/common/Tabs";

export function Develop() {
  const items = [
    { state: true, nombre: "Mis solicitudes" },
    { state: false, nombre: "Generar reunión" },
    { state: false, nombre: "Nueva solicitud" },
  ];
  const [subModuleActive, setSubModuleActive] = useState(items[0].nombre);

  return (
    <>
      <Tabs items={items} setActive={setSubModuleActive} />
      {subModuleActive === items[0].nombre && <Issues />}
      {subModuleActive === items[1].nombre && <Meeting />}
      {subModuleActive === items[2].nombre && <CreateIssues />}
    </>
  );
}

function Meeting() {
  const url =
    "https://outlook.office365.com/owa/calendar/G9d13292448c245a6befb8f679d3403e7@arsabi.co/bookings/";
  const [showIframe, setShowIframe] = useState(false);
  return (
    <div
      className={`md:mx-8 p-4 ronded-md bg-white ${
        showIframe ? "h-[78vh] 2xl:h-[85vh]" : ""
      }`}
    >
      <div className="mb-3">
        <div className="flex items-center justify-between">
          <p className="text-lg mb-4">Agende una reunión de asistencia aquí:</p>
          {showIframe && (
            <button
              className="bg-sky-800 hover:bg-yellow-500 w-32 p-2 text-white rounded-sm"
              onClick={() => setShowIframe(false)}
            >
              Cerrar
            </button>
          )}
        </div>
        <div className="2xl:mx-8">
          {!showIframe && (
            <button
              className="bg-sky-800 hover:bg-yellow-500 w-full p-2 text-white rounded-sm"
              onClick={() => setShowIframe(true)}
            >
              Agendar reunión
            </button>
          )}

          {showIframe && (
            <div
              className="mt-4 relative"
              style={{ height: "calc(100vh - 200px)" }}
            >
              <iframe
                src={url}
                width="100%"
                height="95%"
                title="Agendar Reunión"
                frameBorder="0"
                style={{ border: "none" }}
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
