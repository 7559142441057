import { ModalBG } from "components/common/ModalBG";
import { useState } from "react";
import { StructureModal } from "./StructureModal";

export function ModalEditUser({ isOpenEdit, onClose, dataEdit }) {
  //   const [isLoading, setIsLoading] = useState(false);
  //   const [success, setSuccess] = useState(false);
  //   const [error, setError] = useState(false);
  //   const [name, setName] = useState("");
  //   const [lastName, setLastName] = useState("");
  //   const [phone, setPhone] = useState("");
  //   const [email, setEmail] = useState("");
  //   const [roleId, setRoleId] = useState("");
  //   const [subCompany, setSubCompany] = useState("");
  //   const [documento, setDocumento] = useState("");
  //   const [typeDocument, setTypeDocument] = useState("");
  //   const [password, setPassword] = useState("");
  //   useEffect(() => {
  //     getTypeDocument().then((r) =>
  //       setTypeDocumentData(
  //         r.map((item) => ({ value: item.tipo, label: item.tipo }))
  //       )
  //     );
  //     getRoles().then((r) =>
  //       setRolesData(r.map((item) => ({ value: item.id, label: item.rol })))
  //     );
  //     getSubCompañias(companyId).then((r) =>
  //       setSubCompanyData(
  //         r.map((item) => ({ value: item.id, label: item.subcompañia }))
  //       )
  //     );
  //   }, []);
  //   return (
  //     <ModalBG open={isOpenEdit} onClose={onClose} title="Editar Usuario">
  //       <StructureModal
  //         isLoading={isLoading}
  //         setName={setName}
  //         name={name}
  //         setLastName={setLastName}
  //         lastName={lastName}
  //         setPhone={setPhone}
  //         phone={phone}
  //         setEmail={setEmail}
  //         email={email}
  //         rolesData={rolesData}
  //         setRoleId={setRoleId}
  //         roleId={roleId}
  //         subCompanyData={subCompanyData}
  //         setSubCompany={setSubCompany}
  //         subCompany={subCompany}
  //         typeDocumentData={typeDocumentData}
  //         setTypeDocument={setTypeDocument}
  //         typeDocument={typeDocument}
  //         setDocumento={setDocumento}
  //         documento={documento}
  //         setPassword={setPassword}
  //         password={password}
  //         form={() => {}}
  //       />
  //     </ModalBG>
  //   );
}
