import { BackGroundSections } from "components/common/BackGroundSections";
import { VideosCourseComponents } from "components/training/VideosCourseComponents";

export function VideoSelectedSection({ url, video }) {
  return (
    <div className="col-span-2">
      <BackGroundSections>
        <VideosCourseComponents src={url} />
      </BackGroundSections>
    </div>
  );
}
