import { toast } from "sonner";
import { supabase } from "utils/SupabaseClient";

export async function ChangePassword({
  password,
  setPassword,
  setConfirmPassword,
  setisLoading,
}) {
  setisLoading(true);
  const { error } = await supabase.auth.updateUser({
    password: password,
  });

  if (error) {
    setisLoading(false);
    toast.error("Error al actualizar la contraseña");
    setPassword("");
    setConfirmPassword("");
  } else {
    setisLoading(false);
    setPassword("");
    setConfirmPassword("");
    toast.success("Se ha actualizado la contraseña");
  }
}
