import { useEffect, useState } from "react";

import { getUserDetails } from "hooks/commonData";
import { getUserData } from "utils/SupabaseClient";
import { uploadFile } from "api/bi-module/storage";
import { saveConnection } from "api/bi-module/datasource";

import { InformationFile } from "./InformationFile";
import { ButtonSave } from "components/common/ButtonSave";
import { SearchSelect } from "components/tailwind/SearchSelect";

export function FormSubmit({ files, setIsLoading, isSuccess, setIsSuccess }) {
  const [subCompany, setSubCompany] = useState("");
  const [typeFile, setTypeFile] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [isUnique, setIsUnique] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [subCompanies, setSubCompanies] = useState([]);

  const HadleSubmit = async () => {
    setIsLoading(true);
    await saveConnection({
      subCompanyId: subCompanies.find(
        (item) => item.numero_documento === subCompany
      ).id,
      proveedor: "siigo",
      tipoArchivo: typeFile,
      archivo: fileName,
    });
    await uploadFile({
      fileName: `${
        subCompanies.find((item) => item.numero_documento === subCompany).id
      }/siigo/${typeFile}/${fileName}`,
      file: file,
    });
    setIsLoading(false);
    setIsSuccess(true);
  };

  useEffect(() => {
    if (isSuccess) setIsSuccess(false);
  }, [files]);

  useEffect(() => {
    getUserData().then((user) => {
      getUserDetails(user.id, "subcompanies").then((r) => {
        setSubCompanies(r.company.projects);
      });
    });
  }, []);

  useEffect(() => {
    setIsUnique(
      !!files.find(
        (item) =>
          item.file === fileName &&
          item.subcompany_id ===
            subCompanies.find((item) => item.numero_documento === subCompany).id
      )
    );
  }, [fileName, subCompany]);
  return (
    <>
      <div className="mt-4 mb-2">
        <p className="font-semibold">Información Empresa</p>
        <div className="grid grid-cols-2 gap-4">
          <SearchSelect
            option={subCompany}
            title={"Empresa"}
            items={subCompanies.map((item) => ({
              value: item.numero_documento,
              label: item.razon_social,
            }))}
            setOption={setSubCompany}
          />
          <div>
            <p className="block text-sm font-medium leading-6 text-gray-900 mb-2">
              NIT
            </p>
            <input
              value={subCompany}
              className="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-700 sm:text-sm sm:leading-6"
              disabled
            />
          </div>
        </div>
      </div>
      {subCompany && (
        <>
          <div className="mt-4 mb-2">
            <p className="font-semibold">Información archivo</p>
            <InformationFile
              fileSelected={setFile}
              typeFileSelected={setTypeFile}
              fileName={setFileName}
              setIsAvailable={setIsAvailable}
            />
          </div>
          {isUnique && (
            <div className="bg-red-400 px-4 py-2 rounded-md flex justify-between">
              <p className="text-sm text-white">
                ¡Ya tienes cargado el archivo, por favor eliminalo y vuelve a
                intentarlo mas tarde!
              </p>
            </div>
          )}
          {isAvailable && <ButtonSave HadleSubmit={HadleSubmit} />}
        </>
      )}
    </>
  );
}
