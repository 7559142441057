import { toast } from "sonner";
import { getUserData, supabase } from "utils/SupabaseClient";

export async function AddSubcompany({
  companyId,
  subCompany,
  nombre,
  apellido,
  nit,
  indicative,
  phone,
  mail,
  setIsLoading,
  setError,
  setSuccess,
  setRecharge,
}) {
  setIsLoading(true);

  const user = await getUserData();

  const items = {
    created_by: user.id,
    updated_by: user.id,
    company_id: companyId,
    razon_social: subCompany,
    nombre_responsable: nombre,
    apellido_responsable: apellido,
    tipo_documento: "NIT",
    numero_documento: nit,
    correo_responsable: mail,
    indicativo_celular: indicative,
    celular_responsable: phone,
  };

  const { data: Insercion, error: ErrInsercion } = await supabase
    .from("auth_user_subcompanies")
    .insert(items);

  if (ErrInsercion) {
    toast.error("¡No fue posible guardar la subcompañia!");

    setError(true);
    setRecharge(true);
  } else {
    toast.success("¡Subcompañia Guardada exitosamente!");
    setSuccess(true);
    setRecharge(true);
  }
}
