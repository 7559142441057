import { Button } from "components/common/Button";
import { Isotype } from "components/svg/Isotype";
import { downloadPicture, getUserDetails } from "hooks/commonData";
import { useEffect, useState } from "react";
import { getUserData } from "utils/SupabaseClient";
import { ModalChangeProfilePicture } from "./ModalChangeProfilePicture";

export function UserImage() {
  const [src, setSrc] = useState("");
  const [user, setUser] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getUserData().then((user) => {
      setUser(user.id);
      getUserDetails(user.id, "image").then((r) => {
        {
          if (!r[0]?.profile_picture) {
            setSrc("");
          } else {
            downloadPicture("Perfiles", r[0]?.profile_picture).then((r) => {
              setSrc(r);
            });
          }
        }
      });
    });
  }, []);

  return (
    <div className="flex">
      <ModalChangeProfilePicture
        open={open}
        setOpen={() => setOpen(false)}
        user={user}
      />
      {!src ? (
        <Isotype className={"my-10 h-32"} />
      ) : (
        <img
          src={src}
          alt="User profile"
          className="rounded-full w-32 h-32 my-10"
        />
      )}
      <div className="mt-20 ml-4">
        <Button text={"Cambiar foto de perfil"} onClick={() => setOpen(true)} />
      </div>
    </div>
  );
}
