export function Isotype({ w, h, className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 81 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40.5" cy="40.5" r="40.5" className="fill-[#F1C40F]" />
      <path
        d="M17 56.4033V26H28.7969C29.0939 26 29.4263 26.0141 29.7941 26.0422C30.176 26.0563 30.5579 26.0985 30.9398 26.1689C32.5382 26.4082 33.889 26.9642 34.9923 27.8369C36.1098 28.6955 36.9514 29.7793 37.5172 31.0883C38.0971 32.3974 38.3871 33.8472 38.3871 35.4377C38.3871 37.0142 38.0971 38.4569 37.5172 39.7659C36.9373 41.075 36.0886 42.1658 34.9711 43.0385C33.8678 43.8971 32.5241 44.4461 30.9398 44.6854C30.5579 44.7417 30.176 44.7839 29.7941 44.812C29.4263 44.8402 29.0939 44.8543 28.7969 44.8543H20.119V56.4033H17ZM20.119 41.8984H28.712C28.9666 41.8984 29.2637 41.8843 29.6031 41.8562C29.9426 41.828 30.275 41.7787 30.6003 41.7084C31.6471 41.4832 32.5029 41.0539 33.1677 40.4205C33.8466 39.7871 34.3488 39.034 34.6741 38.1613C35.0136 37.2886 35.1833 36.3808 35.1833 35.4377C35.1833 34.4946 35.0136 33.5867 34.6741 32.7141C34.3488 31.8273 33.8466 31.0672 33.1677 30.4338C32.5029 29.8004 31.6471 29.3711 30.6003 29.1459C30.275 29.0755 29.9426 29.0333 29.6031 29.0192C29.2637 28.9911 28.9666 28.977 28.712 28.977H20.119V41.8984Z"
        className="fill-white"
      />
      <path
        d="M41 56.7906V26.3873H52.7969C54.5085 26.3873 56.0007 26.7392 57.2737 27.4429C58.5468 28.1467 59.537 29.0968 60.2442 30.2932C60.9513 31.4756 61.305 32.7846 61.305 34.2203C61.305 35.8531 60.8664 37.3099 59.9896 38.5908C59.1127 39.8576 57.9384 40.7374 56.4675 41.23L56.425 40.1954C58.4052 40.7585 59.9614 41.7437 61.0929 43.1513C62.2244 44.5589 62.7903 46.262 62.7903 48.2608C62.7903 50.0202 62.4153 51.5404 61.6657 52.8213C60.9162 54.0881 59.8623 55.0663 58.5044 55.756C57.1464 56.4457 55.5694 56.7906 53.7729 56.7906H41ZM44.1402 53.8136H53.009C54.2539 53.8136 55.3711 53.5954 56.3614 53.1591C57.3516 52.7087 58.1294 52.0753 58.6953 51.2589C59.2751 50.4425 59.5652 49.4572 59.5652 48.303C59.5652 47.1769 59.3037 46.1635 58.7802 45.2627C58.2567 44.3618 57.5426 43.6439 56.6372 43.1091C55.7461 42.5601 54.7276 42.2857 53.5819 42.2857H44.1402V53.8136ZM44.1402 39.3509H52.7757C53.7517 39.3509 54.6428 39.1327 55.449 38.6964C56.2553 38.246 56.8918 37.6337 57.3586 36.8595C57.8396 36.0853 58.08 35.1916 58.08 34.1781C58.08 32.7424 57.5777 31.5741 56.5736 30.6733C55.5833 29.7724 54.3176 29.322 52.7757 29.322H44.1402V39.3509Z"
        className="fill-white"
      />
    </svg>
  );
}
