import { useState } from "react";

export function InputText({
  className,
  placeholder,
  setValue,
  value,
  type,
  defaultValue,
  disabled,
  required,
}) {
  const [onFocus, setOnFocus] = useState(false);

  return (
    <div className={`${className}`}>
      <label
        className={`text-black bg-white transition-all ml-1 px-1 text-sm `}
      >
        {placeholder}
      </label>

      <input
        onFocus={() => setOnFocus(true)}
        onBlur={() => setOnFocus(false)}
        type={type ? type : "text"}
        onChange={(e) => setValue(e.target.value)}
        defaultValue={defaultValue}
        disabled={disabled}
        className="border border-slate-300 focus:ring-2 focus:ring-[#F1C40F] focus:outline-none transition-all outline-of w-full p-2 text-sm rounded-md mt-3"
        value={value}
        required={required}
        placeholder={onFocus ? "" : placeholder + "..."}
      />
    </div>
  );
}
