import { BackGroundSections } from "components/common/BackGroundSections";
import { StatusDetail } from "components/training/StatusDetail";
import ModuleManager from "./moduleManagerComponents/ModuleManager";

export function FilesSection({
  colorId,
  statusName,
  id_course,
  setUrl,
  setVideo,
}) {
  return (
    <BackGroundSections>
      <div className="flex">
        <StatusDetail colorId={colorId} statusName={statusName} />
      </div>
      <ModuleManager
        id_course={id_course}
        setUrl={setUrl}
        setVideo={setVideo}
      />
    </BackGroundSections>
  );
}
