import { useState } from "react";
import { MenuVisualBudget } from "./submodules/MenuVisualBudget";
import { Visual } from "./submodules/form/Visual";

export function Budget() {
  const [submodule, setSubmodule] = useState("MenuVisualBudget");

  return (
    <div>
      <MenuVisualBudget submodule={submodule} setSubmodule={setSubmodule} />
      <Visual submodule={submodule} setSubmodule={setSubmodule} />
    </div>
  );
}
