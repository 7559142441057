import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { getUserData } from "utils/SupabaseClient";
import {
  getCoursesStudent,
  getUserCompanyPlan,
  assignFreeCoursesToUser,
  assignActiveCoursesToUser,
} from "../../hooks/commonData";
import { Module } from "components/common/Module";
import { TarjetCourses } from "components/training/TarjetCourses";
import { ChargingComponent } from "components/common/ChargingComponent";

export function Training() {
  const [userId, setUserId] = useState(null);
  const [courses, setCourses] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 250);

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  useEffect(() => {
    setLoading(true);

    getUserData().then((user) => {
      setUserId(user.id)
      getCoursesStudent(user.id).then((r) => setCourses(r))}
    )
    setLoading(false);
  }, []);

  useEffect(() => {
    const loadUserData = async () => {
      setLoading(true);
      const user = await getUserData();
      setUserId(user?.id);

      const userPlanData = await getUserCompanyPlan(user?.id);

      if (!userPlanData) {
        console.error("No se pudo obtener la información del plan del usuario.");
        setLoading(false);
        return;
      }

      if (userPlanData.id_plan === 1) {
        await assignFreeCoursesToUser(user.id);
      } else {
        await assignActiveCoursesToUser(user.id);
      }

      const userCourses = await getCoursesStudent(user.id);
      setCourses(userCourses);
      setLoading(false);
    };

    loadUserData();
  }, []);

  const filteredCourses = courses.filter((course) =>
    course?.info_curso?.nombre_curso
      ?.toLowerCase()
      .includes(debouncedSearchTerm.toLowerCase())
  );

  return (
    <Module>
      {loading ? (
        <ChargingComponent />
      ) : (
        <>
          <div className="bg-gradient-to-r from-sky-700 via-sky-500 to-yellow-500 w-full px-4 mb-4 rounded-sm uppercase flex items-center justify-between">
            <h1 className="text-center text-white font-bold uppercase w-full">
              ¡Aprende todos los días algo nuevo!
            </h1>
            <div className="flex items-center space-x-2">
              <img src="assets/gift/write.gif" className="w-36" />
            </div>
          </div>
          <h1 className="text-2xl font-semibold text-sky-700 mb-4">
            Cursos actuales
          </h1>
          <div className="mb-4 relative">
            <input
              type="text"
              placeholder="Buscar cursos..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="p-2 pl-10 pr-4 border rounded-md w-full"
            />

            <FiSearch
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
              size={20}
            />
          </div>
          {filteredCourses.length === 0 ? (
            "No hay cursos disponibles"
          ) : (
            <div
              className={`lg:grid lg:grid-cols-4 gap-4 p-4 slide-down-animation`}
            >
              {filteredCourses.map((course) => (
                <TarjetCourses
                  key={course.id_curso}
                  to={
                    course?.estado_curso?.nombre_estado === "Bloqueado"
                      ? ""
                      : `/training/course/${course.id_curso}`
                  }
                  title={course?.info_curso?.nombre_curso}
                  description={course?.info_curso?.descripcion}
                  image={course?.info_curso?.nombre_miniatura}
                  value={course?.info_curso?.valor_curso}
                  status={course?.estado_curso?.nombre_estado}
                  colorId={course?.estado_curso?.color_id}
                />
              ))}
            </div>
          )}
        </>
      )}
    </Module>
  );
}
