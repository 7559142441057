export function PowerPlatformIcon({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 406 431"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M101.493 119.788C101.493 119.788 83.727 111.48 91.4168 97.1609L135.743 10.3688C135.743 10.3688 138.243 1.55615 149.743 0.305887L348.74 0C348.74 0 401.218 10.0101 405.107 60.0378L405.107 80.8077C405.107 80.8077 402.866 128.362 347.767 142.097C347.767 142.097 339.186 143.878 334.836 144.338C330.811 144.763 322.701 144.811 322.701 144.811C322.701 144.811 323.879 126.947 286.578 120.229"
        fill="url(#paint0_linear_777_4378)"
      />
      <path
        d="M8.91016 273.335C8.91016 273.335 13.2421 264.654 24.6797 263.279L290.604 262.02C290.604 262.02 313.408 259.015 331.616 238.156L395.139 108.503C395.139 108.503 379.136 144.811 322.703 144.811L91.9952 143.889C91.9952 143.889 73.1688 146.497 65.8324 160.02"
        fill="url(#paint1_linear_777_4378)"
      />
      <path
        d="M8.90957 273.335C8.90957 273.335 -4.61405 290.835 1.66148 308.424L59.3039 423.591C59.3039 423.591 72.5488 440.742 86.2492 421.031L165.002 262.615L24.6791 263.279C24.6791 263.279 14.4783 264.01 8.90957 273.335Z"
        fill="url(#paint2_linear_777_4378)"
      />
      <path
        d="M3.23828 283.526C3.23828 283.526 14.2523 273.525 29.2521 273.962C44.2523 274.4 159.53 273.619 159.53 273.619L165.002 262.613L24.5646 263.46C24.5646 263.46 15.9187 263.717 9.22592 272.776"
        fill="url(#paint3_linear_777_4378)"
        fill-opacity="0.603399"
      />
      <defs>
        <linearGradient
          id="paint0_linear_777_4378"
          x1="89.4868"
          y1="45.9282"
          x2="405.107"
          y2="119.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1BA291" />
          <stop offset="1" stop-color="#076989" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_777_4378"
          x1="8.91012"
          y1="190.919"
          x2="395.139"
          y2="190.919"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9ADD95" />
          <stop offset="1" stop-color="#569EA5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_777_4378"
          x1="84.8531"
          y1="262.615"
          x2="76.3273"
          y2="429.721"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1B975E" />
          <stop offset="1" stop-color="#1DCC9F" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_777_4378"
          x1="88.5045"
          y1="248.696"
          x2="89.2328"
          y2="296.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
