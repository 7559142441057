import { ButtonSave } from "components/common/ButtonSave";
import { InputText } from "components/common/InputText";
import { ModalBG } from "components/common/ModalBG";
import { useEffect, useState } from "react";
import { Country } from "country-state-city";
import { SearchSelect } from "components/tailwind/SearchSelect";
import { ChargingComponent } from "components/common/ChargingComponent";
import { AddSubcompany } from "api/user-module/AddSubCompany";

export function ModalAddSubCompanie({
  setIsOpen,
  isOpen,
  setRecharge,
  nombre,
  apellido,
  companyId,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [countries, setCountries] = useState([]);

  const [subCompany, setSubCompany] = useState("");
  const [nit, setNit] = useState("");
  const [indicative, setIndicative] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");

  useEffect(() => {
    const countriesData = Country.getAllCountries();

    if (countriesData) {
      setCountries(
        countriesData.map((item) => ({
          label: `${item.phonecode} ${item.name}`,
          value: `${item.phonecode}`,
        }))
      );
    } else {
      console.error("No se pudieron obtener los países");
    }
  }, []);

  useEffect(() => {
    if (success || error) {
      setIsOpen(false);
      setIsLoading(false);
      setSuccess(false);
      setError(false);
      setSubCompany("");
      setNit("");
      setIndicative("");
      setPhone("");
      setMail("");
    }
  }, [success, error]);

  const funcion = async () => {
    await AddSubcompany({
      companyId,
      subCompany,
      nombre,
      apellido,
      nit,
      indicative,
      phone,
      mail,
      setIsLoading,
      setError,
      setSuccess,
      setRecharge,
    });
  };

  return (
    <ModalBG
      open={isOpen}
      onClose={() => setIsOpen(false)}
      title={"Añadir Nueva Sub Compañia"}
    >
      <div className={isLoading ? "appear-animation" : "hidden"}>
        <ChargingComponent />
      </div>

      <div className={!isLoading ? "appear-animation" : "hidden"}>
        <InputText
          setValue={setSubCompany}
          value={subCompany}
          placeholder={"Nombre Subcompañia"}
          required
        />
        <div className="grid grid-cols-2 gap-2">
          <InputText
            setValue={setMail}
            value={mail}
            placeholder={"Correo"}
            required
          />
          <InputText
            setValue={setNit}
            value={nit}
            placeholder={"NIT"}
            required
          />
        </div>
        <div className="grid grid-cols-3 gap-2">
          <SearchSelect
            items={countries}
            setOption={setIndicative}
            option={indicative}
            title={"Indicativo"}
          />
          <div className="col-span-2">
            <InputText
              setValue={setPhone}
              value={phone}
              placeholder={"Teléfono"}
              required
            />
          </div>
        </div>

        <ButtonSave HadleSubmit={funcion} />
      </div>
    </ModalBG>
  );
}
