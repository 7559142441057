// import { useState, useEffect } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { getCourses, updateCourse, uploadImage } from "hooks/commonData";
// import { Button } from "components/common/Button";
// import { toast } from "react-toastify";

// export function AdminCourse() {
//   const { courseId } = useParams();
//   const navigate = useNavigate();

//   const [course, setCourse] = useState(null);
//   const [newCourseName, setNewCourseName] = useState("");
//   const [newImage, setNewImage] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchCourseData = async () => {
//       try {
//         const data = await getCourses(courseId);
//         if (data && data.length > 0) {
//           const courseData = data[0];
//           setCourse(courseData);
//           setNewCourseName(courseData.nombre_curso);
//         }
//       } catch (error) {
//         toast.error("Error al obtener los datos del curso.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchCourseData();
//   }, [courseId]);

//   const handleSaveChanges = async () => {
//     setLoading(true);

//     try {
//       let imageUrl = course.nombre_miniatura;
//       if (newImage) {
//         const uploadedImage = await uploadImage(newImage);
//         imageUrl = uploadedImage.publicUrl;
//       }

//       await updateCourse(courseId, newCourseName, imageUrl);
//       toast.success("Curso actualizado exitosamente");
//       navigate(`/training/course/${courseId}`);
//     } catch (error) {
//       toast.error("Error al actualizar el curso.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setNewImage(file);
//     }
//   };

//   if (loading) {
//     return <div>Cargando...</div>;
//   }

//   return (
//     <div>
//       <h1>Administrar Curso</h1>
//       <div>
//         <label>Nombre del curso:</label>
//         <input
//           type="text"
//           value={newCourseName}
//           onChange={(e) => setNewCourseName(e.target.value)}
//           className="p-2 border rounded-md w-full"
//         />
//       </div>

//       <div className="mt-4">
//         <label>Imagen del curso:</label>
//         <input
//           type="file"
//           accept="image/*"
//           onChange={handleImageChange}
//           className="p-2 border rounded-md w-full"
//         />
//         {course.nombre_miniatura && (
//           <div className="mt-2">
//             <img
//               src={course.nombre_miniatura}
//               alt="Miniatura actual"
//               className="w-32 h-32 object-cover"
//             />
//           </div>
//         )}
//       </div>

//       <div className="mt-4 flex justify-end">
//         <Button text="Guardar cambios" onClick={handleSaveChanges} />
//       </div>
//     </div>
//   );
// }






import { useState, useEffect } from "react";
import {
  getAllUser,
  getCourses,
  getUsersInCourse,
  assignUserToCourse,
  removeUserFromCourse,
} from "hooks/commonData";
import { Module } from "components/common/Module";
import { BackGroundSections } from "components/common/BackGroundSections";
import { useRoleContext } from "modules/auth/admin/RoleContex";
import { Select } from "components/tailwind/Select";
import { FiSearch } from "react-icons/fi";

export function AdminCourse() {
  const [courses, setCourses] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { userRole } = useRoleContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 250);

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      try {
        const coursesData = await getCourses();

        if (!coursesData || coursesData.length === 0) {
          setMessage("No courses found.");
          return;
        }

        setCourses(coursesData);
      } catch (error) {
        setMessage("Error loading courses");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    getAllUser().then((r) => setUsers(r));
  }, []);

  useEffect(() => {
    if (selectedCourse) {
      const fetchAssignedUsers = async () => {
        const usersInCourse = await getUsersInCourse(selectedCourse);
        setAssignedUsers(usersInCourse);
      };

      fetchAssignedUsers();
    }
  }, [selectedCourse]);

  if (userRole !== 7) {
    return <div>No tienes permisos para acceder a este contenido</div>;
  }

  const handleUserSelect = (userId) => {
    setSelectedUser(userId);
  };

  const handleAssignUser = async () => {
    if (!selectedCourse || !selectedUser) return;
  
    const existingAssignment = assignedUsers.find(
      (user) => user.user_id === selectedUser
    );
  
    if (existingAssignment) {
      setMessage("Este usuario ya está asignado a este curso.");
      return;
    }
  
    try {
      await assignUserToCourse(selectedCourse, selectedUser);
      setAssignedUsers([...assignedUsers, { user_id: selectedUser, created_at: new Date().toISOString() }]);
      setSelectedUser("");
      setMessage("");
    } catch (error) {
      setMessage("Error asignando usuario al curso.");
    }
  };  

  const handleRemoveUser = async (userId) => {
    try {
      await removeUserFromCourse(selectedCourse, userId);
      setAssignedUsers(assignedUsers.filter((user) => user.user_id !== userId));
    } catch (error) {
      setMessage("Error eliminando usuario del curso.");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("es-ES");
  };

  const calculateRemainingDays = (assignDate) => {
    const assignedDate = new Date(assignDate);
    const expirationDate = new Date(assignedDate);
    expirationDate.setDate(expirationDate.getDate() + 90);

    const today = new Date();
    const timeDiff = expirationDate - today;
    const remainingDays = Math.floor(timeDiff / (1000 * 3600 * 24));

    return remainingDays > 0 ? remainingDays : 0;
  };

  const getRemainingDaysTextColor = (remainingDays) => {
    if (remainingDays > 60) {
      return "text-green-500";
    } else if (remainingDays >= 30) {
      return "text-yellow-500";
    } else {
      return "text-red-500";
    }
  };

  const filteredAssignedUsers = assignedUsers.filter((user) => {
    const userInfo = users.find((u) => u.id === user.user_id);
    return (
      userInfo &&
      `${userInfo.first_name} ${userInfo.last_name}`
        .toLowerCase()
        .includes(debouncedSearchTerm.toLowerCase())
    );
  });

  return (
    <Module title="Administrar Cursos" back linkToBack={"/trainingpmo"}>
      <BackGroundSections>
        {loading && <p>Loading...</p>}
        {message && <p>{message}</p>}

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mx-8">
          <div>
            <Select
              title={"Seleccionar Curso"}
              value={selectedCourse}
              options={courses.map((course) => ({
                value: course.id,
                name: course.nombre_curso,
              }))}
              onChange={setSelectedCourse}
            />
          </div>

          <div>
            <Select
              title={"Seleccionar Usuario"}
              value={selectedUser}
              options={users.map((user) => ({
                value: user.id,
                name: `${user.first_name} ${user.last_name}`,
              }))}
              onChange={handleUserSelect}
            />
            <button
              onClick={handleAssignUser}
              disabled={!selectedCourse || !selectedUser}
              className="mt-2 bg-blue-500 text-white px-4 py-2 rounded"
            >
              Asignar Usuario
            </button>
          </div>
        </div>

        <div className="mt-6">
          {selectedCourse && assignedUsers.length > 0 && (
            <div className="mb-4 relative">
              <input
                type="text"
                placeholder="Buscar usuarios..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="p-2 pl-10 pr-4 border rounded-md w-full"
              />
              <FiSearch
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
                size={20}
              />
            </div>
          )}
        </div>

        {selectedCourse && filteredAssignedUsers.length > 0 && (
          <div className="mt-6 relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead className="text-xs text-sky-800 uppercase bg-gray-50 sticky top-0 z-20">
                <tr>
                  <th scope="col" className="px-6 py-3">Nombre</th>
                  <th scope="col" className="px-6 py-3">Fecha de Asignación</th>
                  <th scope="col" className="px-6 py-3">Días Restantes</th>
                  <th scope="col" className="px-6 py-3">Acción</th>
                </tr>
              </thead>
              <tbody>
                {filteredAssignedUsers.map((user) => {
                  const userInfo = users.find((u) => u.id === user.user_id);
                  const remainingDays = user.created_at ? calculateRemainingDays(user.created_at) : 0;
                  return (
                    <tr key={user.user_id}>
                      <td className="px-6 py-4">
                        {userInfo ? `${userInfo.first_name} ${userInfo.last_name}` : "Usuario no encontrado"}
                      </td>
                      <td className="px-6 py-4">
                        {user.created_at ? formatDate(user.created_at) : "Fecha no disponible"}
                      </td>
                      <td className={`px-6 py-4 ${getRemainingDaysTextColor(remainingDays)}`}>
                        {remainingDays} días
                      </td>
                      <td className="px-6 py-4">
                        <button
                          onClick={() => handleRemoveUser(user.user_id)}
                          className="text-red-500"
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </BackGroundSections>
    </Module>
  );
}
