import { useEffect, useState } from "react";

import { getSubmodules } from "hooks/commonData";

import { Tabs } from "components/common/Tabs";
import { Reports } from "./submodules/reports";
import { ViewDocFiles } from "./submodules/documents";
import { Integrations } from "./submodules/integrations";
import { ConnectSource } from "./submodules/connections";

export function BIModule() {
  const SelectedSubmodule = localStorage.getItem("SelectedSubmodule");
  const submodule = localStorage.getItem("Submodule");
  const [active, setActive] = useState(
    SelectedSubmodule === null ? "cargando..." : SelectedSubmodule
  );
  const [items, setItems] = useState(
    submodule === null ? [{ nombre: "cargando..." }] : submodule
  );

  useEffect(() => {
    localStorage.setItem("SelectedSubmodule", active);
  }, [active]);

  useEffect(() => {
    if (!submodule) {
      getSubmodules(9).then((r) => {
        setItems(r);
        localStorage.setItem("submodule", r);
      });
    }
  }, [submodule]);

  return (
    <>
      <Tabs items={items} setActive={setActive} active={active} />
      <ConnectSource submodule={active} setSubmodule={setActive} />
      <Reports submodule={active} setSubmodule={setActive} />
      <Integrations submodule={active} setSubmodule={setActive} />
      <ViewDocFiles submodule={active} setSubmodule={setActive} />
    </>
  );
}
