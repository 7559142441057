import { useEffect, useState } from "react";
import { pastYears, getMonths, validateColumnsExcel } from "utils/Operations";
import { fetchConnectFiles } from "api/bi-module/datasource";
import { validateFile, validateFileName } from "api/bi-module/storage";
import { toast } from "sonner";

import { Files } from "components/tailwind/Files";
import { Select } from "components/tailwind/Select";

export function InformationFile({
  fileName,
  fileSelected,
  typeFileSelected,
  setIsAvailable,
}) {
  const [typeFileOptions, setTypeFileOptions] = useState([]);
  const [file, setFile] = useState(null);
  const [years, setYears] = useState([]);
  const [months, setMonth] = useState([]);

  const [selectedTypeFile, setSelectedTypeFile] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [files, setFiles] = useState([]);
  const [isFileEnabled, setIsFileEnabled] = useState(false);

  const validateColumnsFile = () => {
    const bufferFile = files[0];
    if (files.length > 1) {
      setFiles([files[1]]);
      toast.warning("Solo se puede subir un archivo");
      return;
    }
    if (bufferFile) {
      validateColumnsExcel(
        bufferFile,
        JSON.parse(file.columnas),
        file.columna_encabezados - 1
      ).then((r) => {
        if (!r) setFiles([]);
      });
      fileSelected(files[0]);
    }
  };

  useEffect(() => {
    fetchConnectFiles({ proveedor: "siigo" }).then((r) =>
      setTypeFileOptions(r)
    );
    setYears(
      pastYears({ years: 11 }).map((item) => ({
        value: item.year,
        name: item.year,
      }))
    );
    setMonth(
      getMonths().map((item) => ({ value: item.month, name: item.name }))
    );
  }, []);

  useEffect(() => {
    setFile(typeFileOptions.find((item) => item.id == selectedTypeFile));
    if (selectedTypeFile && selectedTypeFile != -1) {
      typeFileSelected(
        typeFileOptions.find((item) => item.id == selectedTypeFile).tipo
      );
      if (file) {
        validateColumnsFile();
      }
    }
  }, [selectedTypeFile, file]);

  useEffect(() => {
    validateColumnsFile();
  }, [files]);

  useEffect(() => {
    if (file) {
      setIsFileEnabled(
        validateFile({
          format: file.formato_fecha,
          year: selectedYear,
          month: selectedMonth,
        })
      );
      fileName(
        validateFileName({
          type: file.formato_fecha,
          year: selectedYear,
          month: selectedMonth,
          fileName: file.tipo,
        })
      );
      setIsAvailable(isFileEnabled && files.length > 0);
    }
  }, [file, selectedYear, selectedMonth, files]);

  return (
    <div className="grid grid-cols-3 gap-4">
      <Select
        options={typeFileOptions.map((item) => ({
          value: item.id,
          name: item.tipo,
        }))}
        field="file_type"
        title="Tipo de archivo"
        onChange={setSelectedTypeFile}
        required
      />
      {file &&
        (file.formato_fecha === "yyyy" || file.formato_fecha === "yyyymm") && (
          <Select
            options={years}
            field="year_file"
            title="Año de archivo"
            onChange={setSelectedYear}
            required={
              file.formato_fecha === "yyyy" || file.formato_fecha === "yyyymm"
            }
          />
        )}
      {file && file.formato_fecha === "yyyymm" && (
        <Select
          options={months}
          field="month_file"
          title="Mes de archivo"
          onChange={setSelectedMonth}
          required={file.formato_fecha === "yyyymm"}
        />
      )}
      {isFileEnabled && selectedTypeFile != -1 && (
        <Files
          title="Archivo siigo"
          message="Solo se aceptan archivos .xlsx"
          files={files}
          setFiles={setFiles}
          isCSV
        />
      )}
    </div>
  );
}
