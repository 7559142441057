import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserData } from "utils/SupabaseClient";
import {
  getUserDetails,
  getEnumFieldItems,
  getProjectDetails,
} from "hooks/commonData";
import { saveCustumerProblem } from "api/problems/route";
import { ChargingComponent } from "components/common/ChargingComponent";
import { Text } from "components/tailwind/Text";
import { Form } from "components/tailwind/Forms";
import { Files } from "components/tailwind/Files";
import { Select } from "components/tailwind/Select";
import { TextArea } from "components/tailwind/TextArea";
import { toast } from "sonner";

export function Problems({ ticketTypeId }) {
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [enumServices, setEnumServices] = useState([]);
  const [enumProjects, setEnumProjects] = useState([]);
  const [enumImpacts, setEnumImpacts] = useState([]);
  const [loading, setLoading] = useState(false);

  const consultarProyectos = user !== "";

  useEffect(() => {
    getUserData().then((data) => {
      setUser(data.id);
    });
    getEnumFieldItems(7).then((data) => {
      setEnumImpacts(data);
    });
  }, []);

  useEffect(() => {
    if (consultarProyectos) {
      getUserDetails(user, "projects").then((data) => {
        setEnumProjects(data.company.projects);
      });
    }
  }, [consultarProyectos]);

  const [selectedService, setSelectedService] = useState(-1);
  const [selectedProject, setSelectedProject] = useState(-1);
  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [selectedImpact, setSelectedImpact] = useState(-1);
  const [reason, setReason] = useState("");
  const [solution, setSolution] = useState("");
  const [steps, setSteps] = useState("");
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (selectedProject != -1) {
      getProjectDetails(selectedProject, "services").then((data) => {
        setEnumServices(data.th_user_projects_services);
      });
    } else {
      setEnumServices([]);
    }
  }, [selectedProject]);

  const handlesave = async () => {
    if (
      !selectedService === -1 ||
      !selectedProject === -1 ||
      !summary ||
      !description ||
      selectedImpact === -1 ||
      !reason ||
      !solution ||
      !steps
    ) {
      toast.error("Por favor llene todos los campos");
      return;
    }

    setLoading(true);
    try {
      try {
        const result = await saveCustumerProblem({
          ticketTypeId,
          projectId: selectedProject,
          summary,
          description,
          serviceId: selectedService,
          impactValue: selectedImpact,
          reason,
          solution,
          steps,
          attachment: "Adjuntos",
          files,
        });

        if (result?.error) {
          toast.error(result.error);
          return;
        }

        toast.success("Problema creado exitosamente");
      } catch (error) {
        console.error("Error inesperado");
        return;
      }
      ChargingComponent();
      //TODO: Navegar a interfaz adecuada
      navigate("/support");
    } catch (error) {
      toast.error("Error desconocido");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mx-auto max-w-7xl p-6 lg:p-8">
      {loading ? (
        <ChargingComponent />
      ) : (
        <Form
          title={"Problemas"}
          description={"Por favor describe el problema o falla masiva."}
          onClick={handlesave}
        >
          <Select
            title={"Proyecto"}
            field={"project"}
            onChange={setSelectedProject}
            required={true}
            options={enumProjects.map((proyecto) => ({
              value: proyecto.id,
              name: proyecto.project_name,
            }))}
          />
          <Select
            title={"Servicio"}
            field={"service"}
            onChange={setSelectedService}
            required={true}
            options={enumServices.map((item) => ({
              value: item.services.id,
              name: item.services.item_value,
            }))}
          />
          <Text title="Resumen" field={"summary"} onChange={setSummary} />
          <TextArea
            title={"Descripción"}
            onChange={setDescription}
            required={true}
            description={"Por favor describemos el error."}
          />
          <Select
            title={"Impacto"}
            onChange={setSelectedImpact}
            required={true}
            options={enumImpacts.map((impacto) => ({
              value: impacto.item_value,
              name: impacto.item_value,
            }))}
          />
          <TextArea
            title={"Causa principal"}
            onChange={setReason}
            required={true}
            description={"Motivo por el cual se genera el problema."}
          />
          <TextArea
            title={"Solución"}
            onChange={setSolution}
            required={true}
            description={"Plan a ejecutar para solventar el problema."}
          />
          <TextArea
            title={"Paso a paso de como ocurre el error"}
            onChange={setSteps}
            required={true}
            description={
              "Texto largo, descripción del paso a paso del proceos que realizo para que se le generara el error."
            }
          />
          <Files
            title={"Adjuntos"}
            message={"PNG, JPG, SVG, XLSX, CSV, PDF, DOC up to 50MB"}
            files={files}
            setFiles={setFiles}
          />
        </Form>
      )}
    </div>
  );
}
