export function ButtonIntegration({ children, onClick }) {
  return (
    <button
      className="bg-slate-100 hover:bg-yellow-100 transition-all rounded-lg py-2 px-5 hover:scale-105"
      onClick={onClick}
    >
      {children}
    </button>
  );
}
