import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase, getUserData } from "utils/SupabaseClient";
import { getUserModules } from "hooks/commonData";
import { App } from "./App";
import { toast } from "sonner";

export function Layout() {
  const [user, setUser] = useState(null);
  const [items, setItems] = useState([]);
  const [rol, setRol] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (!session) {
        navigate("/login");
      } else {
        const userData = await getUserData();

        const { data: rolesData, error: rolesError } = await supabase
          .from("auth_user_roles")
          .select("role_id")
          .eq("user_id", userData.id)
          .single();

        if (rolesError) {
          toast.error("Error al obtener el rol del usuario");
          return;
        }

        const roleId = rolesData?.role_id;
        if (rolesData) setRol(roleId);

        if (roleId === 7) {
          setItems((prevItems) =>
            prevItems.map((item) =>
              item.text === "Capacitación"
                ? { ...item, to: "/trainingpmo" }
                : item
            )
          );
        }

        const { data, error } = await supabase
          .from("auth_users")
          .select("first_name, last_name, email")
          .eq("id", `${userData.id}`);

        if (!error && data.length > 0) {
          setUser(data[0]);
          toast.success(
            `Bienvenid@ ${data[0]?.first_name} ${data[0]?.last_name}!`
          );
        }
      }
    };

    fetchSession();
  }, []);

  useEffect(() => {
    getUserModules()
      .then((data) => {
        let formattedItems = [];
        if (rol && String(rol) === "11") {
          formattedItems = [
            {
              icon: <i className={`lni lni-layers`}></i>,
              text: "Capacitación",
              active: false,
              alert: false,
              to: "/training",
            },
            {
              icon: <i className={`lni lni-user`}></i>,
              text: "Perfil",
              active: false,
              alert: false,
              to: "/profile",
            },
          ];
        } else if (rol) {
          formattedItems = data.map((module) => {
            return {
              icon: <i className={`lni lni-${module.icon}`}></i>,
              text: module.modulo,
              active: false,
              alert: false,
              to: module.path,
            };
          });
        }
        setItems(formattedItems);
      })
      .catch((error) => {
        toast.error("Error al cargar los módulos", error);
        setItems([]);
      });
  }, [rol]);

  return (
    <App
      nombre={user ? user.first_name : "...cargando"}
      apellido={user ? user.last_name : "...cargando"}
      email={user ? user.email : "...cargando"}
      items={items}
    />
  );
}
