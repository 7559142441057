import { BackGroundSections } from "components/common/BackGroundSections";
import { Button } from "components/common/Button";
import { Subtitle } from "components/common/Subtitle";
import { Row } from "components/table/Row";
import { Table } from "components/table/Table";
import { TD } from "components/table/TD";
import { getUserDetails } from "hooks/commonData";
import { useEffect, useState } from "react";
import { getUserData } from "utils/SupabaseClient";
import { ModalAddSubCompanie } from "./ModalAddSubCompanie";
import { FiEdit } from "react-icons/fi";
import { toast } from "sonner";

export function SubCompaniesVisual({
  submodule,
  nombre,
  apellido,
  companyId,
  company,
}) {
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [recharge, setRecharge] = useState(false);

  const nameRows = [
    { label: "Sub compañia", responsive: null },
    { label: "NIT", responsive: "md" },
    { label: "Responsable", responsive: "md" },
    { label: "Activo", responsive: "md" },
    { label: "Editar", responsive: null },
  ];

  useEffect(() => {
    getUserData().then((user) => {
      getUserDetails(user.id, "subcompanies").then((e) => {
        setRecharge(false);
        setData(e.company.projects);
      });
    });
  }, [recharge]);

  return (
    <div className={submodule === "Sub Compañias" ? "" : "hidden"}>
      <ModalAddSubCompanie
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setRecharge={setRecharge}
        nombre={nombre}
        apellido={apellido}
        companyId={companyId}
      />

      <BackGroundSections>
        <div className="flex justify-between mb-1">
          <Subtitle text={`Sub Compañias Asociadas`} />

          {data.length >= company?.id_plan.user_count ? (
            <button
              className=" rounded-md p-1 border-2 border-red-500 bg-red-200 text-red-500 font-bold hover:bg-red-500  hover:text-white transition-all"
              onClick={() =>
                toast.error("para poder añadir mas usuarios mejora tu plan.")
              }
            >
              ¡No puedes añadir mas usuarios!
            </button>
          ) : (
            <div>
              <Button
                className="appear-animation bg-sky-800 hover:bg-yellow-500 grow p-2 text-white rounded-lg transition-colors"
                text="añadir +"
                onClick={() => setIsOpen(true)}
              />
            </div>
          )}
        </div>
        <Table nameRows={nameRows}>
          {data.map((item) => (
            <Row key={item.id}>
              <TD item={item.razon_social} />
              <TD item={item.numero_documento} />
              <TD
                item={String(
                  `${item.nombre_responsable} ${item.apellido_responsable}`
                ).replaceAll("null", "")}
              />
              <TD
                item={String(item.activo)
                  .replaceAll("true", "activo")
                  .replaceAll("false", "inactivo")}
              />
              <FiEdit
                onClick={() => {}}
                className="w-7 h-7 ml-5 mt-2 hover:bg-slate-100 hover:scale-105 rounded-lg transition-all p-[2px]"
              />
            </Row>
          ))}
        </Table>
      </BackGroundSections>
    </div>
  );
}
