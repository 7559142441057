export function MincrosoftFabricIcon({ className, onClick }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 432 435"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.0469 219.181L116.672 187.806Z"
        fill="black"
        fill-opacity="0.603399"
      />
      <path
        d="M189.504 286.461L160.517 400.943C160.517 400.943 160.076 416.234 132.587 426.134L73.5433 434C73.5433 434 15.2583 439.832 1.41859 387.685C-1.63051 376.196 -4.76879 329.172 49.5017 309.726"
        fill="url(#paint0_linear_777_4354)"
      />
      <path
        d="M1.10547 362.394C1.10547 362.394 6.08283 324.776 49.5011 309.727L260.796 276.306C260.796 276.306 280.046 275.181 284.546 249.306L310.421 154.806L92.8589 187.181C92.8589 187.181 45.2338 198.368 40.0463 219.181"
        fill="url(#paint1_linear_777_4354)"
      />
      <path
        d="M39.4434 220.974C39.4434 220.974 46.9253 198.54 92.8585 187.181L384.818 143.786C384.818 143.786 404.953 140.892 405.926 108.63L431.693 20.661C431.693 20.661 436.568 -6.08892 399.943 1.28611L133.818 41.0361C133.818 41.0361 83.0684 47.2861 67.5684 107.286"
        fill="url(#paint2_linear_777_4354)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_777_4354"
          x1="97.9343"
          y1="286.461"
          x2="116.933"
          y2="428.22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#055047" />
          <stop offset="1" stop-color="#46B7A1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_777_4354"
          x1="157.376"
          y1="195.667"
          x2="260.796"
          y2="276.306"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#39A08B" />
          <stop offset="1" stop-color="#83D999" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_777_4354"
          x1="95.1419"
          y1="60.1342"
          x2="402.898"
          y2="125.999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#85E6E3" />
          <stop offset="0.41519" stop-color="#B7F0E2" />
          <stop offset="0.655696" stop-color="#6CE9BD" />
          <stop offset="1" stop-color="#96E894" />
        </linearGradient>
      </defs>
    </svg>
  );
}
