import { toast } from "sonner";
import ExcelJS from "exceljs";

export function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleString("es-ES", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
}

export function pastYears({ years }) {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: years }, (_, i) => ({
    year: currentYear - i,
  }));
}

export function getMonths() {
  return [
    { month: "01", name: "Enero" },
    { month: "02", name: "Febrero" },
    { month: "03", name: "Marzo" },
    { month: "04", name: "Abril" },
    { month: "05", name: "Mayo" },
    { month: "06", name: "Junio" },
    { month: "07", name: "Julio" },
    { month: "08", name: "Agosto" },
    { month: "09", name: "Septiembre" },
    { month: "10", name: "Octubre" },
    { month: "11", name: "Noviembre" },
    { month: "12", name: "Diciembre" },
  ];
}

export function validateColumnsExcel(file, columns, headerIndex) {
  return new Promise((resolve, reject) => {
    if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const buffer = e.target.result;
        const workbook = new ExcelJS.Workbook();

        try {
          await workbook.xlsx.load(buffer);

          if (
            workbook.worksheets.length !== 1 ||
            workbook.worksheets[0].name !== "Sheet1"
          ) {
            toast.error("El archivo debe tener solo una hoja llamada 'Sheet1'");
            resolve(false);
            return;
          }

          const sheet = workbook.getWorksheet("Sheet1");
          const headerRow = sheet.getRow(headerIndex + 1);

          if (headerRow.cellCount !== columns.length) {
            toast.error("El archivo no tiene el formato correcto");
            resolve(false);
          } else {
            const isValid = columns.every(
              (item, index) => item === headerRow.getCell(index + 1).value
            );
            if (isValid) {
              resolve(true);
            } else {
              toast.error("Las columnas no coinciden");
              resolve(false);
            }
          }
        } catch (error) {
          toast.error("Error al leer el archivo Excel");
          reject(false);
        }
      };

      reader.readAsArrayBuffer(file);
    } else {
      toast.error("Por favor carga un archivo Excel (.xlsx o .xls)");
      resolve(false);
    }
  });
}

export const convertToBlob = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const blob = new Blob([reader.result], { type: file.type });
      resolve(blob);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsArrayBuffer(file);
  });
};
