export function LineIcon({ name, color, onClick }) {
  const handleClick = () => {
    onClick();
  };
  return (
    <div
      onClick={handleClick}
      class={`bg-${color}-100 text-${color}-500 p-1 rounded-sm flex items-center cursor-pointer hover:bg-yellow-400 mx-1`}
    >
      <i class={`lni lni-${name}`}></i>
    </div>
  );
}
