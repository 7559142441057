import { Button } from "components/common/Button";
import { ImageWithDescription } from "components/common/ImageWithDescription";
import { TextInformation } from "components/common/TextInformation";

export function Information({ submodule }) {
  return (
    <div>
      <div className="h-72 xl:h-96">
        <ImageWithDescription
          path={
            submodule === "IhaveAQuestionVisual"
              ? "/assets/img/atencion_al_cliente.png"
              : "/assets/img/desarrollo.png"
          }
        />
      </div>

      <div className="bg-yellow-200 border-2 border-yellow-600 rounded-lg w-full p-1 text-sm text-yellow-600 mt-2">
        <div className="text-center">
          <TextInformation
            text={
              submodule === "IhaveAQuestionVisual"
                ? "ACTUALMENTE CUENTAS CON"
                : ` Usuarios (1)
                    Proyectos (3)
                    Publica tus informes (5)
                    Aprende BI (Intermedio)
                    Conecta API (1)
                    Mesa de ayuda (Soporte 1) `
            }
          />
        </div>
        {submodule === "IhaveAQuestionVisual" && (
          <div className="grid grid-cols-2">
            <div className="text-center text-7xl">
              <TextInformation text={"0"} />
            </div>

            <TextInformation text={"Solicitudes de soporte para este mes."} />
          </div>
        )}
      </div>
      <div className="mt-2 flex justify-center">
        <Button text="Pasarme a Plis BI PRO" />
      </div>
    </div>
  );
}
