import { useState, useEffect } from "react";
import { supabase } from "utils/SupabaseClient";
import {
  assignRoleToUser,
  assignPermissionToRole,
  assignPermissionToUser,
  createRole,
  createPermission,
  deletePermission,
  deleteRoleFromUser,
  getUserPermissions,
  removePermissionFromUser,
} from "api/Roles/route";
import { useRoleContext } from "./RoleContex";
import { Tabs } from "components/common/Tabs";
import { Module } from "components/common/Module";
import { Button } from "components/common/Button";
import { Select } from "components/tailwind/Select";
import { BackGroundSections } from "components/common/BackGroundSections";

export function RoleManagement() {
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedPermission, setSelectedPermission] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [newRoleName, setNewRoleName] = useState("");
  const [newPermissionName, setNewPermissionName] = useState("");
  const [newPermissionDesc, setNewPermissionDesc] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [userRoles, setUserRoles] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const { userRole } = useRoleContext();
  const [isRecharge, setIsRecharge] = useState(false);

  const items = [
    { state: true, nombre: "Roles y Permisos" },
    { state: false, nombre: "Crear Rol" },
    { state: false, nombre: "Crear Permiso" },
  ];
  const [subModuleActive, setSubModuleActive] = useState(items[0].nombre);

  useEffect(() => {
    const fetchRolesPermissionsAndUsers = async () => {
      setLoading(true);
      try {
        const { data: rolesData, error: rolesError } = await supabase
          .from("auth_roles")
          .select("*");
        const { data: permissionsData, error: permissionsError } =
          await supabase.from("auth_permissions").select("*");
        const { data: usersData, error: usersError } = await supabase
          .from("auth_users")
          .select("*");

        if (rolesError || permissionsError || usersError) {
          throw new Error("Error fetching roles, permissions, or users");
        }

        setRoles(rolesData);
        setPermissions(permissionsData);
        setUsers(usersData);
      } catch (error) {
        setMessage("Error loading roles, permissions, or users");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchRolesPermissionsAndUsers();
  }, [isRecharge]);

  useEffect(() => {
    if (selectedUser) {
      const fetchUserRoles = async () => {
        const { data: userRolesData } = await supabase
          .from("auth_user_roles")
          .select("role_id")
          .eq("user_id", selectedUser);

        setUserRoles(userRolesData.map((role) => role.role_id));
      };

      fetchUserRoles();
    }
  }, [selectedUser, isRecharge]);

  const handleAssignRole = async () => {
    if (!selectedUser || !selectedRole) {
      setMessage("Please select a user and a role.");
      return;
    }

    try {
      const role = roles.find((r) => r.role_name === selectedRole);
      if (!role) {
        setMessage("Role not found");
        return;
      }
      const roleId = role.id;
      const result = await assignRoleToUser(selectedUser, roleId);

      if (result) {
        setMessage(`Role "${selectedRole}" assigned to user ${selectedUser} successfully.`);
        setIsRecharge(!isRecharge);
      } else {
        setMessage("Error assigning role to user.");
      }
    } catch (error) {
      setMessage("Error assigning role to user.");
      console.error(error);
    }
  };

  const handleDeleteRole = async (roleId) => {
    if (!selectedUser) {
      setMessage("Please select a user first.");
      return;
    }

    try {
      const result = await deleteRoleFromUser(selectedUser, roleId);
      if (result) {
        setMessage(`Role deleted successfully.`);
        setUserRoles(userRoles.filter((role) => role !== roleId));
        setIsRecharge(!isRecharge);
      } else {
        setMessage("Error deleting role.");
      }
    } catch (error) {
      setMessage("Error deleting role.");
      console.error(error);
    }
  };

  const handleCreateRole = async () => {
    if (!newRoleName) {
      setMessage("Role name is required.");
      return;
    }

    setLoading(true);
    try {
      const {
        data: { user },
        error,
      } = await supabase.auth.getUser();

      if (error || !user) {
        setMessage("No user is authenticated.");
        return;
      }
      const roleData = {
        role_name: newRoleName,
        description: "New role description",
        created_by: user.id,
        modified_by: user.id,
      };

      const { data: createdRole, error: createError } = await supabase
        .from("auth_roles")
        .insert([roleData]);

      if (createError) {
        throw createError;
      }

      setRoles([...roles, createdRole[0]]);
      setMessage(`Role "${newRoleName}" created successfully.`);
      setNewRoleName("");
      setIsRecharge(!isRecharge);
    } catch (error) {
      setMessage("Error creating role.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreatePermission = async () => {
    if (!newPermissionName || !newPermissionDesc) {
      setMessage("Permission name and description are required.");
      return;
    }

    try {
      const {
        data: { user },
        error,
      } = await supabase.auth.getUser();

      if (error) {
        setMessage("Error getting user.");
        return;
      }

      if (!user) {
        setMessage("No user is logged in.");
        return;
      }

      const result = await createPermission(
        newPermissionName,
        newPermissionDesc,
        user.id
      );

      if (result) {
        setMessage(`Permission "${newPermissionName}" created successfully.`);
        setPermissions([...permissions, ...result]);
        setIsRecharge(!isRecharge);
      }
    } catch (error) {
      setMessage("Error creating permission.");
      console.error(error);
    }
  };

  const handleDeletePermission = async (permissionId) => {
    try {
      const result = await deletePermission(permissionId);
      if (result) {
        setMessage("Permission deleted successfully.");
        setPermissions(
          permissions.filter((permission) => permission.id !== permissionId)
        );
        setIsRecharge(!isRecharge);
      }
    } catch (error) {
      setMessage("Error deleting permission.");
      console.error(error);
    }
  };

  const handleRemovePermission = async (permissionId) => {
    if (!selectedUser) {
      setMessage("Please select a user first.");
      return;
    }

    try {
      const result = await removePermissionFromUser(selectedUser, permissionId);
      if (result) {
        setMessage("Permission removed successfully.");
        setUserPermissions(
          userPermissions.filter((permission) => permission !== permissionId)
        );
        setIsRecharge(!isRecharge);
      } else {
        setMessage("Error removing permission.");
      }
    } catch (error) {
      setMessage("Error removing permission.");
      console.error(error);
    }
  };

  if (userRole !== 7) {
    return <div>No tienes permisos para acceder a este contenido</div>;
  }

  return (
    <Module title="Gestión de Roles y Permisos" back={true} linkToBack={"/"}>
      <BackGroundSections>
        <Tabs items={items} setActive={setSubModuleActive} />

        {subModuleActive === items[0].nombre && (
          <div className="space-y-4 mx-8">
            <div className="grid grid-cols-5 gap-4">
              <div>
                <Select
                  title={"Seleccionar Usuario"}
                  value={selectedUser}
                  options={users.map((user) => ({
                    value: user.id,
                    name: `${user.first_name} ${user.last_name}`,
                  }))}
                  onChange={setSelectedUser}
                />
              </div>
              <div>
                <Select
                  title={"Seleccionar Rol"}
                  value={selectedRole}
                  options={roles.map((role) => ({
                    value: role.role_name,
                    name: role.role_name,
                  }))}
                  onChange={setSelectedRole}
                />
              </div>
              <div>
                <Select
                  title={"Seleccionar Permiso"}
                  value={selectedPermission}
                  options={permissions.map((permission) => ({
                    value: permission.name,
                    name: permission.name,
                  }))}
                  onChange={setSelectedPermission}
                />
              </div>
              <div className="flex justify-between self-center">
                <Button
                  text="Asignar"
                  onClick={handleAssignRole}
                  style="bg-sky-700 text-white py-3 px-8 rounded-md"
                />
              </div>
            </div>

            {selectedUser && (
              <div className="mt-8">
                <h3 className="text-lg font-semibold">Roles Asignados</h3>
                <ul className="space-y-2">
                  {userRoles.map((roleId) => {
                    const role = roles.find((role) => role.id === roleId);
                    return (
                      role && (
                        <li key={role.id} className="flex justify-between">
                          <span>{role.role_name}</span>
                          <Button
                            text="Eliminar"
                            onClick={() => handleDeleteRole(role.id)}
                            style="bg-red-500 text-white text-xs py-1 px-2 rounded-md"
                          />
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            )}

            {selectedUser && (
              <div className="mt-8">
                <h3 className="text-lg font-semibold">Permisos Asignados</h3>
                <ul className="space-y-2">
                  {userPermissions.map((permissionId) => {
                    const permission = permissions.find(
                      (permission) => permission.id === permissionId
                    );
                    return (
                      permission && (
                        <li
                          key={permission.id}
                          className="flex justify-between"
                        >
                          <span>{permission.permission_name}</span>
                          <Button
                            text="Eliminar"
                            onClick={() =>
                              handleRemovePermission(permission.id)
                            }
                            style="bg-red-500 text-white text-xs py-1 px-2 rounded-md"
                          />
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        )}

        {subModuleActive === items[1].nombre && (
          <div className="space-y-4 mx-8">
            <h3 className="text-lg font-semibold">Crear Nuevo Rol</h3>
            <input
              type="text"
              placeholder="Nombre del rol"
              value={newRoleName}
              onChange={(e) => setNewRoleName(e.target.value)}
              className="block w-full max-w-md px-4 py-2 border rounded-md"
            />
            <Button
              text="Crear Rol"
              onClick={handleCreateRole}
              style="mt-4 bg-sky-700 text-white py-3 px-8 rounded-md"
            />
          </div>
        )}

        {subModuleActive === items[2].nombre && (
          <div className="space-y-4 mx-8">
            <h3 className="text-lg font-semibold">Crear Nuevo Permiso</h3>
            <input
              type="text"
              placeholder="Nombre del permiso"
              value={newPermissionName}
              onChange={(e) => setNewPermissionName(e.target.value)}
              className="block w-full max-w-md px-4 py-2 border rounded-md"
            />
            <input
              type="text"
              placeholder="Descripción del permiso"
              value={newPermissionDesc}
              onChange={(e) => setNewPermissionDesc(e.target.value)}
              className="mt-2 block w-full max-w-md px-4 py-2 border rounded-md"
            />
            <Button
              text="Crear Permiso"
              onClick={handleCreatePermission}
              style="mt-4 bg-sky-700 text-white py-3 px-8 rounded-md"
            />
          </div>
        )}
      </BackGroundSections>
    </Module>
  );
}



// import { useEffect, useState } from "react";
// import { Button } from "components/common/Button";
// import { ModalBG } from "components/common/ModalBG";
// import { supabase } from "utils/SupabaseClient";
// import { useRoleContext } from "./RoleContex";
// import { FiSearch } from "react-icons/fi";
// import { assignRoleToUser, assignPermissionToRole, removePermissionFromUser } from "api/Roles/route";

// export function RoleManagement() {
//   const [roles, setRoles] = useState([]);
//   const [permissions, setPermissions] = useState([]);
//   const [users, setUsers] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [modalAction, setModalAction] = useState("");
//   const [userToUpdate, setUserToUpdate] = useState(null);
//   const [message, setMessage] = useState("");
//   const [newRoleName, setNewRoleName] = useState("");
//   const [newPermissionName, setNewPermissionName] = useState("");
//   const [isCreateRoleModalOpen, setIsCreateRoleModalOpen] = useState(false);
//   const [isCreatePermissionModalOpen, setIsCreatePermissionModalOpen] = useState(false);
//   const { userRole } = useRoleContext();

//   useEffect(() => {
//     const timeoutId = setTimeout(() => {
//       setDebouncedSearchTerm(searchTerm);
//     }, 250);

//     return () => clearTimeout(timeoutId);
//   }, [searchTerm]);

//   useEffect(() => {
//     const fetchData = async () => {
//       const { data: rolesData, error: rolesError } = await supabase
//         .from("auth_roles")
//         .select("*");
//       const { data: permissionsData, error: permissionsError } =
//         await supabase.from("auth_permissions").select("*");
//       const { data: usersData, error: usersError } = await supabase
//         .from("auth_users")
//         .select("*");

//       if (rolesError || permissionsError || usersError) {
//         setMessage("Error fetching roles, permissions, or users.");
//         console.error(rolesError, permissionsError, usersError);
//         return;
//       }

//       setRoles(rolesData);
//       setPermissions(permissionsData);
//       setUsers(usersData);
//     };

//     fetchData();
//   }, []);

//   const filteredUsers = users.filter((user) =>
//     `${user.first_name} ${user.last_name}`
//       .toLowerCase()
//       .includes(debouncedSearchTerm.toLowerCase())
//   );

//   const handleOpenModal = (action, user) => {
//     setModalAction(action);
//     setUserToUpdate(user);
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setModalAction("");
//     setUserToUpdate(null);
//   };

//   const handleCreateRole = async () => {
//     if (!newRoleName) {
//       setMessage("Please enter a role name.");
//       return;
//     }

//     try {
//       const { data, error } = await supabase
//         .from("auth_roles")
//         .insert({ role_name: newRoleName })
//         .single();

//       if (error) {
//         setMessage("Error creating role.");
//         return;
//       }

//       setRoles([...roles, data]);
//       setMessage(`Role "${newRoleName}" created successfully.`);
//       setIsCreateRoleModalOpen(false);
//     } catch (error) {
//       console.error(error);
//       setMessage("Error creating role.");
//     }
//   };

//   const handleCreatePermission = async () => {
//     if (!newPermissionName) {
//       setMessage("Please enter a permission name.");
//       return;
//     }

//     try {
//       const { data, error } = await supabase
//         .from("auth_permissions")
//         .insert({ name: newPermissionName })
//         .single();

//       if (error) {
//         setMessage("Error creating permission.");
//         return;
//       }

//       setPermissions([...permissions, data]);
//       setMessage(`Permission "${newPermissionName}" created successfully.`);
//       setIsCreatePermissionModalOpen(false);
//     } catch (error) {
//       console.error(error);
//       setMessage("Error creating permission.");
//     }
//   };

//   const handleAssignRoleToUser = async (userId, roleId) => {
//     const result = await assignRoleToUser(userId, roleId);
//     if (result) {
//       setMessage(`Role assigned successfully.`);
//     } else {
//       setMessage("Error assigning role.");
//     }
//   };

//   const handleAssignPermissionToUser = async (userId, permissionId) => {
//     const result = await assignPermissionToRole(userId, permissionId);
//     if (result) {
//       setMessage(`Permission assigned successfully.`);
//     } else {
//       setMessage("Error assigning permission.");
//     }
//   };

//   const handleRemovePermissionFromUser = async (userId, permissionId) => {
//     const result = await removePermissionFromUser(userId, permissionId);
//     if (result) {
//       setMessage(`Permission removed successfully.`);
//     } else {
//       setMessage("Error removing permission.");
//     }
//   };

//   if (userRole !== 7) {
//     return <div>No tienes permisos para acceder a este contenido</div>;
//   }

//   return (
//     <div className="space-y-4 mx-8">
//       <div className="flex justify-between mb-4">
//         <div className="flex gap-6 items-center w-full relative">
//           <input
//             type="text"
//             placeholder="Buscar usuario..."
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//             className="p-2 pl-10 pr-4 border rounded-md w-3/4 sm:w-2/3 lg:w-1/2"
//           />
//           <FiSearch
//             className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
//             size={20}
//           />
//         </div>
//         <div className="flex gap-6 items-center">
//           <Button
//             text="Crear Rol"
//             onClick={() => setIsCreateRoleModalOpen(true)}
//             className="bg-green-500 text-white py-2 px-6 rounded-md text-sm"
//           />
//           <Button
//             text="Crear Permiso"
//             onClick={() => setIsCreatePermissionModalOpen(true)}
//             className="bg-blue-500 text-white py-2 px-6 rounded-md text-sm"
//           />
//         </div>
//       </div>

//       <table className="table-auto w-full mt-4 border border-gray-300">
//         <thead>
//           <tr>
//             <th className="px-4 py-2 border">Usuario</th>
//             <th className="px-4 py-2 border">
//               Roles
//               <input
//                 type="text"
//                 placeholder="Filtrar por rol"
//                 className="border p-1 mt-1 w-full rounded-md"
//               />
//             </th>
//             <th className="px-4 py-2 border">
//               Permisos
//               <input
//                 type="text"
//                 placeholder="Filtrar por permiso"
//                 className="border p-1 mt-1 w-full rounded-md"
//               />
//             </th>
//             <th className="px-4 py-2 border">Acciones</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filteredUsers.map((user) => {
//             const userRoles = Array.isArray(user.roles)
//               ? roles
//                   .filter((role) => user.roles.includes(role.id))
//                   .map((role) => role.role_name)
//                   .join(", ")
//               : "No roles";
//             const userPermissions = Array.isArray(user.permissions)
//               ? permissions
//                   .filter((permission) =>
//                     user.permissions.includes(permission.id)
//                   )
//                   .map((permission) => permission.name)
//                   .join(", ")
//               : "No permissions";

//             return (
//               <tr key={user.id}>
//                 <td className="px-4 py-2 border">{`${user.first_name} ${user.last_name}`}</td>
//                 <td className="px-4 py-2 border">{userRoles}</td>
//                 <td className="px-4 py-2 border">{userPermissions}</td>
//                 <td className="px-4 py-2 border text-center">
//                   <Button
//                     text="Asignar Rol"
//                     onClick={() => handleOpenModal("role", user)}
//                     className="bg-green-500 text-white py-1 px-2 rounded-md mr-2"
//                   />
//                   <Button
//                     text="Asignar Permiso"
//                     onClick={() => handleOpenModal("permission", user)}
//                     className="bg-blue-500 text-white py-1 px-2 rounded-md"
//                   />
//                 </td>
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>

//       <ModalBG
//         open={isCreateRoleModalOpen}
//         onClose={() => setIsCreateRoleModalOpen(false)}
//         title="Crear Nuevo Rol"
//       >
//         <div>
//           <input
//             type="text"
//             placeholder="Nombre del rol"
//             value={newRoleName}
//             onChange={(e) => setNewRoleName(e.target.value)}
//             className="border p-2 w-full mb-4 rounded-md"
//           />
//           <Button
//             text="Crear Rol"
//             onClick={handleCreateRole}
//             className="bg-green-700 text-white py-3 px-8 rounded-md"
//           />
//         </div>
//       </ModalBG>

//       <ModalBG
//         open={isCreatePermissionModalOpen}
//         onClose={() => setIsCreatePermissionModalOpen(false)}
//         title="Crear Nuevo Permiso"
//       >
//         <div>
//           <input
//             type="text"
//             placeholder="Nombre del permiso"
//             value={newPermissionName}
//             onChange={(e) => setNewPermissionName(e.target.value)}
//             className="border p-2 w-full mb-4 rounded-md"
//           />
//           <Button
//             text="Crear Permiso"
//             onClick={handleCreatePermission}
//             className="bg-blue-700 text-white py-3 px-8 rounded-md"
//           />
//         </div>
//       </ModalBG>

//       {message && <div className="text-center text-sm text-red-500">{message}</div>}
//     </div>
//   );
// }
