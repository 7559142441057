import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";

import { Button } from "components/common/Button";
import { ModalBG } from "components/common/ModalBG";
import { ReportDetail, ReportTemplate } from "./ReportModal";

export function ReportInformation({
  report,
  setReport,
  setActive,
  isTemplate,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  const handleExit = () => {
    setActive(false);
    setReport(null);
  };

  return (
    <>
      <div className="uppercase font-bold py-4 flex items-center justify-between">
        <h1 className="text-center text-sky-700 w-full">{report.title}</h1>
        <div className="flex items-center space-x-2">
          <Button
            type="button"
            onClick={handleOpen}
            className="bg-sky-800 hover:bg-yellow-500 grow p-2 text-white rounded-lg transition-colors"
            icon={<InformationCircleIcon className="h-4" />}
          />
          <Button
            type="button"
            onClick={handleExit}
            className="bg-red-500 hover:bg-yellow-500 grow p-2 text-white rounded-lg transition-colors"
            icon={<XMarkIcon className="h-4" />}
          />
        </div>
      </div>
      <div className="border-4 border-zinc-300 bg-white rounded-md p-1">
        <iframe
          title="g"
          className="w-full h-[30rem] 2xl:h-[50rem]"
          src={report.url}
          allowFullScreen={true}
        />
      </div>
      <ReportModal
        item={report}
        isTemplate={isTemplate}
        open={isOpen}
        onClose={handleOpen}
      />
    </>
  );
}

function ReportModal({ item, open, onClose, isTemplate }) {
  const navigate = useNavigate();

  const handleSupport = () => {
    navigate("/support");
  };
  return (
    <ModalBG
      title={
        isTemplate
          ? `¡Conecta, carga y recibe tu reporte ${item.title}!`.toLocaleUpperCase()
          : "Información del reporte".toLocaleUpperCase()
      }
      open={open}
      onClose={onClose}
    >
      {isTemplate && <ReportTemplate item={item} />}
      {!isTemplate && (
        <>
          <ReportDetail item={item} />
          <div className="flex justify-end space-x-4">
            <Button
              className="bg-sky-700 px-2 py-1 rounded-md text-white font-bold"
              type="button"
              text="Editar"
            />
            <Button
              onClick={handleSupport}
              className="bg-gray-400 px-2 py-1 rounded-md text-white font-bold"
              type="button"
              text="Ir a soporte"
            />
          </div>
        </>
      )}
    </ModalBG>
  );
}
