import { useCallback, useEffect, useRef, useState } from "react";
import { VideoControls } from "./videoComponets";

export function VideosCourseComponents({ src }) {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [volume, setVolume] = useState(1);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  // Cargar el tiempo de reproducción desde localStorage cuando el componente se monte
  useEffect(() => {
    const savedProgress = localStorage.getItem("videoProgress");
    if (savedProgress) {
      setProgress(parseFloat(savedProgress)); // Convertir el valor guardado en número
    }
  }, []); // Solo se ejecuta una vez, al montar el componente

  useEffect(() => {
    const video = videoRef.current;

    // Solo se debe establecer currentTime cuando se ha cargado el video
    const handleLoadedMetadata = () => {
      if (videoRef.current) {
        videoRef.current.currentTime = progress;
      }
    };

    // Establecer tiempo al cargar el video
    video?.addEventListener("loadedmetadata", handleLoadedMetadata);

    const handleTimeUpdate = () => {
      setProgress(video.currentTime);
    };

    const handleDurationChange = () => {
      setDuration(video.duration);
    };

    video?.addEventListener("timeupdate", handleTimeUpdate);
    video?.addEventListener("durationchange", handleDurationChange);

    return () => {
      video?.removeEventListener("loadedmetadata", handleLoadedMetadata);
      video?.removeEventListener("timeupdate", handleTimeUpdate);
      video?.removeEventListener("durationchange", handleDurationChange);
    };
  }, [progress]);

  // Guardar la posición del video en localStorage cada vez que cambie el progreso.
  // Lo haremos con un `useEffect` separado para evitar el ciclo infinito.
  useEffect(() => {
    if (progress > 0) {
      localStorage.setItem("videoProgress", progress.toString());
    }
  }, [progress]); // Guardamos el progreso cuando cambia, pero no dependemos de él en el otro `useEffect`.

  const togglePlay = useCallback(() => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  }, []);

  const handleVolumeChange = useCallback((e) => {
    const newVolume = e.target.value;
    videoRef.current.volume = newVolume;
    setVolume(newVolume);
  }, []);

  const handlePlaybackRateChange = useCallback((e) => {
    const newPlaybackRate = e.target.value;
    videoRef.current.playbackRate = newPlaybackRate;
    setPlaybackRate(newPlaybackRate);
  }, []);

  const toggleFullScreen = useCallback(() => {
    const video = document.getElementById("fullDiv");
    if (!isFullscreen) {
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen();
        console.log(video.msRequestFullscreen);
      }
    } else {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else if (document.webkitRequestFullscreenElement) {
        document.webkitExitFullscreen();
      } else if (document.msRequestFullscreenElement) {
        document.msExitFullscreen();
      } else if (document.mozRequestFullscreenElement) {
        document.mozCancelFullscreen();
      }
    }

    setIsFullscreen(!isFullscreen);
  }, [isFullscreen]);

  const handleProgressChange = useCallback((e) => {
    const newProgress = e.target.value;
    videoRef.current.currentTime = newProgress;
    setProgress(newProgress);
  }, []);

  return (
    <div
      className={`${
        isFullscreen
          ? "fixed inset-0 w-screen h-screen"
          : "border shadow-2x1 shadow-black rounded-md overflow-hidden drop-shadow-sm group relative lg:w-full lg:h-full"
      }`}
      id={"fullDiv"}
    >
      <video
        autoPlay={true}
        src={src}
        className={`object-cover ${isFullscreen ? "w-screen h-screen" : ""}`}
        ref={videoRef}
        onClick={togglePlay}
        onContextMenu={(e) => e.preventDefault()}
      />
      <VideoControls
        // Estados
        progress={progress}
        duration={duration}
        isPlaying={isPlaying}
        volume={volume}
        playbackRate={playbackRate}
        isFullscreen={isFullscreen}
        // Funciones del reproductor
        togglePlay={togglePlay}
        handleVolumeChange={handleVolumeChange}
        handlePlaybackRateChange={handlePlaybackRateChange}
        toggleFullScreen={toggleFullScreen}
        handleProgressChange={handleProgressChange}
      />
    </div>
  );
}
