import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deleteCourse, getModule,downloadImage } from "hooks/commonData";
import { Button } from "components/common/Button";
import { ModalBG } from "components/common/ModalBG";
import { StatusDetail } from "components/training/StatusDetail";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

export function TarjetCoursesPMO({ to, title, image, status, colorId, id }) {
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [userConfirmation, setUserConfirmation] = useState("");
  const navigate = useNavigate();

  const handleEdit = (e) => {
    e.preventDefault();
    navigate(`/trainingpmo/edit/${id}`);
  };

  const handleViewDetails = (e) => {
    e.preventDefault();
    navigate(`/training/course/${id}`);
  };

  const handleClose = () => {
    setIsConfirmDelete(false);
  };

  const handleAdminCourse = (e) => {
    e.preventDefault();
    navigate(`/trainingpmo/admin-course`);
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const modules = await getModule(id);

    if (modules.length === 0) {
      if (window.confirm("¿Estás seguro de que deseas eliminar este curso?")) {
        try {
          await deleteCourse(id, "miniaturas", "cursos");
          alert("Curso eliminado con éxito");
          navigate("/trainingpmo");
        } catch (error) {
          alert("Error al eliminar el curso: " + error.message);
        }
      }
    } else {
      setIsConfirmDelete(true);
    }
  };

  const confirmDeleteWithModules = async () => {
    if (userConfirmation.toLowerCase() === "eliminar") {
      try {
        await deleteCourse(id, "miniaturas", "cursos");
        alert(
          "Curso y todo su contenido (módulos, videos, miniaturas) han sido eliminados con éxito"
        );
        navigate("/trainingpmo");
      } catch (error) {
        alert("Error al eliminar el curso: " + error.message);
      }
    } else {
      alert("Por favor, escribe 'Eliminar' para confirmar la acción.");
    }
  };

  useEffect(() => {
    downloadImage(image).then((r) => setAvatarUrl(r));
  }, [image]);

  return (
    <>
      <div className="relative lg:bg-white lg:hover:scale-105 rounded-md px-4 py-4 transition-transform border-b border-red lg:border-none group">
        <Link to={to}>
          <div className="relative">
            <div className="absolute top-2 left-2 z-10">
              <StatusDetail colorId={colorId} statusName={status} />
            </div>
            <img
              src={avatarUrl}
              alt=""
              className="w-full rounded-lg slide-down-animation"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
          </div>
        </Link>

        <div className="mt-2 text-center">
          <p className="text-base lg:text-lg text-black">{title}</p>
        </div>

        <div className="absolute top-2 right-2 z-10">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                <ChevronDownIcon
                  aria-hidden="true"
                  className="-mr-1 h-5 w-5 text-gray-400"
                />
              </MenuButton>
            </div>

            <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition">
              <div className="py-1">
                <MenuItem>
                  <a
                    href="#"
                    onClick={handleEdit}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Editar contenido
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    href="#"
                    onClick={handleViewDetails}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Ver módulos
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    href="#"
                    onClick={handleDelete}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Eliminar curso
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    href="#"
                    onClick={handleAdminCourse}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Administrar acceso
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </Menu>
        </div>
      </div>

      <ModalBG
        open={isConfirmDelete}
        onClose={handleClose}
        title={"Confirmación de eliminación"}
      >
        <p className="text-sm mb-4">Este curso tiene módulos asociados.</p>
        <p className="text-sm mb-4">
          Si eliminas este curso, también se eliminarán todos los módulos y
          videos relacionados.
        </p>
        <p className="text-sm mb-4">
          Por favor, escribe <strong>"Eliminar"</strong> para confirmar que
          deseas eliminar este curso y todo su contenido.
        </p>

        <input
          type="text"
          value={userConfirmation}
          onChange={(e) => setUserConfirmation(e.target.value)}
          placeholder="Escribe 'Eliminar' para confirmar"
          className="border p-2 w-full mb-4 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
        />

        <div className="flex flex-col sm:flex-row justify-end gap-4">
          <Button
            text="Cancelar"
            onClick={() => setIsConfirmDelete(false)}
            className="bg-gray-300 hover:bg-gray-400 text-black rounded-md px-4 py-2 transition-all duration-200"
          />
          <Button
            text="Confirmar eliminación"
            onClick={confirmDeleteWithModules}
            className="bg-red-500 hover:bg-red-600 text-white rounded-md px-4 py-2 transition-all duration-200"
          />
        </div>
      </ModalBG>
    </>
  );
}
