import { useEffect, useState } from "react";

import ExcelJS from "exceljs";
import mammoth from "mammoth";
import fileDownload from "js-file-download";

import { supabase } from "utils/SupabaseClient";
import { getUserData } from "utils/SupabaseClient";
import { getDocFiles } from "./Consulta";

import { ModalBG } from "components/common/ModalBG";
import { Button } from "components/common/Button";
import { ViewTable } from "components/common/ViewTable";
import { AutoHeigth } from "components/common/AutoHeigth";
import { DownloadIcon } from "components/svg/DownloadIcon";
import { BackGroundSections } from "components/common/BackGroundSections";
import { ChargingComponent } from "components/common/ChargingComponent";

export function ViewDocFiles({ submodule, setSubmodule }) {
  const tittles = [
    "Proyecto",
    "Archivo",
    "Fecha de Carga",
    "Ver archivo",
    "Descargar archivo",
  ];

  useEffect(() => {
    setSubmodule(submodule);
  }, [submodule]);

  const [dataArray, setDataArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [idUser, setIdUser] = useState("");
  const [selectedProject, setSelectedProject] = useState("Proyecto Lanuza");
  const [filePreviewContent, setFilePreviewContent] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getUserData().then((r) => setIdUser(r.id));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const files = await getDocFiles(selectedProject);
        const newArray = files.map((item) => {
          const date = new Date(item.created_at);
          const formattedDate = date.toLocaleDateString("es-CO", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          });

          return {
            project: selectedProject,
            file_name: item.name,
            created_at: formattedDate,
            verArchivo: (
              <Button
                text={"Ver"}
                onClick={() => handlePreview(item.name)}
                isIcon={false}
              />
            ),
            acciones: (
              <Button
                text={"Descargar"}
                onClick={() => handleDownload(item.name)}
                isIcon={true}
                icon={<DownloadIcon h={"20"} w={"20"} />}
              />
            ),
          };
        });
        setDataArray(newArray);
      } catch (error) {
        console.error("Error al traer archivos de la carpeta DOC:", error);
      } finally {
        setLoading(false);
      }
    };

    if (idUser) {
      fetchData();
    }
  }, [idUser, selectedProject]);

  const handleDownload = async (fileName) => {
    try {
      const { data, error } = await supabase.storage
        .from("archivos")
        .download(`${selectedProject}/DOC/${fileName}`);

      if (error) throw error;
      fileDownload(data, fileName);
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
    }
  };

  const handlePreview = async (fileName) => {
    try {
      const { data, error } = await supabase.storage
        .from("archivos")
        .download(`${selectedProject}/DOC/${fileName}`);

      if (error) throw error;
      const fileExt = fileName.split(".").pop().toLowerCase();

      if (fileExt === "xlsx") {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = new ExcelJS.Workbook();

          await workbook.xlsx.load(data);

          let htmlContent = "";
          workbook.worksheets.forEach((worksheet, index) => {
            htmlContent += `<h4>Hoja ${index + 1}: ${worksheet.name}</h4>`;
            htmlContent += worksheetToHtml(worksheet);
          });

          setFilePreviewContent(htmlContent);
          setShowModal(true);
        };

        reader.readAsArrayBuffer(data);
      } else if (fileExt === "docx") {
        const arrayBuffer = await data.arrayBuffer();
        const result = await mammoth.convertToHtml({ arrayBuffer });
        setFilePreviewContent(result.value);
        setShowModal(true);
      } else {
        setFilePreviewContent("El archivo no es compatible para vista previa.");
        setShowModal(true);
      }
    } catch (error) {
      console.error("Error al obtener el archivo para vista previa:", error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setFilePreviewContent("");
  };

  return (
    <div className={`${submodule !== "Documentos" ? "hidden" : ""}`}>
      <BackGroundSections>
        <div style={{ marginBottom: "20px" }}>
          <label htmlFor="projectSelect" style={{ marginRight: "10px" }}>
            Selecciona un proyecto:
          </label>
          <select
            id="projectSelect"
            onChange={(e) => setSelectedProject(e.target.value)}
            value={selectedProject}
          >
            <option value="Proyecto Lanuza">Proyecto Lanuza</option>
            <option value="Proyecto BI_Vistas">Proyecto BI_Vistas</option>
          </select>
        </div>
        {loading ? (
          <ChargingComponent />
        ) : (
          <ViewTable to="/databases" rows={dataArray} tittles={tittles} />
        )}
      </BackGroundSections>
      <ModalBG
        onClose={() => setShowModal(false)}
        open={showModal}
        title={"Vista previa del archivo"}
      >
        <AutoHeigth>
          {filePreviewContent && (
            <div dangerouslySetInnerHTML={{ __html: filePreviewContent }} />
          )}
        </AutoHeigth>
      </ModalBG>
    </div>
  );
}

const worksheetToHtml = (worksheet) => {
  let html = "<table border='1'>";
  worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    html += "<tr>";
    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      html += `<td>${cell.text}</td>`;
    });
    html += "</tr>";
  });
  html += "</table>";
  return html;
};
