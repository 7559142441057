import { useEffect, useState } from "react";

import { RiFileExcel2Fill } from "react-icons/ri";
import { LineIcon } from "components/svg/LineIcon";

import { formatDate } from "utils/Operations";

import { deleteConnection } from "api/bi-module/datasource";
import { deleteFile, downloadFile } from "api/bi-module/storage";

import { ModalBG } from "components/common/ModalBG";
import { Dropdown } from "components/common/Dropdown";
import { ModalSuccess } from "components/modal/ModalSuccess";
import { ConfirmDelete } from "components/common/ConfirmDelete";
import { ChargingComponent } from "components/common/ChargingComponent";

function DeleteModal({ item, open, onClick }) {
  const [isDelete, setIsDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const handleDelete = async () => {
    setIsLoading(true);
    await deleteFile({
      fileName: `${item.company_id}/${item.provider}/${item.type}/${item.file}`,
    });
    await deleteConnection({ id: item.id });
    setIsLoading(false);
    setIsSuccess(true);
  };
  return (
    <ModalBG title="Eliminar conexion" open={open} onClose={onClick}>
      {!isLoading && (
        <>
          {!isSuccess && (
            <>
              <h1 className="block text-md font-medium leading-6 text-gray-900">
                ¿Seguro que quiere eliminar el archivo{" "}
                <strong>{item.file}</strong> de la empresa{" "}
                <strong>{item.company}</strong>?.
              </h1>
              <ConfirmDelete message="para confirmar" onConfirm={setIsDelete} />
              <div className="flex justify-end pt-2">
                <button
                  onClick={handleDelete}
                  className="bg-red-500 px-4 py-2 rounded-md text-white hover:bg-red-400"
                  disabled={!isDelete}
                >
                  Eliminar
                </button>
              </div>
            </>
          )}
          {isSuccess && (
            <ModalSuccess
              title="Eliminado"
              message="Conexión eliminada correctamente, por favor recarga la página nuevamente."
              onClose={onClick}
            />
          )}
        </>
      )}
      {isLoading && <ChargingComponent />}
    </ModalBG>
  );
}

function Row({ item, setModal, setRecharge }) {
  const [open, setOpen] = useState(false);
  const handleModal = () => {
    setOpen(!open);
    setModal(open);
  };
  const handleSuccess = () => {
    setOpen(!open);
    setModal(open);
    setRecharge();
  };
  const handleDownload = () => {
    downloadFile({
      path: `${item.company_id}/${item.provider}/${item.type}/${item.file}`,
      fileName: item.file,
    });
  };
  return (
    <>
      <tr className="bg-white border-b">
        <th
          scope="row"
          className="px-3 md:px-6 py-4 font-medium text-gray-900 whitespace-nowrap hidden md:table-cell"
        >
          {item.company}
        </th>
        <td className="px-3 md:px-6 py-4 hidden lg:table-cell">
          {item.provider}
        </td>
        <td className="px-3 md:px-6 py-4 hidden lg:table-cell">{item.type}</td>
        <td className="px-3 md:px-6 py-4">
          <p className="hidden md:table-cell">{item.file}</p>
          <div className="md:hidden">
            <File item={item} />
          </div>
        </td>
        <td className="px-3 md:px-6 py-4 hidden xl:table-cell">
          {item.createdDate}
        </td>
        <td className="px-3 md:px-6 py-4">
          <div className="hidden md:table-cell">
            <div className="flex">
              <LineIcon name="download" color="blue" onClick={handleDownload} />
              <LineIcon name="minus" color="red" onClick={handleModal} />
            </div>
          </div>
          <div className="md:hidden">
            <Dropdown
              firstName="Eliminar"
              downloadAction={handleDownload}
              secondName="Descargar"
              deleteAction={handleModal}
            />
          </div>
        </td>
      </tr>
      <DeleteModal item={item} open={open} onClick={handleSuccess} />
    </>
  );
}

function File({ item }) {
  return (
    <div className="flex min-w-0 gap-x-4">
      <div className="h-12 w-12 flex items-center justify-center rounded-full bg-gray-50 text-2xl">
        <RiFileExcel2Fill className="text-emerald-600" />
      </div>
      <div className="min-w-0 flex-auto">
        <p className="text-sm font-semibold leading-6 text-gray-900">
          {item.type}
        </p>
        <p className="mt-1 truncate text-xs leading-5 text-gray-500">
          {item.company}
        </p>
      </div>
    </div>
  );
}

export function Table({ active, items, setRecharge }) {
  const [totalItems, setTotalItems] = useState(0);
  const [modalActive, setModalActive] = useState(false);
  useEffect(() => {
    if (items) setTotalItems(items.length);
  }, [items]);
  return (
    <>
      {items === null ? (
        <ChargingComponent />
      ) : (
        <>
          <div className="relative shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead
                className={`text-xs text-sky-800 uppercase bg-gray-50 top-0 z-20 ${
                  active && modalActive ? "sticky" : ""
                }`}
              >
                <tr>
                  <th
                    scope="col"
                    className="px-3 mix-blend-normal py-3 hidden md:table-cell"
                  >
                    Empresa
                  </th>
                  <th
                    scope="col"
                    className="px-3 mix-blend-normal py-3 hidden lg:table-cell"
                  >
                    Proveedor
                  </th>
                  <th
                    scope="col"
                    className="px-3 mix-blend-normal py-3 hidden lg:table-cell"
                  >
                    Tipo
                  </th>
                  <th scope="col" className="px-3 mix-blend-normal py-3">
                    Archivo
                  </th>
                  <th
                    scope="col"
                    className="px-3 mix-blend-normal py-3 hidden xl:table-cell"
                  >
                    Fecha
                  </th>
                  <th scope="col" className="px-3 mix-blend-normal py-3">
                    Acción
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.map((item) => (
                  <Row
                    key={item.id}
                    item={{
                      id: item.id,
                      company: item.auth_user_subcompanies.razon_social,
                      company_id: item.auth_user_subcompanies.id,
                      provider: item.proveedor,
                      type: item.tipo_archivo,
                      file: item.archivo,
                      createdDate: formatDate(item.created_at),
                    }}
                    setModal={setModalActive}
                    setRecharge={setRecharge}
                  />
                ))}
              </tbody>
            </table>
          </div>
          {totalItems > 10 && (
            <PaginationTable totalItems={totalItems} perPage={10} />
          )}
        </>
      )}
    </>
  );
}

function PaginationTable({ totalItems, perPage }) {
  const [pagesList, setPagesList] = useState(null);

  useEffect(() => {
    const pages = Math.ceil(totalItems / perPage);
    const newPagesList = [];
    for (let i = 1; i <= pages; i++) {
      newPagesList.push(i);
    }
    setPagesList(newPagesList);
  }, [totalItems, perPage]);

  return (
    <nav
      className="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4"
      aria-label="Table navigation"
    >
      <span className="text-sm font-normal text-gray-500 mb-4 md:mb-0 block w-full md:inline md:w-auto">
        Mostrando <span className="font-semibold text-gray-900">1-10</span> de{" "}
        <span className="font-semibold text-gray-900">{totalItems}</span>
      </span>
      <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
        <li>
          <a
            href="#"
            className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700"
          >
            Anterior
          </a>
        </li>
        {pagesList &&
          pagesList.map((page) => {
            <li>
              <a
                href="#"
                className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
              >
                {page}
              </a>
            </li>;
          })}
        <li>
          <a
            href="#"
            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700"
          >
            Siguiente
          </a>
        </li>
      </ul>
    </nav>
  );
}
